import { baseURLPrefix } from 'app/constants'
import { constructQueryParamsFromObject } from 'utils/objects'
import { FilterVouchers } from 'app/containers/Vouchers/types'
import { IFilterOrders } from 'app/containers/Orders/types'
import { IFilterPayments } from 'app/containers/Payments/types'

export const VouchersEndpoints = {
  vouchersList: (queryParams: FilterVouchers) =>
    `${baseURLPrefix}shop/admin-orders${constructQueryParamsFromObject(queryParams)}`,
  addVoucher: () => `${baseURLPrefix}shop/admin-orders/create`,
}

export const PaymentsEndpoints = {
  paymentsList: (queryParams: IFilterPayments) =>
    `${baseURLPrefix}shop/payments${constructQueryParamsFromObject(queryParams)}`,
}

export const OrdersEndpoints = {
  ordersList: (queryParams: IFilterOrders) =>
    `${baseURLPrefix}shop/orders${constructQueryParamsFromObject(queryParams)}`,
}
