import { Header } from './components/Header'
import { FilterDomainsModal } from './components/modals/filter'
import { TableOfData } from './components/table'

export function Domains() {
  return (
    <>
      <Header />
      <TableOfData />
      <FilterDomainsModal />
    </>
  )
}
