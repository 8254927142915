import { nftsActions } from '../slice'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { nftsSelectors } from '../selectors'
import { styled } from '@mui/material'
import { KYCNFT_STATE, TABLE_KEY, columnsForShowInTable } from '../constants'
import SortableTable from 'app/components/table/sortableTable'
import { globalSelectors } from 'app/containers/Initialize/selector'
import { ActionComponent } from './actionComponent'
import { searchUserSelectors } from 'app/containers/SearchUser/selector'

export const TableOfData = () => {
  const dispatch = useDispatch()

  const isLoading = useSelector(nftsSelectors.isLoading)
  const searchUserCoreId = useSelector(searchUserSelectors.coreId)
  const issuedNfts = useSelector(nftsSelectors.issuedNfts)
  const isLoadingContractAddresses = useSelector(
    globalSelectors.isLoadingContractAddresses,
  )

  useEffect(() => {
    searchUserCoreId
      ? dispatch(nftsActions.fetchNfts(searchUserCoreId))
      : dispatch(nftsActions.setNfts([]))
  }, [searchUserCoreId])

  const speceficRowStyleBasedOnCellValue = {
    cellKey: 'state',
    style: {
      backgroundColor: 'var(--error)',
    },
    cellValue: KYCNFT_STATE[4],
    actionComponent: ActionComponent,
  }

  return (
    <Wrapper>
      <SortableTable
        name={TABLE_KEY}
        isLoading={isLoading || isLoadingContractAddresses}
        data={issuedNfts}
        columns={columnsForShowInTable}
        showNumberOfPages={false}
        countOfData={issuedNfts?.length}
        speceficRowStyleBasedOnCellValue={speceficRowStyleBasedOnCellValue}
      />
    </Wrapper>
  )
}

const Wrapper = styled('div')`
  justify-content: center;
  grid-gap: 10px 0;
  margin-top: 20px;
  margin-bottom: 20px;
  min-width: 0;
`
