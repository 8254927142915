import { useDispatch, useSelector } from 'react-redux'
import { FilterAssetsManagementContent } from './content'
import { assetsManagementSelectors } from 'app/containers/AssetsManagement/selectors'
import { assetsManagementActions } from 'app/containers/AssetsManagement/slice'
import { Modal } from 'app/components/modal'

export const FilterAssetsManagementModal = () => {
  const dispatch = useDispatch()

  const isOpen = useSelector(
    assetsManagementSelectors.displayFilterAssetsManagementModal,
  )
  const handleModalClose = () => {
    dispatch(
      assetsManagementActions.setDisplayFilterAssetsManagementModal(false),
    )
  }

  return (
    <Modal open={isOpen} onClose={handleModalClose}>
      <FilterAssetsManagementContent />
    </Modal>
  )
}
