import { styled } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import AddIcon from '@mui/icons-material/Add'
import { useDispatch, useSelector } from 'react-redux'
import { configurationsSelectors } from '../selectors'
import { configurationsActions } from '../slice'
import {
  FilterChipsInHeader,
  IShowablefilterData,
} from 'app/components/common/FilterChipsInHeader'
import { mediaQueries } from 'styles/mediaQueries'
import { PageHeader } from 'app/components/common/globals/pageHeader'
import PrimaryButton from 'app/components/button/primaryButton'

export const ConfigurationsHeader = () => {
  const dispatch = useDispatch()

  const filterConfigurations = useSelector(
    configurationsSelectors.filterConfigurations,
  )
  const displayFilterConfigurationsModal = useSelector(
    configurationsSelectors.displayFilterConfigurationsModal,
  )

  const handleOpenFilterModal = () => {
    dispatch(configurationsActions.setDisplayFilterConfigurationsModal(true))
  }

  const showablefilterConfigurations: IShowablefilterData[] = [
    {
      label: 'Group Code',
      value: filterConfigurations?.groupCode,
    },
    {
      label: 'Key',
      value: filterConfigurations?.key,
    },
    {
      label: 'CreatedAt From',
      value: filterConfigurations?.createdAtFrom,
      isDate: true,
    },
    {
      label: 'CreatedAt To',
      value: filterConfigurations?.createdAtTo,
      isDate: true,
    },
    {
      label: 'UpdateAt From',
      value: filterConfigurations?.updateAtFrom,
      isDate: true,
    },
    {
      label: 'UpdateAt To',
      value: filterConfigurations?.updateAtTo,
      isDate: true,
    },
  ]

  const handleDelete = (label: string) => {
    dispatch(
      configurationsActions.setFilterConfigurations({
        ...filterConfigurations,
        [label]: undefined,
      }),
    )
    dispatch(configurationsActions.getConfigurations())
  }

  return (
    <>
      <PageHeader title="Configurations">
        <ButtonsContainer>
          <CTAContainer
            endIcon={<StyledAddIcon />}
            onClick={() =>
              dispatch(
                configurationsActions.setDisplaySetConfigurationModal(true),
              )
            }
          >
            <h4> Set Config.</h4>
          </CTAContainer>
          <CTAContainer
            endIcon={<StyledSearchIcon />}
            onClick={() =>
              dispatch(
                configurationsActions.setDisplayFilterConfigurationsModal(true),
              )
            }
          >
            <h4> Search</h4>
          </CTAContainer>
        </ButtonsContainer>
      </PageHeader>

      <FilterSection>
        {!displayFilterConfigurationsModal && (
          <FilterChipsInHeader
            showableFilter={showablefilterConfigurations}
            deleteFilter={handleDelete}
          />
        )}
      </FilterSection>
    </>
  )
}

const ButtonsContainer = styled('div')`
  display: flex;
  gap: 16px;
`

const FilterSection = styled('div')`
  display: flex;
  gap: 8px;
  width: 100%;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
`
const StyledSearchIcon = styled(SearchIcon)`
  color: var(--white);
`
const StyledAddIcon = styled(AddIcon)`
  color: var(--white);
`
const CTAContainer = styled(PrimaryButton)`
  h4 {
    display: inline;
    margin: 0;
    ${mediaQueries.lessThan('xs')`
      display: none;   
      `}
  }
  ${mediaQueries.lessThan('xs')`
      width: 40px;
      & .MuiButton-endIcon {
        margin:0;
      }
      `}
`
