import { createSelector } from '@reduxjs/toolkit'

import { RootState } from 'store/types'
import { initialState } from './slice'

export const clientsDomains = {
  root: (state: RootState) => state.clients || initialState,
  clientsList: (state: RootState) => state.clients.clientsList,
  isLoadingClients: (state: RootState) => state.clients.isLoadingClients,
  filterClients: (state: RootState) => state.clients.filterClients,
  lastPageOfClientsTable: (state: RootState) =>
    state.clients.lastPageOfClientsTable,
  isAllClientsFetched: (state: RootState) => state.clients.isAllClientsFetched,
  displayFilterClientsModal: (state: RootState) =>
    state.clients.displayFilterClientsModal,
}

export const clientsSelectors = {
  root: createSelector(clientsDomains.root, root => root),
  clientsList: createSelector(clientsDomains.clientsList, clients => clients),
  isLoadingClients: createSelector(
    clientsDomains.isLoadingClients,
    isLoading => isLoading,
  ),
  filterClients: createSelector(
    clientsDomains.filterClients,
    filterData => filterData,
  ),
  lastPageOfClientsTable: createSelector(
    clientsDomains.lastPageOfClientsTable,
    lastPageOfTable => lastPageOfTable,
  ),
  isAllClientsFetched: createSelector(
    clientsDomains.isAllClientsFetched,
    isAllFetched => isAllFetched,
  ),
  displayFilterClientsModal: createSelector(
    clientsDomains.displayFilterClientsModal,
    displayFilterModal => displayFilterModal,
  ),
}
