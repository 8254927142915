import { call, put, select, takeLatest } from 'redux-saga/effects'
import { clientsActions } from './slice'
import { getClientsReq } from './providers'
import { AxiosResponse } from 'axios'
import { customToast } from 'app/components/toast/CustomToast'
import { errorMessage } from 'utils/parser'
import { clientsSelectors } from './selectors'
import { IFilterClients, IClients } from './types'

function* getClients(): any {
  yield put(clientsActions.setIsLoadingClients(true))
  try {
    const filterData: IFilterClients = yield select(
      clientsSelectors.filterClients,
    )
    const response: AxiosResponse = yield call(getClientsReq, filterData)

    const listOfNewData = (response?.data?.data?.clients || []) as IClients[]

    yield put(clientsActions.setClients(listOfNewData))

    listOfNewData.length === 0 || listOfNewData.length < filterData.pageSize
      ? yield put(clientsActions.setIsAllClientsFetched(true))
      : yield put(clientsActions.setIsAllClientsFetched(false))
  } catch (error) {
    customToast.error(errorMessage(error))
  } finally {
    yield put(clientsActions.setIsLoadingClients(false))
  }
}

export function* clientsSaga() {
  yield takeLatest(clientsActions.getClientsList.type, getClients)
}
