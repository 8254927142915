import { createSelector } from '@reduxjs/toolkit'

import { RootState } from 'store/types'
import { initialState } from './slice'

export const recordsDomains = {
  root: (state: RootState) => state.records || initialState,
  recordsList: (state: RootState) => state.records.recordsList,
  isLoadingRecords: (state: RootState) => state.records.isLoadingRecords,
  filterRecords: (state: RootState) => state.records.filterRecords,
  lastPageOfRecordsTable: (state: RootState) =>
    state.records.lastPageOfRecordsTable,
  isAllRecordsFetched: (state: RootState) => state.records.isAllRecordsFetched,
  displayFilterRecordsModal: (state: RootState) =>
    state.records.displayFilterRecordsModal,
}

export const recordsSelectors = {
  root: createSelector(recordsDomains.root, root => root),
  recordsList: createSelector(recordsDomains.recordsList, records => records),
  isLoadingRecords: createSelector(
    recordsDomains.isLoadingRecords,
    isLoading => isLoading,
  ),
  filterRecords: createSelector(
    recordsDomains.filterRecords,
    filterData => filterData,
  ),
  lastPageOfRecordsTable: createSelector(
    recordsDomains.lastPageOfRecordsTable,
    lastPageOfTable => lastPageOfTable,
  ),
  isAllRecordsFetched: createSelector(
    recordsDomains.isAllRecordsFetched,
    isAllFetched => isAllFetched,
  ),
  displayFilterRecordsModal: createSelector(
    recordsDomains.displayFilterRecordsModal,
    displayFilterModal => displayFilterModal,
  ),
}
