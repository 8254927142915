import { styled } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import { useDispatch, useSelector } from 'react-redux'

import { phoneVerificationsSelectors } from '../selectors'
import { phoneVerificationsActions } from '../slice'
import {
  FilterChipsInHeader,
  IShowablefilterData,
} from 'app/components/common/FilterChipsInHeader'
import { PageHeader } from 'app/components/common/globals/pageHeader'
import PrimaryButton from 'app/components/button/primaryButton'

export const Header = () => {
  const dispatch = useDispatch()

  const filterPhoneVerifications = useSelector(
    phoneVerificationsSelectors.filterPhoneVerifications,
  )
  const displayFilterPhoneVerificationsModal = useSelector(
    phoneVerificationsSelectors.displayFilterPhoneVerificationsModal,
  )

  const handleOpenFilterModal = () => {
    dispatch(
      phoneVerificationsActions.setDisplayFilterPhoneVerificationsModal(true),
    )
  }

  const showableFilterPhoneVerification: IShowablefilterData[] = [
    { label: 'CoreID', value: filterPhoneVerifications?.coreID },
    { label: 'Status', value: filterPhoneVerifications?.status },
    { label: 'NFTID', value: filterPhoneVerifications?.nftID },
    { label: 'Id', value: filterPhoneVerifications?.id },
    {
      label: 'Expiration',
      value: filterPhoneVerifications?.expiration,
      isDate: true,
    },
    { label: 'VouchererName', value: filterPhoneVerifications?.vouchererName },
    { label: 'TxHash', value: filterPhoneVerifications?.txHash },
    { label: 'BcTxID', value: filterPhoneVerifications?.bcTxID },
    { label: 'MintType', value: filterPhoneVerifications?.mintType },
    { label: 'VerifyRetry', value: filterPhoneVerifications?.verifyRetry },
    {
      label: 'CreatedAt From',
      value: filterPhoneVerifications?.createdAtFrom,
      isDate: true,
    },
    {
      label: 'CreatedAt To',
      value: filterPhoneVerifications?.createdAtTo,
      isDate: true,
    },
  ]

  const handleDelete = (label: string) => {
    dispatch(
      phoneVerificationsActions.setFilterPhoneVerifications({
        ...filterPhoneVerifications,
        [label]: undefined,
      }),
    )
    dispatch(phoneVerificationsActions.getPhoneVerifications())
  }

  return (
    <>
      <PageHeader title="Phone Verifications">
        <PrimaryButton
          onClick={handleOpenFilterModal}
          endIcon={<StyledSearchIcon />}
        >
          Search
        </PrimaryButton>
      </PageHeader>
      <FilterSection>
        {!displayFilterPhoneVerificationsModal && (
          <FilterChipsInHeader
            showableFilter={showableFilterPhoneVerification}
            deleteFilter={handleDelete}
          />
        )}
      </FilterSection>
    </>
  )
}

const FilterSection = styled('div')`
  display: flex;
  gap: 8px;
  width: 100%;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
`
const StyledSearchIcon = styled(SearchIcon)`
  color: var(--white);
`
