import { createSelector } from '@reduxjs/toolkit'

import { RootState } from 'store/types'
import { initialState } from './slice'

export const emailRejectionsDomains = {
  root: (state: RootState) => state.emailRejections || initialState,
  emailRejectionsList: (state: RootState) =>
    state.emailRejections.emailRejectionsList,
  isLoadingEmailRejections: (state: RootState) =>
    state.emailRejections.isLoadingEmailRejections,
  filterEmailRejections: (state: RootState) =>
    state.emailRejections.filterEmailRejections,
  lastPageOfEmailRejectionsTable: (state: RootState) =>
    state.emailRejections.lastPageOfEmailRejectionsTable,
  isAllEmailRejectionsFetched: (state: RootState) =>
    state.emailRejections.isAllEmailRejectionsFetched,
  displayFilterEmailRejectionsModal: (state: RootState) =>
    state.emailRejections.displayFilterEmailRejectionsModal,
}

export const emailRejectionsSelectors = {
  root: createSelector(emailRejectionsDomains.root, root => root),
  emailRejectionsList: createSelector(
    emailRejectionsDomains.emailRejectionsList,
    emailRejections => emailRejections,
  ),
  isLoadingEmailRejections: createSelector(
    emailRejectionsDomains.isLoadingEmailRejections,
    isLoading => isLoading,
  ),
  filterDataEmailRejections: createSelector(
    emailRejectionsDomains.filterEmailRejections,
    filterData => filterData,
  ),
  lastPageOfEmailRejectionsTable: createSelector(
    emailRejectionsDomains.lastPageOfEmailRejectionsTable,
    lastPageOfTable => lastPageOfTable,
  ),
  isAllEmailRejectionsFetched: createSelector(
    emailRejectionsDomains.isAllEmailRejectionsFetched,
    isAllFetched => isAllFetched,
  ),
  displayFilterEmailRejectionsModal: createSelector(
    emailRejectionsDomains.displayFilterEmailRejectionsModal,
    displayFilterModal => displayFilterModal,
  ),
}
