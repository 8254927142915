import { domainsActions } from '../slice'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { domainsSelectors } from '../selectors'
import { styled } from '@mui/material'
import { DOMAINS_TABLE_KEY, columnsForShowInDomainsTable } from '../constants'
import SortableTable from 'app/components/table/sortableTable'
import { capitalizeAllCharacters } from 'utils/string'
import { ISortType } from 'app/types'
import { MAX_SAFE_INTEGER } from 'app/constants'

export const TableOfData = () => {
  const dispatch = useDispatch()

  const domainsList = useSelector(domainsSelectors.domainsList)
  const isLoadingDomains = useSelector(domainsSelectors.isLoadingDomains)
  const filterDomains = useSelector(domainsSelectors.filterDomains)
  const lastPageOfDomainsTable = useSelector(
    domainsSelectors.lastPageOfDomainsTable,
  )
  const isAllDomainsFetched = useSelector(domainsSelectors.isAllDomainsFetched)

  useEffect(() => {
    dispatch(domainsActions.getDomainsList())
  }, [])

  const handleSetNumberRowsPerPage = (num: number) => {
    const tablePageSizeTitle = `${DOMAINS_TABLE_KEY}_pageSize`
    localStorage.setItem(tablePageSizeTitle, num.toString())
    dispatch(
      domainsActions.setFilterDomains({
        ...filterDomains,
        pageSize: num,
      }),
    )
    dispatch(domainsActions.getDomainsList())
  }

  return (
    <Wrapper>
      <SortableTable
        name={DOMAINS_TABLE_KEY}
        isLoading={isLoadingDomains}
        data={domainsList}
        columns={columnsForShowInDomainsTable}
        handleChangeLastPage={(newPage: number) => {
          dispatch(
            domainsActions.setFilterDomains({
              ...filterDomains,
              page: newPage,
            }),
          )

          dispatch(domainsActions.getDomainsList())
        }}
        setPage={page =>
          dispatch(domainsActions.setLastPageOfDomainsTable(page))
        }
        page={lastPageOfDomainsTable}
        setNumberRowsPerPage={num => handleSetNumberRowsPerPage(num)}
        handleCustomRequestSort={(property, order) => {
          dispatch(
            domainsActions.setFilterDomains({
              ...filterDomains,
              sortField: property,
              sortType: capitalizeAllCharacters(order) as ISortType,
            }),
          )
          const oneRow = domainsList[0]
          if (oneRow) {
            const propertyType = typeof oneRow[property as keyof typeof oneRow]
            if (propertyType === 'number') {
              dispatch(domainsActions.getDomainsList())
            }
          }
        }}
        showNumberOfPages={false}
        countOfData={MAX_SAFE_INTEGER}
        disableNextButton={isAllDomainsFetched}
      />
    </Wrapper>
  )
}

const Wrapper = styled('div')`
  justify-content: center;
  grid-gap: 10px 0;
  margin-top: 20px;
  margin-bottom: 20px;
  min-width: 0;
`
