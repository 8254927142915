import { Header } from './components/Header'
import { FilterApiKeysModal } from './components/modals/filter'
import { TableOfData } from './components/table'

export function ApiKeys() {
  return (
    <>
      <Header />
      <TableOfData />
      <FilterApiKeysModal />
    </>
  )
}
