import { useDispatch, useSelector } from 'react-redux'
import { nftsSelectors } from '../../../selectors'
import { nftsActions } from '../../../slice'
import { ConfrimRejectModalContent } from './content'
import { Modal } from 'app/components/modal'

export const ConfirmDeleteModal = () => {
  const dispatch = useDispatch()

  const confirmRejectModalItem = useSelector(
    nftsSelectors.confirmRejectModalItem,
  )

  const handleModalClose = () =>
    dispatch(nftsActions.setConfirmRejectModalItem(undefined))

  return (
    <Modal open={!!confirmRejectModalItem} onClose={handleModalClose}>
      <ConfrimRejectModalContent />
    </Modal>
  )
}
