import { baseURLPrefix } from 'app/constants'
import { IFilterApiKeys } from 'app/containers/ApiKeys/types'
import { IFilterClientSourceKeys } from 'app/containers/ClientSourceKeys/types'
import { IFilterClients } from 'app/containers/Clients/types'
import { IFilterDomains } from 'app/containers/Domains/types'
import { IFilterOauths } from 'app/containers/Oauths/types'
import { IFilterOrganizations } from 'app/containers/Organizations/types'
import { IFilterRecords } from 'app/containers/Records/types'
import { IFilterTransmissions } from 'app/containers/Transmissions/types'
import { constructQueryParamsFromObject } from 'utils/objects'

export const TransmissionsEndpoints = {
  transmissionList: (queryParams: IFilterTransmissions) =>
    `${baseURLPrefix}connector/kyc/transmissions${constructQueryParamsFromObject(queryParams)}`,
}

export const RecordsEndpoints = {
  recordsList: (queryParams: IFilterRecords) =>
    `${baseURLPrefix}connector/kyc/records${constructQueryParamsFromObject(queryParams)}`,
}

export const ApiKeysEndpoints = {
  apiKeysList: (queryParams: IFilterApiKeys) =>
    `${baseURLPrefix}connector/client/apikeys${constructQueryParamsFromObject(queryParams)}`,
}

export const ClientsEndpoints = {
  clientsList: (queryParams: IFilterClients) =>
    `${baseURLPrefix}connector/client/clients${constructQueryParamsFromObject(queryParams)}`,
}

export const ClientSourceKeysEndpoints = {
  clientSourceKeysList: (queryParams: IFilterClientSourceKeys) =>
    `${baseURLPrefix}connector/client/clientsourcekeys${constructQueryParamsFromObject(queryParams)}`,
}

export const OauthsEndpoints = {
  oauthsList: (queryParams: IFilterOauths) =>
    `${baseURLPrefix}connector/client/oauths${constructQueryParamsFromObject(queryParams)}`,
}

export const OrganizationsEndpoints = {
  organizationsList: (queryParams: IFilterOrganizations) =>
    `${baseURLPrefix}connector/client/organizations${constructQueryParamsFromObject(queryParams)}`,
}

export const DomainsEndpoints = {
  domainsList: (queryParams: IFilterDomains) =>
    `${baseURLPrefix}connector/client/domains${constructQueryParamsFromObject(queryParams)}`,
}
