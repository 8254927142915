import { createSelector } from '@reduxjs/toolkit'

import { RootState } from 'store/types'
import { initialState } from './slice'

export const ordersDomains = {
  root: (state: RootState) => state.orders || initialState,
  orderList: (state: RootState) => state.orders.orderList,
  isLoadingOrders: (state: RootState) => state.orders.isLoadingOrders,
  filterOrders: (state: RootState) => state.orders.filterOrders,
  lastPageOfOrdersTable: (state: RootState) =>
    state.orders.lastPageOfOrdersTable,
  isAllOrdersFetched: (state: RootState) => state.orders.isAllOrdersFetched,
  displayFilterOrdersModal: (state: RootState) =>
    state.orders.displayFilterOrdersModal,
}

export const ordersSelectors = {
  root: createSelector(ordersDomains.root, root => root),
  orderList: createSelector(ordersDomains.orderList, orders => orders),
  isLoadingOrders: createSelector(
    ordersDomains.isLoadingOrders,
    isLoading => isLoading,
  ),
  filterOrders: createSelector(
    ordersDomains.filterOrders,
    filterOrders => filterOrders,
  ),
  lastPageOfOrdersTable: createSelector(
    ordersDomains.lastPageOfOrdersTable,
    lastPageOfTable => lastPageOfTable,
  ),
  isAllOrdersFetched: createSelector(
    ordersDomains.isAllOrdersFetched,
    isAllFetched => isAllFetched,
  ),
  displayFilterOrdersModal: createSelector(
    ordersDomains.displayFilterOrdersModal,
    displayFilterModal => displayFilterModal,
  ),
}
