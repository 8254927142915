import { PayloadAction } from '@reduxjs/toolkit'
import { AssetsManagementState } from './types'
import { createSlice } from 'store/toolkit'
import { useInjectReducer, useInjectSaga } from 'store/redux-injectors'

import { assetsManagementSaga } from './saga'
import { localPageSize, localSortField, localSortType } from './constants'

export const initialState: AssetsManagementState = {
  assetsManagementList: [],
  isLoadingAssetsManagement: false,
  filterAssetsManagement: {
    page: 0,
    pageSize: localPageSize ? localPageSize : 100,
    sortField: localSortField,
    sortType: localSortType,
  },
  lastPageOfAssetsManagementTable: 0,
  isAllAssetsManagementFetched: false,
  displayFilterAssetsManagementModal: false,
}

const assetsManagementSlice = createSlice({
  name: 'assetsManagement',
  initialState,
  reducers: {
    getAssetsManagement(state) {},
    setAssetsManagement(
      state,
      action: PayloadAction<AssetsManagementState['assetsManagementList']>,
    ) {
      state.assetsManagementList = action.payload
    },
    setIsLoadingAssetsManagement(
      state,
      action: PayloadAction<AssetsManagementState['isLoadingAssetsManagement']>,
    ) {
      state.isLoadingAssetsManagement = action.payload
    },
    setFilterAssetsManagement(
      state,
      action: PayloadAction<AssetsManagementState['filterAssetsManagement']>,
    ) {
      state.filterAssetsManagement = action.payload
    },
    setLastPageOfAssetsManagementTable(state, action: PayloadAction<number>) {
      state.lastPageOfAssetsManagementTable = action.payload
    },
    setIsAllAssetsManagementFetched(state, action: PayloadAction<boolean>) {
      state.isAllAssetsManagementFetched = action.payload
    },
    setDisplayFilterAssetsManagementModal(
      state,
      action: PayloadAction<boolean>,
    ) {
      state.displayFilterAssetsManagementModal = action.payload
    },
    setDisplayDetailAssetsManagementModal(
      state,
      action: PayloadAction<
        AssetsManagementState['displayDetailAssetsManagementModal']
      >,
    ) {
      state.displayDetailAssetsManagementModal = action.payload
    },
  },
})

export const {
  actions: assetsManagementActions,
  reducer: assetsManagementReducer,
  name: sliceKey,
} = assetsManagementSlice

export const useAssetsManagementSlice = () => {
  useInjectReducer({ key: sliceKey, reducer: assetsManagementReducer })
  useInjectSaga({ key: sliceKey, saga: assetsManagementSaga })
  return { assetsManagementActions: assetsManagementActions }
}
