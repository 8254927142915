import { call, put, select, takeLatest } from 'redux-saga/effects'
import { documentRejectionsActions } from './slice'
import { getDocumentRejectionsReq } from './providers'
import { AxiosResponse } from 'axios'
import { customToast } from 'app/components/toast/CustomToast'
import { errorMessage } from 'utils/parser'
import { documentRejectionsSelectors } from './selectors'
import { IFilterDocumentRejections, IDocumentRejection } from './types'

function* getDocumentRejections(): any {
  yield put(documentRejectionsActions.setIsLoadingDocumentRejections(true))
  try {
    const filterDocumentRejections: IFilterDocumentRejections = yield select(
      documentRejectionsSelectors.filterDocumentRejections,
    )

    const response: AxiosResponse = yield call(
      getDocumentRejectionsReq,
      filterDocumentRejections,
    )

    const documentRejectionsList = (response?.data?.data?.rejections ||
      []) as IDocumentRejection[]

    yield put(
      documentRejectionsActions.setDocumentRejections(documentRejectionsList),
    )

    documentRejectionsList.length === 0 ||
    documentRejectionsList.length < filterDocumentRejections.pageSize
      ? yield put(
          documentRejectionsActions.setIsAllDocumentRejectionsFetched(true),
        )
      : yield put(
          documentRejectionsActions.setIsAllDocumentRejectionsFetched(false),
        )
  } catch (error) {
    customToast.error(errorMessage(error))
  } finally {
    yield put(documentRejectionsActions.setIsLoadingDocumentRejections(false))
  }
}

export function* documentRejectionsSaga() {
  yield takeLatest(
    documentRejectionsActions.getDocumentRejections.type,
    getDocumentRejections,
  )
}
