import { PayloadAction } from '@reduxjs/toolkit'
import { RecordsState } from './types'
import { createSlice } from 'store/toolkit'
import { useInjectReducer, useInjectSaga } from 'store/redux-injectors'

import { recordsSaga } from './saga'
import { localPageSize, localSortField, localSortType } from './constants'

export const initialState: RecordsState = {
  recordsList: [],
  isLoadingRecords: false,
  filterRecords: {
    page: 0,
    pageSize: localPageSize ? localPageSize : 100,
    sortField: localSortField,
    sortType: localSortType,
  },
  lastPageOfRecordsTable: 0,
  isAllRecordsFetched: false,
  displayFilterRecordsModal: false,
}

const recordsSlice = createSlice({
  name: 'records',
  initialState,
  reducers: {
    getRecords(state) {},
    setRecords(state, action: PayloadAction<RecordsState['recordsList']>) {
      state.recordsList = action.payload
    },
    setIsLoadingRecords(
      state,
      action: PayloadAction<RecordsState['isLoadingRecords']>,
    ) {
      state.isLoadingRecords = action.payload
    },
    setFilterRecords(
      state,
      action: PayloadAction<RecordsState['filterRecords']>,
    ) {
      state.filterRecords = action.payload
    },
    setLastPageOfRecordsTable(state, action: PayloadAction<number>) {
      state.lastPageOfRecordsTable = action.payload
    },
    setIsAllRecordsFetched(state, action: PayloadAction<boolean>) {
      state.isAllRecordsFetched = action.payload
    },
    setDisplayFilterRecordsModal(state, action: PayloadAction<boolean>) {
      state.displayFilterRecordsModal = action.payload
    },
  },
})

export const {
  actions: recordsActions,
  reducer: recordsReducer,
  name: sliceKey,
} = recordsSlice

export const useRecordsSlice = () => {
  useInjectReducer({ key: sliceKey, reducer: recordsReducer })
  useInjectSaga({ key: sliceKey, saga: recordsSaga })
  return { recordsActions: recordsActions }
}
