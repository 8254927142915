import { createSelector } from '@reduxjs/toolkit'

import { RootState } from 'store/types'
import { initialState } from './slice'

export const transmissionsDomains = {
  root: (state: RootState) => state.transmissions || initialState,
  transmissionsList: (state: RootState) =>
    state.transmissions.transmissionsList,
  isLoadingTransmissions: (state: RootState) =>
    state.transmissions.isLoadingTransmissions,
  filterTransmissions: (state: RootState) =>
    state.transmissions.filterTransmissions,
  lastPageOfTransmissionsTable: (state: RootState) =>
    state.transmissions.lastPageOfTransmissionsTable,
  isAllTransmissionsFetched: (state: RootState) =>
    state.transmissions.isAllTransmissionsFetched,
  displayFilterTransmissionsModal: (state: RootState) =>
    state.transmissions.displayFilterTransmissionsModal,
}

export const transmissionsSelectors = {
  root: createSelector(transmissionsDomains.root, root => root),
  transmissionsList: createSelector(
    transmissionsDomains.transmissionsList,
    transmissions => transmissions,
  ),
  isLoadingTransmissions: createSelector(
    transmissionsDomains.isLoadingTransmissions,
    isLoading => isLoading,
  ),
  filterTransmissions: createSelector(
    transmissionsDomains.filterTransmissions,
    filterData => filterData,
  ),
  lastPageOfTransmissionsTable: createSelector(
    transmissionsDomains.lastPageOfTransmissionsTable,
    lastPageOfTable => lastPageOfTable,
  ),
  isAllTransmissionsFetched: createSelector(
    transmissionsDomains.isAllTransmissionsFetched,
    isAllFetched => isAllFetched,
  ),
  displayFilterTransmissionsModal: createSelector(
    transmissionsDomains.displayFilterTransmissionsModal,
    displayFilterModal => displayFilterModal,
  ),
}
