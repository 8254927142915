import { useDispatch, useSelector } from 'react-redux'
import { FilterOauthsContent } from './content'
import { oauthsSelectors } from 'app/containers/Oauths/selectors'
import { oauthsActions } from 'app/containers/Oauths/slice'
import { Modal } from 'app/components/modal'

export const FilterOauthsModal = () => {
  const dispatch = useDispatch()

  const isOpen = useSelector(oauthsSelectors.displayFilterOauthsModal)
  const handleModalClose = () => {
    dispatch(oauthsActions.setDisplayFilterOauthsModal(false))
  }

  return (
    <Modal open={isOpen} onClose={handleModalClose}>
      <FilterOauthsContent />
    </Modal>
  )
}
