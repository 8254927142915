import axiosInstance from 'api/axios'
import { AxiosResponse } from 'axios'
import { ApiKeysEndpoints } from 'api/endpoints/connector'
import { IFilterApiKeys } from '../types'

export function* getApiKeysReq(filterData: IFilterApiKeys) {
  const response: AxiosResponse = yield axiosInstance.get(
    ApiKeysEndpoints.apiKeysList(filterData),
  )
  return response
}
