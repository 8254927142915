import DeleteIcon from '@mui/icons-material/Delete'
import { IconButton } from '@mui/material'
import { submissionsSelectors } from 'app/containers/Submissions/selectors'
import { useDispatch, useSelector } from 'react-redux'
import { submissionsActions } from 'app/containers/Submissions/slice'

interface ActionProps {
  rowId: string
  actionHasBeenDone?: boolean
}
export const ActionComponent = ({ rowId, actionHasBeenDone }: ActionProps) => {
  const dispatch = useDispatch()
  const submittedEvent = useSelector(
    submissionsSelectors.getSubmittedByTransactionHash(rowId),
  )

  const handleActionClick = () => {
    dispatch(submissionsActions.setConfirmDeleteModalItem(submittedEvent))
  }

  return (
    <IconButton onClick={handleActionClick}>
      {!actionHasBeenDone ? <DeleteIcon /> : <></>}
    </IconButton>
  )
}
