import { call, put, takeLatest } from 'redux-saga/effects'
import { mainPageActions } from './slice'
import { getMetricsDataReq } from './providers'

function* getMetricsData(
  action: ReturnType<typeof mainPageActions.getMetricsData>,
): any {
  try {
    yield put(mainPageActions.setIsLoading(true))
    const response = yield getMetricsDataReq(action)

    yield put(mainPageActions.setMetricsData(response?.data?.data))
  } catch (error) {
    console.error('Error fetching metrics data', error)
  } finally {
    yield put(mainPageActions.setIsLoading(false))
  }
}

export function* mainPageSaga() {
  yield takeLatest(mainPageActions.getMetricsData.type, getMetricsData)
}
