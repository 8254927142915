import { Box, IconButton, styled } from '@mui/material'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { mediaQueries } from 'styles/mediaQueries'
import { useCopy } from 'hooks/useCopy'

interface IResubmissionRowItemWidgetProps {
  title: string
  onClick: () => void
}
export const ResubmissionRowItemWidget = (
  props: IResubmissionRowItemWidgetProps,
) => {
  const { title, onClick } = props

  const { isCopied, handleCopy } = useCopy()

  return (
    <Wrapper>
      <Title
        hasTitle={title ? 'true' : 'false'}
        onClick={() => {
          onClick()
        }}
      >
        {title ? title : 'N/A'}
      </Title>
      <StyledIconButton disabled={!title} onClick={() => handleCopy(title)}>
        <StyledContentCopyIcon
          fontSize="small"
          iscopied={isCopied ? 'true' : 'false'}
        />
      </StyledIconButton>
    </Wrapper>
  )
}

const Wrapper = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-right: 1px solid #e0e0e0;
  &:first-of-type {
    border-left: 1px solid #e0e0e0;
  }
  ${mediaQueries.lessThan('xs')`
    grid-template-columns: 1fr;
  `}
`

const StyledIconButton = styled(IconButton)`
  padding: 0;
  margin: 0;
  width: 32px;
  height: 32px;
  align-self: center;
  justify-self: center;
  & > * {
    color: ${({ disabled, theme }) =>
      disabled ? theme.palette.grey[400] : theme.palette.common.black};
  }
`

const StyledContentCopyIcon = styled(ContentCopyIcon)<{
  iscopied: 'false' | 'true'
}>`
  color: ${({ iscopied }) => (iscopied ? 'gray' : 'black')};
`

const Title = styled('p')<{ hasTitle: 'true' | 'false' }>`
  margin: 0;
  padding: 0 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: ${({ hasTitle }) => (hasTitle === 'true' ? 'pointer' : 'default')};
  color: ${({ hasTitle, theme }) =>
    hasTitle === 'true' ? theme.palette.common.black : theme.palette.grey[400]};

  &:hover {
    color: ${({ hasTitle, theme }) =>
      hasTitle === 'true' ? theme.palette.grey[500] : theme.palette.grey[400]};
  }
  ${mediaQueries.lessThan('sm')`
    font-size: 14px;
    `}
  ${mediaQueries.lessThan('xs')`
    font-size: 12px;
    `}
`
