import { useDispatch, useSelector } from 'react-redux'
import { FilterVouchersContent } from './content'
import { vouchersActions } from 'app/containers/Vouchers/slice'
import { vouchersSelectors } from 'app/containers/Vouchers/selectors'
import { Modal } from 'app/components/modal'

export const FilterVouchersModal = () => {
  const dispatch = useDispatch()

  const isOpen = useSelector(vouchersSelectors.displayFilterVouchersModal)
  const handleModalClose = () => {
    dispatch(vouchersActions.setDisplayFilterVouchersModal(false))
  }

  return (
    <Modal open={isOpen} onClose={handleModalClose}>
      <FilterVouchersContent />
    </Modal>
  )
}
