import { useDispatch, useSelector } from 'react-redux'
import { FilterPhoneInvalidationsContent } from './content'
import { phoneInvalidationsSelectors } from 'app/containers/PhoneInvalidations/selectors'
import { phoneInvalidationsActions } from 'app/containers/PhoneInvalidations/slice'
import { Modal } from 'app/components/modal'

export const FilterPhoneInvalidationsModal = () => {
  const dispatch = useDispatch()

  const isOpen = useSelector(
    phoneInvalidationsSelectors.displayFilterPhoneInvalidationsModal,
  )
  const handleModalClose = () => {
    dispatch(
      phoneInvalidationsActions.setDisplayFilterPhoneInvalidationsModal(false),
    )
  }

  return (
    <Modal open={isOpen} onClose={handleModalClose}>
      <FilterPhoneInvalidationsContent />
    </Modal>
  )
}
