import { FC } from 'react'
import { Button, ButtonProps, CircularProgress } from '@mui/material'
import { ROW_CENTER } from 'styles/globalStyles'
import { styled } from '@mui/material/styles'
import { mediaQueries } from 'styles/mediaQueries'

export interface BaseButtonProps extends ButtonProps {
  isLoading?: boolean
  loadingText?: string
}

const BaseButton: FC<BaseButtonProps> = ({
  children,
  isLoading = false,
  loadingText,
  endIcon,
  startIcon,
  onClick,
  ...props
}) => {
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (onClick && isLoading !== true) {
      onClick(event)
    }
  }

  return (
    <BButton
      variant="contained"
      endIcon={!isLoading ? endIcon : null}
      startIcon={!isLoading ? startIcon : null}
      disabled={isLoading}
      onClick={handleClick}
      {...props}
    >
      {isLoading ? (
        <LoadingContainer>
          {loadingText}
          <CircularProgress size="25px" sx={{ color: 'white' }} />
        </LoadingContainer>
      ) : (
        children
      )}
    </BButton>
  )
}

const BButton = styled(Button)<BaseButtonProps>`
  background-color: var(--greek-sea);
  color: var(--White);
  white-space: nowrap;

  &:hover {
  }

  &:hover::before {
  }

  &.Mui-disabled {
  }

  &.MuiButton-root {
    min-width: auto;
    padding: 6px 22px;
    font-weight: 600;
    font-size: 14px;
    border-radius: 8px;
    position: relative;
    text-transform: none;
    ${mediaQueries.lessThan('md')`
    padding: 6px 18px;
    font-size: 13px;
    `}
    ${mediaQueries.lessThan('sm')`
    padding: 6px 16px;
    font-size: 12px;
  `}
  ${mediaQueries.lessThan('xs')`
    padding: 6px 14px;
    font-size: 11px;
    `}
  }
`

const LoadingContainer = styled('div')`
  ${ROW_CENTER}
  gap: 16px;
  opacity: 0.5;
`
export default BaseButton
