import { useDispatch, useSelector } from 'react-redux'
import { FilterOrdersContent } from './content'
import { FC } from 'react'
import { ordersActions } from 'app/containers/Orders/slice'
import { ordersSelectors } from 'app/containers/Orders/selectors'
import { Modal } from 'app/components/modal'

interface IFilterOrdersModalProps {}

export const FilterOrdersModal: FC<IFilterOrdersModalProps> = () => {
  const dispatch = useDispatch()

  const isOpen = useSelector(ordersSelectors.displayFilterOrdersModal)
  const handleModalClose = () => {
    dispatch(ordersActions.setDisplayFilterOrdersModal(false))
  }

  return (
    <Modal open={isOpen} onClose={handleModalClose}>
      <FilterOrdersContent />
    </Modal>
  )
}
