import { useDispatch, useSelector } from 'react-redux'
import { domainsSelectors } from 'app/containers/Domains/selectors'
import { domainsActions } from 'app/containers/Domains/slice'
import { FilterDomainsContent } from './content'
import { Modal } from 'app/components/modal'

export const FilterDomainsModal = () => {
  const dispatch = useDispatch()

  const isOpen = useSelector(domainsSelectors.displayFilterDomainsModal)
  const handleModalClose = () => {
    dispatch(domainsActions.setDisplayFilterDomainsModal(false))
  }

  return (
    <Modal open={isOpen} onClose={handleModalClose}>
      <FilterDomainsContent />
    </Modal>
  )
}
