import { Box, Tab } from '@mui/material'
import { TabList, TabPanel } from '@mui/lab'
import { C3_Medium, C3_Semibold } from 'styles/globalTypography'
import styled from 'styled-components'
import {
  ROW_ALIGN_START__JUSTIFY_CENTER,
  ROW_CENTER,
} from 'styles/globalStyles'

export const Wrapper = styled('div')`
  width: 100%;
  height: 100%;
`
export const TabBox = styled(Box)`
  width: 100%;
  border-bottom: 1;
  border-top: 1;
  border-color: 'var(--greyscale-50)';
`
export const StyledTab = styled(Tab)`
  ${C3_Medium}
  font-size: 10px !important;
  text-transform: none;
  color: var(--greyscale-400);
  &.Mui-selected {
    ${C3_Semibold}
    color: var(--greyscale-800);
  }
  margin: 0 12px;
  padding: 0px;
  min-width: 0;
`
export const StyledTabs = styled(TabList)``
export const Divider = styled('div')`
  width: 100%;
  height: 1px;
  background-color: var(--greyscale-50);
`
export const TabPage = styled(TabPanel)`
  height: 100%;
  width: 100%;
  padding: 0;
`
export const TabsWrapper = styled('div')<{ centered?: boolean }>`
  width: 100%;
  ${ROW_ALIGN_START__JUSTIFY_CENTER}
  ${({ centered }) => centered && `${ROW_CENTER}`}
`
