import axiosInstance from 'api/axios'
import { AxiosResponse } from 'axios'
import { AdminsState } from '../types'
import { adminsActions } from '../slice'
import { AdminsEndpoints } from 'api/endpoints/admins'

export function* fetchAdminsReq() {
  const response: AxiosResponse = yield axiosInstance.get(
    AdminsEndpoints.adminsList,
  )
  return response
}

export function* deleteRoleFromAdminReq(
  removeRoleFromAdmin: AdminsState['deleteConfirmationModal'],
) {
  const { role, admin } = removeRoleFromAdmin
  const response: AxiosResponse = yield axiosInstance.delete(
    AdminsEndpoints.deleteRoleFromAdmin,
    {
      data: {
        codes: [role?.code],
        adminID: admin?.id,
      },
    },
  )
  return response
}

export function* addRoleToAdminReq(
  action: ReturnType<typeof adminsActions.addRoleToAdmin>,
) {
  const { role, admin } = action.payload
  const response: AxiosResponse = yield axiosInstance.post(
    AdminsEndpoints.addRoleToAdmin,
    {
      codes: [role.code],
      adminID: admin.id,
    },
  )
  return response
}

export function* blockUnblockAdminReq(
  action: ReturnType<typeof adminsActions.blockUnblockAdmin>,
) {
  const { admin } = action.payload
  const response: AxiosResponse = yield axiosInstance.post(
    admin.isBlocked ? AdminsEndpoints.unblockAdmin : AdminsEndpoints.blockAdmin,
    {
      coreID: admin.coreID,
    },
  )
  return response
}

export function* addAdminReq(
  action: ReturnType<typeof adminsActions.addAdmin>,
) {
  const response: AxiosResponse = yield axiosInstance.post(
    AdminsEndpoints.addAdmin,
    action.payload,
  )
  return response
}
