import { styled } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import { useDispatch, useSelector } from 'react-redux'
import { vouchersActions } from '../slice'
import { vouchersSelectors } from '../selectors'
import { adminsSelectors } from 'app/containers/Admins/selectors'
import { IssueVoucherButton } from './issueVoucherButton'
import {
  FilterChipsInHeader,
  IShowablefilterData,
} from 'app/components/common/FilterChipsInHeader'
import { mediaQueries } from 'styles/mediaQueries'
import { PageHeader } from 'app/components/common/globals/pageHeader'
import PrimaryButton from 'app/components/button/primaryButton'

export const Header = () => {
  const dispatch = useDispatch()

  const filterVouchers = useSelector(vouchersSelectors.filterVouchers)
  const displayFilterModal = useSelector(
    vouchersSelectors.displayFilterVouchersModal,
  )
  const adminList = useSelector(adminsSelectors.adminList)

  const nameOfAdmin = adminList.find(
    admin => admin.id.toString() == filterVouchers?.adminID,
  )?.fullName

  const showablefilterVouchers: IShowablefilterData[] = [
    { label: 'User CoreID', value: filterVouchers?.userCoreID },
    { label: 'Status', value: filterVouchers?.status },
    { label: 'AdminID', value: nameOfAdmin },
    { label: 'Voucherer Name', value: filterVouchers?.vouchererName },
    { label: 'TxHash', value: filterVouchers?.txHash },
    {
      label: 'CreatedAt From',
      value: filterVouchers?.createdAtFrom,
      isDate: true,
    },
    { label: 'CreatedAt To', value: filterVouchers?.createdAtTo, isDate: true },
    {
      label: 'UpdateAt From',
      value: filterVouchers?.updateAtFrom,
      isDate: true,
    },
    { label: 'UpdateAt To', value: filterVouchers?.updateAtTo, isDate: true },
  ]

  const handleDelete = (label: string) => {
    dispatch(
      vouchersActions.setFilterVouchers({
        ...filterVouchers,
        [label]: undefined,
      }),
    )
    dispatch(vouchersActions.getVouchersList())
  }

  return (
    <>
      <PageHeader title="Vouchers">
        <ButtonsContainer>
          <IssueVoucherButton />
          <CTAContainer
            endIcon={<StyledSearchIcon />}
            onClick={() =>
              dispatch(vouchersActions.setDisplayFilterVouchersModal(true))
            }
          >
            <h4> Search</h4>
          </CTAContainer>
        </ButtonsContainer>
      </PageHeader>
      <FilterSection>
        {!displayFilterModal && (
          <FilterChipsInHeader
            showableFilter={showablefilterVouchers}
            deleteFilter={handleDelete}
          />
        )}
      </FilterSection>
    </>
  )
}

const CTAContainer = styled(PrimaryButton)`
  h4 {
    display: inline;
    margin: 0;
    ${mediaQueries.lessThan('xs')`
      display: none;   
      `}
  }
  ${mediaQueries.lessThan('xs')`
      width: 40px;
      & .MuiButton-endIcon {
        margin:0;
      }
      `}
`

const StyledSearchIcon = styled(SearchIcon)`
  color: var(--white);
`

const ButtonsContainer = styled('div')`
  display: flex;
  gap: 16px;
`

const FilterSection = styled('div')`
  display: flex;
  gap: 8px;
  width: 100%;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
`
