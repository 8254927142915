//FIXME: this functions may be changing or modifying
import jwt_decode from 'jwt-decode'
import { toast } from 'react-toastify'
import { put, takeLatest } from 'redux-saga/effects'
import { globalActions } from 'store/slice'
import { LocalStorageKeys, storage } from 'store/storage'

import { loginActions } from './slice'
import { LoginStatus, JWTencodedData } from './types'
import {
  logInCallbackSideEffect,
  loginSideEffect,
  refreshTokenCallbackSideEffect,
} from 'api/authorization/sideEffects'

export function* setTokenInHeader() {
  let shouldSendTokenRequest: boolean = true
  let tokenRequestsCount: number = 0
  let authToken: { accessToken: string; refreshToken: string }
  while (shouldSendTokenRequest) {
    if (tokenRequestsCount < 15 && shouldSendTokenRequest) {
      shouldSendTokenRequest = false
    }
    tokenRequestsCount = tokenRequestsCount += 1
    yield put(loginActions.setLoginStatus(LoginStatus.redirectLoading))
    try {
      authToken = yield logInCallbackSideEffect()
      shouldSendTokenRequest = false
      var decodedJWTAccessToken: JWTencodedData = jwt_decode(
        authToken.accessToken,
      )

      const coreID = decodedJWTAccessToken.sub.split(':')[1]
      yield storage.write(
        LocalStorageKeys.AUTH_ACCESS_TOKEN,
        authToken.accessToken,
      )
      yield storage.write(
        LocalStorageKeys.AUTH_REFRESH_TOKEN,
        authToken.refreshToken,
      )
      yield put(loginActions.setCoreId(coreID))
      yield put(globalActions.setIsLoggedIn(true))
    } catch (error) {}
  }
}

export function* setTokenInHeaderWithRefreshToken() {
  let authToken: { accessToken: string; refreshToken: string }

  yield put(loginActions.setLoginStatus(LoginStatus.redirectLoading))
  try {
    authToken = yield refreshTokenCallbackSideEffect()

    var decodedJWTAccessToken: JWTencodedData = jwt_decode(
      authToken.accessToken,
    )

    const coreID = decodedJWTAccessToken.sub.split(':')[1]

    yield put(loginActions.setCoreId(coreID))
    yield put(globalActions.setIsLoggedIn(true))
    yield storage.write(
      LocalStorageKeys.AUTH_ACCESS_TOKEN,
      authToken.accessToken,
    )
    yield storage.write(
      LocalStorageKeys.AUTH_REFRESH_TOKEN,
      authToken.refreshToken,
    )
  } catch (error) {}
}

export function* login() {
  try {
    yield put(loginActions.setLoginStatus(LoginStatus.loading))
    yield loginSideEffect()
  } catch (error: any) {
    yield put(loginActions.setLoginStatus(LoginStatus.init))
    toast(error.message, { type: 'error' })
  }
}
export function* logout() {
  try {
    yield put(globalActions.setIsLoggedIn(false))
  } catch (error: any) {
    console.log(error)
    yield put(loginActions.setLoginStatus(LoginStatus.init))
  }
}

export function* loginSaga() {
  yield takeLatest(loginActions.setAuthCode.type, setTokenInHeader)
  yield takeLatest(
    loginActions.setAuthCodeWithRefreshToken.type,
    setTokenInHeaderWithRefreshToken,
  )
  yield takeLatest(loginActions.login.type, login)
  yield takeLatest(loginActions.logout.type, logout)
}
