import { call, put, select, takeLatest } from 'redux-saga/effects'
import { domainsActions } from './slice'
import { getDomainsReq } from './providers'
import { AxiosResponse } from 'axios'
import { customToast } from 'app/components/toast/CustomToast'
import { errorMessage } from 'utils/parser'
import { domainsSelectors } from './selectors'
import { IFilterDomains, IDomain } from './types'

function* getDomains(): any {
  yield put(domainsActions.setIsLoadingDomains(true))
  try {
    const filterData: IFilterDomains = yield select(
      domainsSelectors.filterDomains,
    )
    const response: AxiosResponse = yield call(getDomainsReq, filterData)

    const listOfNewData = (response?.data?.data?.domains || []) as IDomain[]

    yield put(domainsActions.setDomains(listOfNewData))

    listOfNewData.length === 0 || listOfNewData.length < filterData.pageSize
      ? yield put(domainsActions.setIsAllDomainsFetched(true))
      : yield put(domainsActions.setIsAllDomainsFetched(false))
  } catch (error) {
    customToast.error(errorMessage(error))
  } finally {
    yield put(domainsActions.setIsLoadingDomains(false))
  }
}

export function* domainsSaga() {
  yield takeLatest(domainsActions.getDomainsList.type, getDomains)
}
