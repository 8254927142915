import { styled } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import { useDispatch, useSelector } from 'react-redux'
import { resubmissionsActions } from '../slice'
import { allResubmissionsSelectors } from '../selectors'
import {
  FilterChipsInHeader,
  IShowablefilterData,
} from 'app/components/common/FilterChipsInHeader'
import { PageHeader } from 'app/components/common/globals/pageHeader'
import PrimaryButton from 'app/components/button/primaryButton'

export const Header = () => {
  const dispatch = useDispatch()

  const filterResubmissions = useSelector(
    allResubmissionsSelectors.filterResubmissions,
  )
  const displayFilterResubmissionsModal = useSelector(
    allResubmissionsSelectors.displayFilterResubmissionsModal,
  )

  const handleOpenFilterModal = () => {
    dispatch(resubmissionsActions.setDisplayFilterResubmissionsModal(true))
  }

  const showableFilterResubmissions: IShowablefilterData[] = [
    { label: 'Core ID', value: filterResubmissions?.coreID },
    { label: 'User Status', value: filterResubmissions?.userStatus },
    { label: 'Provider Status', value: filterResubmissions?.providerStatus },
    { label: 'Denied Count', value: filterResubmissions?.deniedCount },
    {
      label: 'CreatedAt From',
      value: filterResubmissions?.createdAtFrom,
      isDate: true,
    },
    {
      label: 'CreatedAt To',
      value: filterResubmissions?.createdAtTo,
      isDate: true,
    },
    {
      label: 'UpdateAt From',
      value: filterResubmissions?.updateAtFrom,
      isDate: true,
    },
    {
      label: 'UpdateAt To',
      value: filterResubmissions?.updateAtTo,
      isDate: true,
    },
  ]

  const handleDelete = (label: string) => {
    dispatch(
      resubmissionsActions.setFilterResubmissions({
        ...filterResubmissions,
        [label]: undefined,
      }),
    )
    dispatch(resubmissionsActions.getResubmissionsList())
  }

  return (
    <>
      <PageHeader title="Resubmissions">
        <PrimaryButton
          onClick={handleOpenFilterModal}
          endIcon={<StyledSearchIcon />}
        >
          Search
        </PrimaryButton>
      </PageHeader>
      <FilterSection>
        {!displayFilterResubmissionsModal && (
          <FilterChipsInHeader
            showableFilter={showableFilterResubmissions}
            deleteFilter={handleDelete}
          />
        )}
      </FilterSection>
    </>
  )
}

const FilterSection = styled('div')`
  display: flex;
  gap: 8px;
  width: 100%;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
`
const StyledSearchIcon = styled(SearchIcon)`
  color: var(--white);
`
