import axiosInstance from 'api/axios'
import { AxiosResponse } from 'axios'
import { IFilterPhoneVerifications } from '../types'
import { PhoneVerificationsEndpoints } from 'api/endpoints/verifications'

export function* getPhoneVerificationsDataReq(
  filterData: IFilterPhoneVerifications,
) {
  const response: AxiosResponse = yield axiosInstance.get(
    PhoneVerificationsEndpoints.phoneVerificationsList(filterData),
  )
  return response
}
