import { call, put, takeLatest } from 'redux-saga/effects'
import { getApplicantDetail, postResubmissionConfirmation } from './providers'
import { resubmissionItemActions } from './slice'
import { IServerResponse } from 'app/types'
import { customToast } from 'app/components/toast/CustomToast'
import {
  IKYCProviderData,
  IResubmissionItemInfoRaw,
  IResubmissionSubmitRequest,
  IUserSuggestion,
  IVerificationSumsub,
} from './types'
import {
  getKYCDataFromSumsubObject,
  userSuggestionKYCProviderMapper,
} from './providers/utils'
import { PayloadAction } from '@reduxjs/toolkit'

function* FetchResubmissionItem({ payload: refrenceId }: any): any {
  yield put(resubmissionItemActions.setIsLoading(true))
  try {
    const data = (yield call(getApplicantDetail, refrenceId)) as IServerResponse
    const resubmissionInfo = data.data as IResubmissionItemInfoRaw
    const sumsubData = JSON.parse(
      resubmissionInfo.kycProviderData,
    ) as IVerificationSumsub
    const rawUserSuggestions = resubmissionInfo.userSuggestion
      ? (JSON.parse(resubmissionInfo.userSuggestion) as IUserSuggestion)
      : {}
    const userSuggestion: IKYCProviderData =
      userSuggestionKYCProviderMapper(rawUserSuggestions)
    const voucherName = resubmissionInfo.vouchererName as any
    const kycProviderData: IKYCProviderData = getKYCDataFromSumsubObject(
      sumsubData,
      voucherName,
    )
    if (!sumsubData.id) {
      throw new Error('No sumsub id found')
    }
    yield put(
      resubmissionItemActions.setResubmissionItem({
        kycProviderData,
        userSuggestion,
        applicantId: sumsubData.id ?? '',
        rawUserSuggestions,
      }),
    )
  } catch (error) {
    console.log('Error fetching resubmission item', error)

    yield call(customToast.error, 'Error fetching resubmission item')
  } finally {
    yield put(resubmissionItemActions.setIsLoading(false))
  }
}
function* SendResubmissionConfirmation(
  payload: PayloadAction<IResubmissionSubmitRequest>,
): any {
  yield put(resubmissionItemActions.setSubmitLoading(true))

  try {
    const response = yield call(postResubmissionConfirmation, payload.payload)
    console.log(
      '🚀 ~ function*SendResubmissionConfirmation ~ response:',
      response,
    )
    yield call(customToast.success, 'Resubmission confirmation sent')
  } catch (error) {
    console.log('Error sending resubmission confirmation', error)
    yield call(customToast.error, 'Error sending resubmission confirmation')
  } finally {
    yield put(resubmissionItemActions.setSubmitLoading(false))
  }
}

export function* resubmissionItemSaga() {
  yield takeLatest(
    resubmissionItemActions.fetchResubmissionItem.type,
    FetchResubmissionItem,
  )
  yield takeLatest(
    resubmissionItemActions.sendResubmissionConfirmation.type,
    SendResubmissionConfirmation,
  )
}
