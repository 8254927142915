import { configurationsActions } from '../slice'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { configurationsSelectors } from '../selectors'
import { styled } from '@mui/material'
import {
  CONFIGURATIONS_TABLE_KEY,
  columnsForShowInConfigurationsTable,
} from '../constants'
import SortableTable from 'app/components/table/sortableTable'
import { capitalizeAllCharacters } from 'utils/string'
import { ISortType } from 'app/types'
import { MAX_SAFE_INTEGER } from 'app/constants'

export const ConfigurationsTable = () => {
  const dispatch = useDispatch()

  const configurationsList = useSelector(
    configurationsSelectors.configurationsList,
  )
  const isLoadingConfigurations = useSelector(
    configurationsSelectors.isLoadingConfigurations,
  )
  const filterConfigurations = useSelector(
    configurationsSelectors.filterConfigurations,
  )
  const lastPageOfConfigurationsTable = useSelector(
    configurationsSelectors.lastPageOfConfigurationsTable,
  )
  const isAllConfigurationsFetched = useSelector(
    configurationsSelectors.isAllConfigurationsFetched,
  )

  useEffect(() => {
    dispatch(configurationsActions.getConfigurations())
  }, [])

  const handleSetNumberRowsPerPage = (num: number) => {
    const tablePageSizeTitle = `${CONFIGURATIONS_TABLE_KEY}_pageSize`
    localStorage.setItem(tablePageSizeTitle, num.toString())
    dispatch(
      configurationsActions.setFilterConfigurations({
        ...filterConfigurations,
        pageSize: num,
      }),
    )
    dispatch(configurationsActions.getConfigurations())
  }
  return (
    <Wrapper>
      <SortableTable
        name={CONFIGURATIONS_TABLE_KEY}
        isLoading={isLoadingConfigurations}
        data={configurationsList}
        columns={columnsForShowInConfigurationsTable}
        handleChangeLastPage={(newPage: number) => {
          dispatch(
            configurationsActions.setFilterConfigurations({
              ...filterConfigurations,
              page: newPage,
            }),
          )

          dispatch(configurationsActions.getConfigurations())
        }}
        setPage={page =>
          dispatch(configurationsActions.setLastPageOfConfigurationsTable(page))
        }
        page={lastPageOfConfigurationsTable}
        setNumberRowsPerPage={num => handleSetNumberRowsPerPage(num)}
        handleCustomRequestSort={(property, order) => {
          dispatch(
            configurationsActions.setFilterConfigurations({
              ...filterConfigurations,
              sortField: property,
              sortType: capitalizeAllCharacters(order) as ISortType,
            }),
          )
          const oneRow = configurationsList[0]
          if (oneRow) {
            const propertyType = typeof oneRow[property as keyof typeof oneRow]
            if (propertyType === 'number') {
              dispatch(configurationsActions.getConfigurations())
            }
          }
        }}
        countOfData={MAX_SAFE_INTEGER}
        showNumberOfPages={false}
        disableNextButton={isAllConfigurationsFetched}
        hasPagination={false}
      />
    </Wrapper>
  )
}

const Wrapper = styled('div')`
  justify-content: center;
  grid-gap: 10px 0;
  margin-top: 20px;
  margin-bottom: 20px;
  min-width: 0;
`
