import { call, put, select, takeLatest } from 'redux-saga/effects'
import { vouchersActions } from './slice'
import { addVoucherReq, getVouchersReq } from './providers'
import { customToast } from 'app/components/toast/CustomToast'
import { Voucher } from './types'
import { vouchersSelectors } from './selectors'
import { errorMessage } from 'utils/parser'

function* getVouchersList(): any {
  yield put(vouchersActions.setIsLoadingVouchers(true))
  try {
    const filterVouchers = yield select(vouchersSelectors.filterVouchers)

    const response = yield call(getVouchersReq, filterVouchers)

    const vouchersList = (response?.data?.data?.adminOrders ?? []) as Voucher[]

    yield put(vouchersActions.setVoucherslist(vouchersList))

    vouchersList.length === 0 || vouchersList.length < filterVouchers.pageSize
      ? yield put(vouchersActions.setIsAllVouchersFetched(true))
      : yield put(vouchersActions.setIsAllVouchersFetched(false))
  } catch (error) {
    customToast.error(errorMessage(error))
  } finally {
    yield put(vouchersActions.setIsLoadingVouchers(false))
  }
}

function* addVoucher(
  action: ReturnType<typeof vouchersActions.issueVoucher>,
): any {
  try {
    const response = yield call(addVoucherReq, action.payload)
    customToast.success(response?.data?.message || 'Added successfully')
    const filterVouchers = yield select(vouchersSelectors.filterVouchers)
    yield put(vouchersActions.getVouchersList(filterVouchers))
  } catch (error) {
    customToast.error(errorMessage(error))
  }
}

export function* vouchersSaga() {
  yield takeLatest(vouchersActions.getVouchersList, getVouchersList)
  yield takeLatest(vouchersActions.issueVoucher, addVoucher)
}
