import { createSelector } from '@reduxjs/toolkit'

import { RootState } from 'store/types'
import { initialState } from './slice'

export const documentRejectionsDomains = {
  root: (state: RootState) => state.documentRejections || initialState,
  documentRejectionsList: (state: RootState) =>
    state.documentRejections.documentRejectionsList,
  isLoadingDocumentRejections: (state: RootState) =>
    state.documentRejections.isLoadingDocumentRejections,
  filterDocumentRejections: (state: RootState) =>
    state.documentRejections.filterDocumentRejections,
  lastPageOfDocumentRejectionsTable: (state: RootState) =>
    state.documentRejections.lastPageOfDocumentRejectionsTable,
  isAllDocumentRejectionsFetched: (state: RootState) =>
    state.documentRejections.isAllDocumentRejectionsFetched,
  displayFilterDocumentRejectionsModal: (state: RootState) =>
    state.documentRejections.displayFilterDocumentRejectionsModal,
}

export const documentRejectionsSelectors = {
  root: createSelector(documentRejectionsDomains.root, root => root),
  documentRejectionsList: createSelector(
    documentRejectionsDomains.documentRejectionsList,
    documentRejections => documentRejections,
  ),
  isLoadingDocumentRejections: createSelector(
    documentRejectionsDomains.isLoadingDocumentRejections,
    isLoading => isLoading,
  ),
  filterDocumentRejections: createSelector(
    documentRejectionsDomains.filterDocumentRejections,
    filterData => filterData,
  ),
  lastPageOfDocumentRejectionsTable: createSelector(
    documentRejectionsDomains.lastPageOfDocumentRejectionsTable,
    lastPageOfTable => lastPageOfTable,
  ),
  isAllDocumentRejectionsFetched: createSelector(
    documentRejectionsDomains.isAllDocumentRejectionsFetched,
    isAllFetched => isAllFetched,
  ),
  displayFilterDocumentRejectionsModal: createSelector(
    documentRejectionsDomains.displayFilterDocumentRejectionsModal,
    displayFilterModal => displayFilterModal,
  ),
}
