import * as Yup from 'yup'
import { getAddress } from 'corebc'

export const isAddress = (_address: string) => {
  try {
    getAddress(_address.replace('0x', ''))
    return true
  } catch (error) {
    return false
  }
}

export const addIsAddressMethodToYup = () => {
  Yup.addMethod(Yup.string, 'isAddress', function (errorMessage) {
    return this.test(`test-address`, errorMessage, function (value) {
      const { path, createError } = this
      return (
        isAddress(value || '') ||
        createError({ path, message: 'Address is not valid' })
      )
    })
  })
}
