import { createSelector } from '@reduxjs/toolkit'

import { RootState } from 'store/types'
import { initialState } from './slice'

export const emailInvalidationsDomains = {
  root: (state: RootState) => state.emailInvalidations || initialState,
  emailInvalidationsList: (state: RootState) =>
    state.emailInvalidations?.emailInvalidationsList || [],
  isLoadingEmailInvalidations: (state: RootState) =>
    state.emailInvalidations?.isLoadingEmailInvalidations || false,
  filterEmailInvalidations: (state: RootState) =>
    state.emailInvalidations?.filterEmailInvalidations,
  lastPageOfEmailInvalidationsTable: (state: RootState) =>
    state.emailInvalidations?.lastPageOfEmailInvalidationsTable,
  isAllEmailInvalidationsFetched: (state: RootState) =>
    state.emailInvalidations?.isAllEmailInvalidationsFetched || false,
  displayFilterEmailInvalidationsModal: (state: RootState) =>
    state.emailInvalidations?.displayFilterEmailInvalidationsModal || false,
}

export const emailInvalidationsSelectors = {
  root: createSelector(emailInvalidationsDomains.root, root => root),
  emailInvalidationsList: createSelector(
    emailInvalidationsDomains.emailInvalidationsList,
    emailInvalidations => emailInvalidations,
  ),
  isLoadingEmailInvalidations: createSelector(
    emailInvalidationsDomains.isLoadingEmailInvalidations,
    isLoading => isLoading,
  ),
  filterEmailInvalidations: createSelector(
    emailInvalidationsDomains.filterEmailInvalidations,
    filterData => filterData,
  ),
  lastPageOfEmailInvalidationsTable: createSelector(
    emailInvalidationsDomains.lastPageOfEmailInvalidationsTable,
    lastPageOfTable => lastPageOfTable,
  ),
  isAllEmailInvalidationsFetched: createSelector(
    emailInvalidationsDomains.isAllEmailInvalidationsFetched,
    isAllFetched => isAllFetched,
  ),
  displayFilterEmailInvalidationsModal: createSelector(
    emailInvalidationsDomains.displayFilterEmailInvalidationsModal,
    displayFilterModal => displayFilterModal,
  ),
}
