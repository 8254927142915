import {
  IKYCProviderAddress,
  IKYCProviderData,
  IUserSuggestion,
  IVerificationSumsub,
} from '../types'

export const userSuggestionKYCProviderMapper = (
  userSuggestion: IUserSuggestion,
): IKYCProviderData => {
  return {
    firstName: userSuggestion.Full_Name,
    lastName: userSuggestion.Full_Name,
    firstNameEn: userSuggestion.Full_name_en,
    lastNameEn: userSuggestion.Full_name_en,
    middleName: userSuggestion.Full_Name,
    middleNameEn: userSuggestion.Full_name_en,
    validUntil:
      userSuggestion.Proof_of_address_expiry_date ||
      userSuggestion.Driver_s_Licence_Expiry_Date ||
      userSuggestion.Passport_Expiry_Date ||
      userSuggestion.ID_Card_Expiry_Date ||
      userSuggestion.Residence_Permit_Expiry_Date,
    issuedDate:
      userSuggestion.Proof_of_address_issue_date ||
      userSuggestion.Driver_s_Licence_Issue_Date ||
      userSuggestion.Passport_Issue_Date ||
      userSuggestion.ID_Card_Issue_Date ||
      userSuggestion.Residence_Permit_Issue_Date,
    address: {
      street: userSuggestion.Proof_of_address_street,
      streetEn: userSuggestion.Proof_of_address_street_en,
      subStreet: userSuggestion.Proof_of_address_street2,
      subStreetEn: userSuggestion.Proof_of_address_street2_en,
      state: userSuggestion.Division,
      stateEn: userSuggestion.Division_en,
      town: userSuggestion.Proof_of_address_city,
      townEn: userSuggestion.Proof_of_address_city_en,
      postCode: userSuggestion.Proof_of_address_zip_code,
    },
    dob: userSuggestion.Date_of_Birth,
    nationality: userSuggestion.Nationality,
    placeOfBirth: userSuggestion.Place_of_Birth,
    number:
      userSuggestion.Driver_s_Licence_Number ||
      userSuggestion.Passport_Number ||
      userSuggestion.ID_Card_Number ||
      userSuggestion.Residence_Permit_Number,
    idDocType: userSuggestion.Driver_s_Licence_Number
      ? 'Driver_s_Licence'
      : userSuggestion.Passport_Number
        ? 'Passport'
        : userSuggestion.ID_Card_Number
          ? 'ID_Card'
          : userSuggestion.Proof_of_address_expiry_date
            ? 'Proof_of_address'
            : 'Residence_Permit',
    gender: userSuggestion.Sex,
  }
}
export const getKYCDataFromSumsubObject = (
  sumsubData: IVerificationSumsub,
  vouchererName:
    | 'IDCardVerifier'
    | 'PassportVerifier'
    | 'ResidencePermitVerifier'
    | 'DriverLicenseVerifier'
    | 'AddressVerifier',
): IKYCProviderData => {
  let _docsList
  if (vouchererName === 'AddressVerifier') {
    _docsList = sumsubData?.info?.idDocs
  } else {
    _docsList = sumsubData?.info?.idDocs?.filter(
      _d =>
        _d.country === sumsubData?.info?.country &&
        _d.dob !== null &&
        _d.idDocType === getSumsubTypeFromVouchererName(vouchererName),
    )
  }

  let _validDocs = _docsList?.filter(_d => _d.firstName || _d.lastName)

  let sumsubIdDoc
  if (_validDocs === null || _validDocs?.length === 0) {
    sumsubIdDoc = null
  } else {
    sumsubIdDoc =
      vouchererName === 'AddressVerifier'
        ? _validDocs?.[_validDocs.length - 1]
        : _validDocs?.[0]
  }

  const kycData: IKYCProviderData = {
    firstNameEn: sumsubData?.info?.firstNameEn,
    lastNameEn: sumsubData?.info?.lastNameEn,
    middleNameEn: sumsubData?.info?.middleNameEn,
    gender: sumsubData?.info?.gender,
    ...sumsubIdDoc,
  }
  return kycData
}

export const doesAddressExist = (data: IKYCProviderData) => {
  return (
    data.address?.street ||
    data.address?.town ||
    data.address?.state ||
    data.address?.postCode ||
    data.address?.country ||
    data.address?.subStreet ||
    data.address?.subStreetEn ||
    data.address?.stateEn ||
    data.address?.townEn
  )
}
export function isIKYCProviderAddress(
  value: any,
): value is IKYCProviderAddress {
  return value && typeof value === 'object' && 'street' in value
}
const getSumsubTypeFromVouchererName = (vouchererName: string) => {
  switch (vouchererName) {
    case 'IDCardVerifier':
      return 'ID_CARD'
    case 'PassportVerifier':
      return 'PASSPORT'
    case 'ResidencePermitVerifier':
      return 'RESIDENCE_PERMIT'
    case 'DriverLicenseVerifier':
      return 'DRIVERS'
    case 'AddressVerifier':
      return 'PROOF_OF_ADDRESS'
    default:
      return 'UNKNOWN'
  }
}
