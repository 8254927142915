import * as React from 'react'
import Tooltip from '@mui/material/Tooltip'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import styled from '@emotion/styled'

interface Props {
  children: React.ReactNode
  message: string
}
export default function CustomTooltip({ children, message }: Props) {
  return (
    <Tooltip title={message}>
      <TooltipChildWrapper>{children}</TooltipChildWrapper>
    </Tooltip>
  )
}

const TooltipChildWrapper = styled.div``
