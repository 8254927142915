import { createSelector } from '@reduxjs/toolkit'

import { RootState } from 'store/types'
import { initialState } from './slice'

export const botOrdersDomains = {
  root: (state: RootState) => state.botOrders || initialState,
  botOrderList: (state: RootState) => state.botOrders.botOrderList,
  isLoadingBotOrders: (state: RootState) => state.botOrders.isLoadingBotOrders,
  filterBotOrders: (state: RootState) => state.botOrders.filterBotOrders,
  lastPageOfBotOrdersTable: (state: RootState) =>
    state.botOrders.lastPageOfBotOrdersTable,
  isAllBotOrdersFetched: (state: RootState) =>
    state.botOrders.isAllBotOrdersFetched,
  displayFilterBotOrdersModal: (state: RootState) =>
    state.botOrders.displayFilterBotOrdersModal,
}

export const botOrdersSelectors = {
  root: createSelector(botOrdersDomains.root, root => root),
  botOrderList: createSelector(
    botOrdersDomains.botOrderList,
    botOrders => botOrders,
  ),
  isLoadingBotOrders: createSelector(
    botOrdersDomains.isLoadingBotOrders,
    isLoading => isLoading,
  ),
  filterBotOrders: createSelector(
    botOrdersDomains.filterBotOrders,
    filterBotOrders => filterBotOrders,
  ),
  lastPageOfBotOrdersTable: createSelector(
    botOrdersDomains.lastPageOfBotOrdersTable,
    lastPageOfTable => lastPageOfTable,
  ),
  isAllBotOrdersFetched: createSelector(
    botOrdersDomains.isAllBotOrdersFetched,
    isAllFetched => isAllFetched,
  ),
  displayFilterBotOrdersModal: createSelector(
    botOrdersDomains.displayFilterBotOrdersModal,
    displayFilterModal => displayFilterModal,
  ),
}
