import { useEffect } from 'react'
import { styled } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'

import { useLocation, useNavigate } from 'react-router-dom'
import { allResubmissionsSelectors } from '../selectors'
import { resubmissionsActions } from '../slice'
import { AppPages, MAX_SAFE_INTEGER } from 'app/constants'
import SortableTable from 'app/components/table/sortableTable'
import {
  ORDER_OF_SORT_IN_SUMSUB_STATUS,
  RESUBMISSIONS_TABLE_KEY,
  columnsForShowInResubmissionsTable,
} from '../constants'
import { capitalizeAllCharacters } from 'utils/string'
import { IResubmissionItem } from '../types'
import { searchUserSelectors } from 'app/containers/SearchUser/selector'
import { resubmissionItemActions } from 'app/containers/ResubmissionItem/slice'
import { resubmissionItemSelectors } from 'app/containers/ResubmissionItem/selectors'
import { redirectionToSumsub } from 'utils/redirections'
import { ISortType } from 'app/types'

export const TableOfData = () => {
  const { pathname } = useLocation()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const resubmissions = useSelector(allResubmissionsSelectors.resubmissionsList)
  const isLoadingResubmissions = useSelector(
    allResubmissionsSelectors.isLoadingResubmissions,
  )
  const lastPageOfResubmissionsTable = useSelector(
    allResubmissionsSelectors.lastPageOfResubmissionsTable,
  )
  const filterResubmissions = useSelector(
    allResubmissionsSelectors.filterResubmissions,
  )
  const isAllResubmissionsFetched = useSelector(
    allResubmissionsSelectors.isAllResubmissionsFetched,
  )
  const searchUserCoreId = useSelector(searchUserSelectors.coreId)

  const resubmissionItem = useSelector(
    resubmissionItemSelectors.resubmissionItem,
  )

  useEffect(() => {
    if (pathname === AppPages.SearchUser) {
      // add coreId to filterData if the user is coming from search user page
      // and the search term (coreId) is not empty
      // if the user is coming from search user page and the search term (coreId) is empty
      // remove coreId filter and get all orders

      const searchUserFilters = {
        ...filterResubmissions,
        coreID: searchUserCoreId ? searchUserCoreId : '',
      }
      dispatch(resubmissionsActions.setFilterResubmissions(searchUserFilters))
    }
    dispatch(resubmissionsActions.getResubmissionsList())
  }, [searchUserCoreId])

  const goToResubmission = (
    referenceId: string,
    verificationId: number,
    userReviewStatus: string,
    providerStatus: string,
  ) => {
    const params = {
      referenceId,
      verificationId: verificationId.toString(),
      userReviewStatus,
      providerStatus,
    }

    const queryParams = new URLSearchParams(params).toString()

    navigate(`${AppPages.ResubmissionItem}?${queryParams}`)
  }

  const handleSetNumberRowsPerPage = (num: number) => {
    const tablePageSizeTitle = `${RESUBMISSIONS_TABLE_KEY}_pageSize`
    localStorage.setItem(tablePageSizeTitle, num.toString())
    dispatch(
      resubmissionsActions.setFilterResubmissions({
        ...filterResubmissions,
        pageSize: num,
      }),
    )
    dispatch(resubmissionsActions.getResubmissionsList())
  }

  const columnIdForCustomSort = columnsForShowInResubmissionsTable[2].id

  function customComparators(a: any, b: any) {
    if (!columnIdForCustomSort) {
      return 0
    }
    const aValue = a[columnIdForCustomSort] as string
    const bValue = b[columnIdForCustomSort] as string
    const aIndex = ORDER_OF_SORT_IN_SUMSUB_STATUS.indexOf(aValue)
    const bIndex = ORDER_OF_SORT_IN_SUMSUB_STATUS.indexOf(bValue)
    if (aIndex === -1 || bIndex === -1) {
      return 0
    }
    return (aIndex - bIndex) as number
  }

  const handleOnCellClick = (row: IResubmissionItem, columnId: string) => {
    if (columnId === 'referenceId') {
      dispatch(resubmissionItemActions.fetchResubmissionItem(row.referenceId))

      redirectionToSumsub(resubmissionItem.applicantId)
    }
  }

  return (
    <Wrapper>
      <SortableTable
        name={RESUBMISSIONS_TABLE_KEY}
        isLoading={isLoadingResubmissions}
        data={resubmissions}
        columns={columnsForShowInResubmissionsTable}
        handleChangeLastPage={(newPage: number) => {
          dispatch(
            resubmissionsActions.setFilterResubmissions({
              ...filterResubmissions,
              page: newPage,
            }),
          )

          dispatch(resubmissionsActions.getResubmissionsList())
        }}
        onRowClick={row =>
          goToResubmission(
            row.referenceId,
            row.verificationId,
            row.userReviewStatus,
            row.providerStatus,
          )
        }
        setPage={page =>
          dispatch(resubmissionsActions.setLastPageOfResubmissionsTable(page))
        }
        page={lastPageOfResubmissionsTable}
        customComparators={customComparators}
        columnCustomComparator={columnIdForCustomSort}
        setNumberRowsPerPage={num => handleSetNumberRowsPerPage(num)}
        handleCustomRequestSort={(property, order) => {
          dispatch(
            resubmissionsActions.setFilterResubmissions({
              ...filterResubmissions,
              sortField: property,
              sortType: capitalizeAllCharacters(order) as ISortType,
            }),
          )

          const oneRow = resubmissions[0]
          if (oneRow) {
            const propertyType = typeof oneRow[property as keyof typeof oneRow]
            if (propertyType === 'number') {
              dispatch(resubmissionsActions.getResubmissionsList())
            }
          }
        }}
        showNumberOfPages={false}
        countOfData={MAX_SAFE_INTEGER}
        disableNextButton={isAllResubmissionsFetched}
        onCellClick={(row: any, column: string) =>
          handleOnCellClick(row, column)
        }
      />
    </Wrapper>
  )
}

const Wrapper = styled('div')`
  justify-content: center;
  grid-gap: 10px 0;
  margin-top: 20px;
  margin-bottom: 20px;
  min-width: 0;
`
