import { ColumnOfTable } from 'app/components/table/sortableTable'
import { getTableSettingsFromStorage } from 'utils/storage'

export const APIKEYS_TABLE_KEY = 'apiKeysTable'

export const columnsForShowInApiKeysTable: ColumnOfTable[] = [
  { id: 'uuID', label: 'UUID' },
  { id: 'clientDomainID', label: 'Client Domain ID' },
  { id: 'clientDomainUuid', label: 'Client Domain UUID' },
  { id: 'token', label: 'Token' },
  { id: 'createdAt', label: 'Created At', isDate: true },
  { id: 'updateAt', label: 'Updated At', isDate: true },
]

export const {
  pageSize: localPageSize,
  sortType: localSortType,
  sortField: localSortField,
} = getTableSettingsFromStorage({ tableKey: APIKEYS_TABLE_KEY })
