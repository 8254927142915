import { styled } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import { useDispatch, useSelector } from 'react-redux'

import { transmissionsSelectors } from '../selectors'
import { transmissionsActions } from '../slice'
import {
  FilterChipsInHeader,
  IShowablefilterData,
} from 'app/components/common/FilterChipsInHeader'
import { PageHeader } from 'app/components/common/globals/pageHeader'
import PrimaryButton from 'app/components/button/primaryButton'

export const Header = () => {
  const dispatch = useDispatch()

  const filterData = useSelector(transmissionsSelectors.filterTransmissions)
  const displayFilterModal = useSelector(
    transmissionsSelectors.displayFilterTransmissionsModal,
  )

  const handleOpenFilterModal = () => {
    dispatch(transmissionsActions.setDisplayFilterTransmissionsModal(true))
  }

  const showablefilterData: IShowablefilterData[] = [
    { label: 'Order Status', value: filterData.orderStatus },
    { label: 'SourceKey', value: filterData.sourceKey },
    { label: 'User CoreID', value: filterData.userCoreID },
    { label: 'RefrenceKey', value: filterData.referenceKey },
    { label: 'CreatedAt From', value: filterData.createdAtFrom, isDate: true },
    { label: 'CreatedAt To', value: filterData.createdAtTo, isDate: true },
    { label: 'TransmissionID', value: filterData.transmissionID },
    { label: 'Client DomainUUID', value: filterData.clientDomainUUID },
    { label: 'Price', value: filterData.price },
    { label: 'Plan', value: filterData.plan },
  ]

  const handleDelete = (label: string) => {
    dispatch(
      transmissionsActions.setFilterTransmissions({
        ...filterData,
        [label]: undefined,
      }),
    )
    dispatch(transmissionsActions.getTransmissionsList())
  }

  return (
    <>
      <PageHeader title="Transmissions">
        <PrimaryButton
          onClick={handleOpenFilterModal}
          endIcon={<StyledSearchIcon />}
        >
          Search
        </PrimaryButton>
      </PageHeader>
      <FilterSection>
        {!displayFilterModal && (
          <FilterChipsInHeader
            showableFilter={showablefilterData}
            deleteFilter={handleDelete}
          />
        )}
      </FilterSection>
    </>
  )
}

const FilterSection = styled('div')`
  display: flex;
  gap: 8px;
  width: 100%;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
`
const StyledSearchIcon = styled(SearchIcon)`
  color: var(--white);
`
