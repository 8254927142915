import { call, put, select, takeLatest } from 'redux-saga/effects'
import { documentInvalidationsActions } from './slice'
import { getDocumentInvalidationsReq } from './providers'
import { AxiosResponse } from 'axios'
import { customToast } from 'app/components/toast/CustomToast'
import { errorMessage } from 'utils/parser'
import { documentInvalidationsSelectors } from './selectors'
import { IFilterDocumentInvalidations, IDocumentInvalidation } from './types'

function* getDocumentInvalidations(): any {
  yield put(
    documentInvalidationsActions.setIsLoadingDocumentInvalidations(true),
  )
  try {
    const filterDocumentInvalidations: IFilterDocumentInvalidations =
      yield select(documentInvalidationsSelectors.filterDocumentInvalidations)

    const response: AxiosResponse = yield call(
      getDocumentInvalidationsReq,
      filterDocumentInvalidations,
    )

    const documentInvalidationsList = (response?.data?.data?.invalidations ||
      []) as IDocumentInvalidation[]

    yield put(
      documentInvalidationsActions.setDocumentInvalidations(
        documentInvalidationsList,
      ),
    )

    documentInvalidationsList.length === 0 ||
    documentInvalidationsList.length < filterDocumentInvalidations.pageSize
      ? yield put(
          documentInvalidationsActions.setIsAllDocumentInvalidationsFetched(
            true,
          ),
        )
      : yield put(
          documentInvalidationsActions.setIsAllDocumentInvalidationsFetched(
            false,
          ),
        )
  } catch (error) {
    customToast.error(errorMessage(error))
  } finally {
    yield put(
      documentInvalidationsActions.setIsLoadingDocumentInvalidations(false),
    )
  }
}

export function* documentInvalidationsSaga() {
  yield takeLatest(
    documentInvalidationsActions.getDocumentInvalidations.type,
    getDocumentInvalidations,
  )
}
