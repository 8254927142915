import { createSelector } from '@reduxjs/toolkit'

import { RootState } from 'store/types'
import { initialState } from './slice'

export const apiKeysDomains = {
  root: (state: RootState) => state.apiKeys || initialState,
  apiKeysList: (state: RootState) => state.apiKeys.apiKeysList,
  isLoadingApiKeys: (state: RootState) => state.apiKeys.isLoadingApiKeys,
  filterApiKeys: (state: RootState) => state.apiKeys.filterApiKeys,
  lastPageOfApiKeysTable: (state: RootState) =>
    state.apiKeys.lastPageOfApiKeysTable,
  isAllApiKeysFetched: (state: RootState) => state.apiKeys.isAllApiKeysFetched,
  displayFilterApiKeysModal: (state: RootState) =>
    state.apiKeys.displayFilterApiKeysModal,
}

export const apiKeysSelectors = {
  root: createSelector(apiKeysDomains.root, root => root),
  apiKeysList: createSelector(apiKeysDomains.apiKeysList, apiKeys => apiKeys),
  isLoadingApiKeys: createSelector(
    apiKeysDomains.isLoadingApiKeys,
    isLoading => isLoading,
  ),
  filterApiKeys: createSelector(
    apiKeysDomains.filterApiKeys,
    filterData => filterData,
  ),
  lastPageOfApiKeysTable: createSelector(
    apiKeysDomains.lastPageOfApiKeysTable,
    lastPageOfTable => lastPageOfTable,
  ),
  isAllApiKeysFetched: createSelector(
    apiKeysDomains.isAllApiKeysFetched,
    isAllFetched => isAllFetched,
  ),
  displayFilterApiKeysModal: createSelector(
    apiKeysDomains.displayFilterApiKeysModal,
    displayFilterModal => displayFilterModal,
  ),
}
