import { ColumnOfTable } from 'app/components/table/sortableTable'
import { getTableSettingsFromStorage } from 'utils/storage'

export const TRANSMISSIONS_TABLE_KEY = 'transmissionsTable'

export const columnsForShowInTransmissionsTable: ColumnOfTable[] = [
  { id: 'userCoreID', label: 'User CoreID' },
  { id: 'confirmTxHash', label: 'Confirm TxHash' },
  { id: 'failureReason', label: 'Failure Reason' },
  { id: 'initiateTxHash', label: 'Initiate TxHash' },
  { id: 'orderStatus', label: 'Order Status' },
  { id: 'plan', label: 'Plan' },
  { id: 'price', label: 'Price' },
  { id: 'referenceKey', label: 'Reference Key' },
  { id: 'sourceKey', label: 'Source Key' },
  { id: 'transmissionID', label: 'Transmission ID' },
  { id: 'clientDomainUUID', label: 'Client DomainUUID' },
  { id: 'createdAt', label: 'Created At', isDate: true },
  { id: 'expiration', label: 'Expiration', isDate: true },
]

export const {
  pageSize: localPageSize,
  sortType: localSortType,
  sortField: localSortField,
} = getTableSettingsFromStorage({ tableKey: TRANSMISSIONS_TABLE_KEY })

export const STATUS_OPTIONS = [
  'PENDING',
  'INITIATING',
  'INITIATED',
  'CONFIRMING',
  'CONFIRMED',
  'FAILED',
]
export const PLAN_OPTIONS = ['CHARGE', 'BUNDLE']
