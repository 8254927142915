import { PayloadAction } from '@reduxjs/toolkit'
import { PhoneVerificationsState } from './types'
import { createSlice } from 'store/toolkit'
import { useInjectReducer, useInjectSaga } from 'store/redux-injectors'
import { phoneVerificationsSaga } from './saga'
import { localPageSize, localSortField, localSortType } from './constants'

export const initialState: PhoneVerificationsState = {
  phoneVerificationsList: [],
  isLoadingPhoneVerifications: false,
  filterPhoneVerifications: {
    page: 0,
    pageSize: localPageSize ? localPageSize : 100,
    sortField: localSortField,
    sortType: localSortType,
  },
  lastPageOfPhoneVerificationsTable: 0,
  isAllPhoneVerificationsFetched: false,
  displayFilterPhoneVerificationsModal: false,
}

const phoneVerificationsSlice = createSlice({
  name: 'phoneVerifications',
  initialState,
  reducers: {
    getPhoneVerifications(state) {},
    setPhoneVerificationsList(
      state,
      action: PayloadAction<PhoneVerificationsState['phoneVerificationsList']>,
    ) {
      state.phoneVerificationsList = action.payload
    },
    setIsLoadingPhoneVerifications(
      state,
      action: PayloadAction<
        PhoneVerificationsState['isLoadingPhoneVerifications']
      >,
    ) {
      state.isLoadingPhoneVerifications = action.payload
    },
    setFilterPhoneVerifications(
      state,
      action: PayloadAction<
        PhoneVerificationsState['filterPhoneVerifications']
      >,
    ) {
      state.filterPhoneVerifications = action.payload
    },
    setLastPageOfPhoneVerificationsTable(state, action: PayloadAction<number>) {
      state.lastPageOfPhoneVerificationsTable = action.payload
    },
    setIsAllPhoneVerificationsFetched(state, action: PayloadAction<boolean>) {
      state.isAllPhoneVerificationsFetched = action.payload
    },
    setDisplayFilterPhoneVerificationsModal(
      state,
      action: PayloadAction<boolean>,
    ) {
      state.displayFilterPhoneVerificationsModal = action.payload
    },
  },
})

export const {
  actions: phoneVerificationsActions,
  reducer: phoneVerificationsReducer,
  name: sliceKey,
} = phoneVerificationsSlice

export const usePhoneVerificationsSlice = () => {
  useInjectReducer({ key: sliceKey, reducer: phoneVerificationsReducer })
  useInjectSaga({ key: sliceKey, saga: phoneVerificationsSaga })
  return { phoneVerificationsActions: phoneVerificationsActions }
}
