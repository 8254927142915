import { PayloadAction } from '@reduxjs/toolkit'
import { DocumentInvalidationsState } from './types'
import { createSlice } from 'store/toolkit'
import { useInjectReducer, useInjectSaga } from 'store/redux-injectors'

import { documentInvalidationsSaga } from './saga'
import { localPageSize, localSortField, localSortType } from './constants'

export const initialState: DocumentInvalidationsState = {
  documentInvalidationsList: [],
  isLoadingDocumentInvalidations: false,
  filterDocumentInvalidations: {
    page: 0,
    pageSize: localPageSize ? localPageSize : 100,
    sortField: localSortField,
    sortType: localSortType,
  },
  lastPageOfDocumentInvalidationsTable: 0,
  isAllDocumentInvalidationsFetched: false,
  displayFilterDocumentInvalidationsModal: false,
}

const documentInvalidationsSlice = createSlice({
  name: 'documentInvalidations',
  initialState,
  reducers: {
    getDocumentInvalidations(state) {},
    setDocumentInvalidations(
      state,
      action: PayloadAction<
        DocumentInvalidationsState['documentInvalidationsList']
      >,
    ) {
      state.documentInvalidationsList = action.payload
    },
    setIsLoadingDocumentInvalidations(
      state,
      action: PayloadAction<
        DocumentInvalidationsState['isLoadingDocumentInvalidations']
      >,
    ) {
      state.isLoadingDocumentInvalidations = action.payload
    },
    setFilterDocumentInvalidationsData(
      state,
      action: PayloadAction<
        DocumentInvalidationsState['filterDocumentInvalidations']
      >,
    ) {
      state.filterDocumentInvalidations = action.payload
    },
    setLastPageOfDocumentInvalidationsTable(
      state,
      action: PayloadAction<number>,
    ) {
      state.lastPageOfDocumentInvalidationsTable = action.payload
    },
    setIsAllDocumentInvalidationsFetched(
      state,
      action: PayloadAction<boolean>,
    ) {
      state.isAllDocumentInvalidationsFetched = action.payload
    },
    setDisplayFilterDocumentInvalidationsModal(
      state,
      action: PayloadAction<boolean>,
    ) {
      state.displayFilterDocumentInvalidationsModal = action.payload
    },
  },
})

export const {
  actions: documentInvalidationsActions,
  reducer: documentInvalidationsReducer,
  name: sliceKey,
} = documentInvalidationsSlice

export const useDocumentInvalidationsSlice = () => {
  useInjectReducer({ key: sliceKey, reducer: documentInvalidationsReducer })
  useInjectSaga({ key: sliceKey, saga: documentInvalidationsSaga })
  return { documentInvalidationsActions: documentInvalidationsActions }
}
