import { createSelector } from '@reduxjs/toolkit'

import { RootState } from 'store/types'
import { initialState } from './slice'

export const documentInvalidationsDomains = {
  root: (state: RootState) => state.documentInvalidations || initialState,
  documentInvalidationsList: (state: RootState) =>
    state.documentInvalidations?.documentInvalidationsList || [],
  isLoadingDocumentInvalidations: (state: RootState) =>
    state.documentInvalidations?.isLoadingDocumentInvalidations || false,
  filterDocumentInvalidations: (state: RootState) =>
    state.documentInvalidations?.filterDocumentInvalidations,
  lastPageOfDocumentInvalidationsTable: (state: RootState) =>
    state.documentInvalidations?.lastPageOfDocumentInvalidationsTable,
  isAllDocumentInvalidationsFetched: (state: RootState) =>
    state.documentInvalidations?.isAllDocumentInvalidationsFetched || false,
  displayFilterDocumentInvalidationsModal: (state: RootState) =>
    state.documentInvalidations?.displayFilterDocumentInvalidationsModal ||
    false,
}

export const documentInvalidationsSelectors = {
  root: createSelector(documentInvalidationsDomains.root, root => root),
  documentInvalidationsList: createSelector(
    documentInvalidationsDomains.documentInvalidationsList,
    documentInvalidations => documentInvalidations,
  ),
  isLoadingDocumentInvalidations: createSelector(
    documentInvalidationsDomains.isLoadingDocumentInvalidations,
    isLoading => isLoading,
  ),
  filterDocumentInvalidations: createSelector(
    documentInvalidationsDomains.filterDocumentInvalidations,
    filterData => filterData,
  ),
  lastPageOfDocumentInvalidationsTable: createSelector(
    documentInvalidationsDomains.lastPageOfDocumentInvalidationsTable,
    lastPageOfTable => lastPageOfTable,
  ),
  isAllDocumentInvalidationsFetched: createSelector(
    documentInvalidationsDomains.isAllDocumentInvalidationsFetched,
    isAllFetched => isAllFetched,
  ),
  displayFilterDocumentInvalidationsModal: createSelector(
    documentInvalidationsDomains.displayFilterDocumentInvalidationsModal,
    displayFilterModal => displayFilterModal,
  ),
}
