import axiosInstance from 'api/axios'
import { AxiosResponse } from 'axios'
import { TransmissionsEndpoints } from 'api/endpoints/connector'
import { IFilterTransmissions } from '../types'

export function* getTransmissionReq(filterData: IFilterTransmissions) {
  const response: AxiosResponse = yield axiosInstance.get(
    TransmissionsEndpoints.transmissionList(filterData),
  )
  return response
}
