import { call, put, select, takeLatest } from 'redux-saga/effects'
import { configurationsActions } from './slice'
import { getConfigurationsReq, setNewConfigurationReq } from './providers'
import { AxiosResponse } from 'axios'
import { customToast } from 'app/components/toast/CustomToast'
import { errorMessage } from 'utils/parser'
import { configurationsSelectors } from './selectors'
import { IFilterConfigurations, IConfiguration } from './types'

function* getConfigurations(): any {
  yield put(configurationsActions.setIsLoadingConfigurations(true))
  try {
    const filterConfigurations: IFilterConfigurations = yield select(
      configurationsSelectors.filterConfigurations,
    )

    const response: AxiosResponse = yield call(
      getConfigurationsReq,
      filterConfigurations,
    )

    const configurationsList = (response?.data?.data?.configurations ||
      []) as IConfiguration[]

    yield put(configurationsActions.setConfigurations(configurationsList))

    configurationsList.length === 0 ||
    configurationsList.length < filterConfigurations.pageSize
      ? yield put(configurationsActions.setIsAllConfigurationsFetched(true))
      : yield put(configurationsActions.setIsAllConfigurationsFetched(false))
  } catch (error) {
    customToast.error(errorMessage(error))
  } finally {
    yield put(configurationsActions.setIsLoadingConfigurations(false))
  }
}

function* setNewConfigurations(): any {
  try {
    const newConfiguration: IConfiguration = yield select(
      configurationsSelectors.newConfiguration,
    )

    //@ts-ignore
    yield call(setNewConfigurationReq, newConfiguration)
  } catch (error) {
    customToast.error(errorMessage(error))
  }
}

export function* configurationsSaga() {
  yield takeLatest(
    configurationsActions.getConfigurations.type,
    getConfigurations,
  )
  yield takeLatest(
    configurationsActions.setNewConfiguration.type,
    setNewConfigurations,
  )
}
