import { useEffect, useState } from 'react'
import { Box, styled } from '@mui/material'

import { useDispatch, useSelector } from 'react-redux'
import BaseDropdownInput from 'app/components/input/dropdownInput'

import BaseInput from 'app/components/input/baseInput'
import { MaskInput } from 'app/components/input/inputWithMask'
import { getDateFromTimestamp, getTimestampFromDate } from 'utils/date'
import { DeleteForeverIcon } from 'app/components/icon/deleteForeverIcon'
import { recordsSelectors } from 'app/containers/Records/selectors'
import { recordsActions } from 'app/containers/Records/slice'
import { FAILURE_TYPE_OPTIONS } from 'app/containers/Records/constants'
import { mediaQueries } from 'styles/mediaQueries'
import PrimaryButton from 'app/components/button/primaryButton'

export const FilterRecordsContent = () => {
  const dispatch = useDispatch()
  const filterRecords = useSelector(recordsSelectors.filterRecords)

  const [createAtFrom, setCreateAtFrom] = useState<string>(
    filterRecords?.createdAtFrom?.toString() ?? '',
  )
  const [createAtTo, setCreateAtTo] = useState<string>(
    filterRecords?.createdAtTo?.toString() ?? '',
  )
  const [updateAtFrom, setUpdateAtFrom] = useState<string>(
    filterRecords?.updateAtFrom?.toString() ?? '',
  )
  const [updateAtTo, setUpdateAtTo] = useState<string>(
    filterRecords?.updateAtTo?.toString() ?? '',
  )

  const handleInputChange = (event: any) => {
    dispatch(
      recordsActions.setFilterRecords({
        ...filterRecords,
        [event.target.name]: event.target.value,
      }),
    )
  }

  const handleDateInputChange = (event: any) => {
    const timeStamp = getTimestampFromDate(event.target.value)

    dispatch(
      recordsActions.setFilterRecords({
        ...filterRecords,
        [event.target.name]: timeStamp,
      }),
    )
  }

  useEffect(() => {
    setCreateAtFrom(getDateFromTimestamp(filterRecords?.createdAtFrom))
    setCreateAtTo(getDateFromTimestamp(filterRecords?.createdAtTo))
    setUpdateAtFrom(getDateFromTimestamp(filterRecords?.updateAtFrom))
    setUpdateAtTo(getDateFromTimestamp(filterRecords?.updateAtTo))
  }, [])

  const handleSearching = () => {
    dispatch(recordsActions.setDisplayFilterRecordsModal(false))
    dispatch(recordsActions.getRecords())
  }

  return (
    <Wrapper>
      <TwoItemsContainer>
        <ItemConatiner>
          <ItemLabel>Client DomainUUID:</ItemLabel>
          <BaseInput
            fullWidth
            type="text"
            id="clientDomainUUID"
            name="clientDomainUUID"
            label="Client DomainUUID"
            onChange={handleInputChange}
            value={filterRecords?.clientDomainUUID}
            InputProps={{
              endAdornment: filterRecords?.clientDomainUUID ? (
                <DeleteForeverIcon
                  onClick={() =>
                    dispatch(
                      recordsActions.setFilterRecords({
                        ...filterRecords,
                        clientDomainUUID: '',
                      }),
                    )
                  }
                />
              ) : undefined,
            }}
          />
        </ItemConatiner>
        <ItemConatiner>
          <ItemLabel>FailureType:</ItemLabel>
          <BaseDropdownInput
            label="FailureType"
            placeholder="FailureType"
            name="failureType"
            fullWidth
            options={FAILURE_TYPE_OPTIONS}
            onChange={handleInputChange}
            value={filterRecords?.failureType ?? ''}
            endAdornment={
              filterRecords?.failureType ? (
                <DeleteForeverIcon
                  sx={{
                    position: 'absolute',
                    right: '20px',
                  }}
                  onClick={() =>
                    dispatch(
                      recordsActions.setFilterRecords({
                        ...filterRecords,
                        failureType: '',
                      }),
                    )
                  }
                />
              ) : undefined
            }
          />
        </ItemConatiner>
      </TwoItemsContainer>
      <TwoItemsContainer>
        <ItemConatiner>
          <ItemLabel>OrderID:</ItemLabel>
          <BaseInput
            fullWidth
            type="text"
            id="orderID"
            name="orderID"
            label="OrderID"
            onChange={handleInputChange}
            value={filterRecords?.orderID}
            InputProps={{
              endAdornment: filterRecords?.orderID ? (
                <DeleteForeverIcon
                  onClick={() =>
                    dispatch(
                      recordsActions.setFilterRecords({
                        ...filterRecords,
                        orderID: 0,
                      }),
                    )
                  }
                />
              ) : undefined,
            }}
          />
        </ItemConatiner>
        <ItemConatiner>
          <ItemLabel>Txhash:</ItemLabel>
          <BaseInput
            fullWidth
            type="text"
            id="txhash"
            name="txhash"
            label="Txhash"
            onChange={handleInputChange}
            value={filterRecords?.txhash}
            InputProps={{
              endAdornment: filterRecords?.txhash ? (
                <DeleteForeverIcon
                  onClick={() =>
                    dispatch(
                      recordsActions.setFilterRecords({
                        ...filterRecords,
                        txhash: '',
                      }),
                    )
                  }
                />
              ) : undefined,
            }}
          />
        </ItemConatiner>
      </TwoItemsContainer>
      <TwoItemsContainer>
        <ItemConatiner>
          <ItemLabel>CreatedAt From:</ItemLabel>
          <MaskInput
            fullWidth
            variant="outlined"
            maskedValue={createAtFrom}
            setMaskedValue={setCreateAtFrom}
            type="text"
            id="createdAtFrom"
            name="createdAtFrom"
            label="CreatedAt From"
            placeholder="YYYY-MM-DD"
            onChange={handleDateInputChange}
            InputProps={{
              endAdornment: createAtFrom ? (
                <DeleteForeverIcon
                  onClick={() => {
                    dispatch(
                      recordsActions.setFilterRecords({
                        ...filterRecords,
                        createdAtFrom: undefined,
                      }),
                    )
                    setCreateAtFrom('')
                  }}
                />
              ) : undefined,
            }}
          />
        </ItemConatiner>
        <ItemConatiner>
          <ItemLabel>CreatedAt To:</ItemLabel>
          <MaskInput
            fullWidth
            variant="outlined"
            maskedValue={createAtTo}
            setMaskedValue={setCreateAtTo}
            type="text"
            id="createdAtTo"
            name="createdAtTo"
            label="CreatedAt To"
            placeholder="YYYY-MM-DD"
            onChange={handleDateInputChange}
            InputProps={{
              endAdornment: createAtTo ? (
                <DeleteForeverIcon
                  onClick={() => {
                    dispatch(
                      recordsActions.setFilterRecords({
                        ...filterRecords,
                        createdAtTo: undefined,
                      }),
                    )
                    setCreateAtTo('')
                  }}
                />
              ) : undefined,
            }}
          />
        </ItemConatiner>
      </TwoItemsContainer>
      <TwoItemsContainer>
        <ItemConatiner>
          <ItemLabel>UpdateAt From:</ItemLabel>
          <MaskInput
            fullWidth
            variant="outlined"
            maskedValue={updateAtFrom}
            setMaskedValue={setUpdateAtFrom}
            type="text"
            id="updateAtFrom"
            name="updateAtFrom"
            label="UpdateAt From"
            placeholder="YYYY-MM-DD"
            onChange={handleDateInputChange}
            InputProps={{
              endAdornment: updateAtFrom ? (
                <DeleteForeverIcon
                  onClick={() => {
                    dispatch(
                      recordsActions.setFilterRecords({
                        ...filterRecords,
                        updateAtFrom: undefined,
                      }),
                    )
                    setUpdateAtFrom('')
                  }}
                />
              ) : undefined,
            }}
          />
        </ItemConatiner>
        <ItemConatiner>
          <ItemLabel>UpdateAt To:</ItemLabel>
          <MaskInput
            fullWidth
            variant="outlined"
            maskedValue={updateAtTo}
            setMaskedValue={setUpdateAtTo}
            type="text"
            id="updateAtTo"
            name="updateAtTo"
            label="UpdateAt To"
            placeholder="YYYY-MM-DD"
            onChange={handleDateInputChange}
            InputProps={{
              endAdornment: updateAtTo ? (
                <DeleteForeverIcon
                  onClick={() => {
                    dispatch(
                      recordsActions.setFilterRecords({
                        ...filterRecords,
                        updateAtTo: undefined,
                      }),
                    )
                    setUpdateAtTo('')
                  }}
                />
              ) : undefined,
            }}
          />
        </ItemConatiner>
      </TwoItemsContainer>
      <CTAContainer>
        <PrimaryButtons onClick={handleSearching}>Search</PrimaryButtons>
      </CTAContainer>
    </Wrapper>
  )
}

const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 32px;
  width: 100%;
  ${mediaQueries.lessThan('sm')`
  gap: 23px;`}
  ${mediaQueries.lessThan('xs')`
  gap: 16px;`}
`

const TwoItemsContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 32px;
  width: 100%;
  ${mediaQueries.lessThan('xs')`
  flex-direction: column;
  gap: 16px;
  `}
`

const ItemConatiner = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  width: 100%;
  min-width: 452px;
  ${mediaQueries.lessThan('lg')`
  min-width: 417px;
  `}
  ${mediaQueries.lessThan('md')`
  min-width: 239px;
  `}
  ${mediaQueries.lessThan('sm')`
  min-width: 241px;
  `}
`

const ItemLabel = styled(Box)`
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.palette.text.primary};
  word-wrap: normal;
  min-width: 120px;
  font-weight: 700;
`
const CTAContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 32px;
  margin-top: 28px;
  width: 100%;
  ${mediaQueries.lessThan('sm')`
  margin-top: 16px;`}
  ${mediaQueries.lessThan('xs')`
  margin-top: 8px;`}
`

const PrimaryButtons = styled(PrimaryButton)`
  width: 200px;
  ${mediaQueries.lessThan('sm')`
  width: 111px;
`}
  ${mediaQueries.lessThan('xs')`
  width: 100%;`}
`
