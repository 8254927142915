import { call, put, select, takeLatest } from 'redux-saga/effects'
import {
  addRoleReq,
  addRouteToRoleReq,
  deleteRouteFromRoleReq,
  fetchRolesReq,
  fetchRoutesReq,
} from './providers'
import { rolesActions } from './slice'
import { AxiosResponse } from 'axios'
import { rolesDomains, rolesSelectors } from './selectors'
import { RolesState } from './types'
import { customToast } from 'app/components/toast/CustomToast'
import { errorMessage, successMessage } from 'utils/parser'

function* fetchRoles(action: ReturnType<typeof rolesActions.fetchRoles>) {
  if (!action.payload.silent) {
    yield put(rolesActions.setIsLoadingRoles(true))
  }
  try {
    const response: AxiosResponse = yield call(fetchRolesReq)
    yield put(rolesActions.setRolesList(response?.data?.data))
  } catch (error) {
    customToast.error(errorMessage(error))
    console.log('Error fetching roles', error)
  } finally {
    if (!action.payload.silent) {
      yield put(rolesActions.setIsLoadingRoles(false))
    }
  }
}

function* deleteRouteFromRole() {
  const removeRouteFromRole: RolesState['deleteConfirmationModal'] =
    yield select(rolesDomains.deleteConfirmationModal)
  try {
    const response: AxiosResponse = yield call(
      deleteRouteFromRoleReq,
      removeRouteFromRole,
    )
    customToast.success(successMessage(response))
    yield put(rolesActions.fetchRoles({ silent: true }))
  } catch (error) {
    customToast.error(errorMessage(error))
    console.log('Error deleting route from role', error)
  }
}

function* addRouteToRole(
  action: ReturnType<typeof rolesActions.addRouteToRole>,
) {
  yield put(rolesActions.setIsRouteAdding(action.payload?.route.path))
  try {
    const response: AxiosResponse = yield call(addRouteToRoleReq, action)
    customToast.success(successMessage(response))
    yield put(rolesActions.fetchRoles({ silent: true }))
  } catch (error) {
    console.log('Error adding route to role', error)
    customToast.error(errorMessage(error))
  } finally {
    yield put(rolesActions.setIsRouteAdding(null))
  }
}

function* fetchRoutes() {
  try {
    const response: AxiosResponse = yield call(fetchRoutesReq)
    yield put(rolesActions.setRoutesList(response?.data?.data?.routes))
  } catch (error) {
    customToast.error(errorMessage(error))
    console.log('Error fetching routes', error)
  }
}

function* addRole(action: ReturnType<typeof rolesActions.addRole>) {
  try {
    const response: AxiosResponse = yield call(addRoleReq, action)
    customToast.success(successMessage(response))
    yield put(rolesActions.fetchRoles({ silent: true }))
  } catch (error) {
    console.log('Error adding role', error)
    customToast.error(errorMessage(error))
  }
}

export function* rolesSaga() {
  yield takeLatest(rolesActions.fetchRoles.type, fetchRoles),
    yield takeLatest(
      rolesActions.deleteRouteFromRole.type,
      deleteRouteFromRole,
    ),
    yield takeLatest(rolesActions.fetchRoutes.type, fetchRoutes)
  yield takeLatest(rolesActions.addRouteToRole.type, addRouteToRole)
  yield takeLatest(rolesActions.addRole.type, addRole)
}
