import { EmailRejectionsHeader } from './components/Header'
import { FilterEmailRejectionsModal } from './components/modals/filter'
import { EmailRejectionsTable } from './components/table'

export function EmailRejections() {
  return (
    <>
      <EmailRejectionsHeader />
      <EmailRejectionsTable />
      <FilterEmailRejectionsModal />
    </>
  )
}
