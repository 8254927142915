export const FIELD_ORDER = [
  'firstName',
  'firstNameEn',
  'lastName',
  'lastNameEn',
  'dob',
  'placeOfBirth',
  'nationality',
  'number',
  'issuedDate',
  'validUntil',
  'country',
  'address',
]

export const englishCharactersRegex =
  /^[a-zA-Z0-9`.,\-_^&$#@!~"' :;\\|\/?<>()+=%*\[\]{}]+$/
