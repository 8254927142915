import { TableOfData } from './components/table'
import { Header } from './components/Header'
import { FilterPhoneVerificationsModal } from './components/modals/filter'

export function PhoneVerifications() {
  return (
    <>
      <Header />
      <TableOfData />
      <FilterPhoneVerificationsModal />
    </>
  )
}
