import { call, put, select, takeLatest } from 'redux-saga/effects'
import { phoneRejectionsActions } from './slice'
import { getPhoneRejectionsReq } from './providers'
import { AxiosResponse } from 'axios'
import { customToast } from 'app/components/toast/CustomToast'
import { errorMessage } from 'utils/parser'
import { phoneRejectionsSelectors } from './selectors'
import { IFilterPhoneRejections, PhoneRejection } from './types'

function* getPhoneRejections(): any {
  yield put(phoneRejectionsActions.setIsLoadingPhoneRejections(true))
  try {
    const filterPhoneRejections: IFilterPhoneRejections = yield select(
      phoneRejectionsSelectors.filterPhoneRejections,
    )
    const response: AxiosResponse = yield call(
      getPhoneRejectionsReq,
      filterPhoneRejections,
    )

    const phoneRejectionList = (response?.data?.data?.rejections ||
      []) as PhoneRejection[]

    yield put(phoneRejectionsActions.setPhoneRejections(phoneRejectionList))

    phoneRejectionList.length === 0 ||
    phoneRejectionList.length < filterPhoneRejections.pageSize
      ? yield put(phoneRejectionsActions.setIsAllPhoneRejectionsFetched(true))
      : yield put(phoneRejectionsActions.setIsAllPhoneRejectionsFetched(false))
  } catch (error) {
    customToast.error(errorMessage(error))
  } finally {
    yield put(phoneRejectionsActions.setIsLoadingPhoneRejections(false))
  }
}

export function* phoneRejectionsSaga() {
  yield takeLatest(
    phoneRejectionsActions.getPhoneRejections.type,
    getPhoneRejections,
  )
}
