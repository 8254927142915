import axiosInstance from 'api/axios'
import { AxiosResponse } from 'axios'
import { IFilterPhoneInvalidations } from '../types'
import { PhoneInvalidationsEndpoints } from 'api/endpoints/verifications'

export function* getPhoneInvalidationsReq(
  filterData: IFilterPhoneInvalidations,
) {
  const response: AxiosResponse = yield axiosInstance.get(
    PhoneInvalidationsEndpoints.phoneInvalidationsList(filterData),
  )
  return response
}
