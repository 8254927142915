import { createSelector } from '@reduxjs/toolkit'

import { RootState } from 'store/types'
import { initialState } from './slice'

export const phoneRejectionsDomains = {
  root: (state: RootState) => state.phoneRejections || initialState,
  phoneRejectionList: (state: RootState) =>
    state.phoneRejections.phoneRejectionList,
  isLoadingPhoneRejections: (state: RootState) =>
    state.phoneRejections.isLoadingPhoneRejections,
  filterPhoneRejections: (state: RootState) =>
    state.phoneRejections.filterPhoneRejections,
  lastPageOfPhoneRejectionsTable: (state: RootState) =>
    state.phoneRejections.lastPageOfPhoneRejectionsTable,
  isAllPhoneRejectionsFetched: (state: RootState) =>
    state.phoneRejections.isAllPhoneRejectionsFetched,
  displayFilterPhoneRejectionsModal: (state: RootState) =>
    state.phoneRejections.displayFilterPhoneRejectionsModal,
}

export const phoneRejectionsSelectors = {
  root: createSelector(phoneRejectionsDomains.root, root => root),
  phoneRejectionList: createSelector(
    phoneRejectionsDomains.phoneRejectionList,
    phoneRejections => phoneRejections,
  ),
  isLoadingPhoneRejections: createSelector(
    phoneRejectionsDomains.isLoadingPhoneRejections,
    isLoading => isLoading,
  ),
  filterPhoneRejections: createSelector(
    phoneRejectionsDomains.filterPhoneRejections,
    filterData => filterData,
  ),
  lastPageOfPhoneRejectionsTable: createSelector(
    phoneRejectionsDomains.lastPageOfPhoneRejectionsTable,
    lastPageOfTable => lastPageOfTable,
  ),
  isAllPhoneRejectionsFetched: createSelector(
    phoneRejectionsDomains.isAllPhoneRejectionsFetched,
    isAllFetched => isAllFetched,
  ),
  displayFilterPhoneRejectionsModal: createSelector(
    phoneRejectionsDomains.displayFilterPhoneRejectionsModal,
    displayFilterModal => displayFilterModal,
  ),
}
