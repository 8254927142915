import { createSelector } from '@reduxjs/toolkit'

import { RootState } from 'store/types'
import { initialState } from './slice'

export const assetsManagementDomains = {
  root: (state: RootState) => state.assetsManagement || initialState,
  assetsManagementList: (state: RootState) =>
    state.assetsManagement.assetsManagementList,
  isLoadingAssetsManagement: (state: RootState) =>
    state.assetsManagement.isLoadingAssetsManagement,
  filterAssetsManagement: (state: RootState) =>
    state.assetsManagement.filterAssetsManagement,
  lastPageOfAssetsManagementTable: (state: RootState) =>
    state.assetsManagement.lastPageOfAssetsManagementTable,
  isAllAssetsManagementFetched: (state: RootState) =>
    state.assetsManagement.isAllAssetsManagementFetched,
  displayFilterAssetsManagementModal: (state: RootState) =>
    state.assetsManagement.displayFilterAssetsManagementModal,
  displayDetailAssetsManagementModal: (state: RootState) =>
    state.assetsManagement.displayDetailAssetsManagementModal,
}

export const assetsManagementSelectors = {
  root: createSelector(assetsManagementDomains.root, root => root),
  assetsManagementList: createSelector(
    assetsManagementDomains.assetsManagementList,
    assetsManagement => assetsManagement,
  ),
  isLoadingAssetsManagement: createSelector(
    assetsManagementDomains.isLoadingAssetsManagement,
    isLoading => isLoading,
  ),
  filterAssetsManagement: createSelector(
    assetsManagementDomains.filterAssetsManagement,
    filter => filter,
  ),
  lastPageOfAssetsManagementTable: createSelector(
    assetsManagementDomains.lastPageOfAssetsManagementTable,
    lastPageOfTable => lastPageOfTable,
  ),
  isAllAssetsManagementFetched: createSelector(
    assetsManagementDomains.isAllAssetsManagementFetched,
    isAllFetched => isAllFetched,
  ),
  displayFilterAssetsManagementModal: createSelector(
    assetsManagementDomains.displayFilterAssetsManagementModal,
    displayFilterModal => displayFilterModal,
  ),
  displayDetailAssetsManagementModal: createSelector(
    assetsManagementDomains.displayDetailAssetsManagementModal,
    displayDetailModal => displayDetailModal,
  ),
}
