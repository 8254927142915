export const errorJsonPareser = (error: any) => {
  let errorJson = {}
  try {
    errorJson = JSON.parse(error)
  } catch (e) {
    errorJson = { message: 'Error while parsing error' }
  }
  return errorJson
}

export const successMessage = (response: any) => {
  return response?.data?.message || 'Process succeded'
}

export const errorMessage = (error: any) => {
  return (
    error?.error?.response?.data?.error?.message ||
    error?.response?.data?.error?.message ||
    error?.message ||
    'Process Failed'
  )
}

export const isNonEmptyTruthy = (input: any): boolean => {
  // Check for falsy values
  if (!input) {
    return false
  }
  // Check for an empty object
  if (
    typeof input === 'object' &&
    !Array.isArray(input) &&
    Object.keys(input).length === 0
  ) {
    return false
  }
  // Check for an empty array

  if (Array.isArray(input) && input.length === 0) {
    return false
  }
  // If none of the above, return true
  return true
}
