import { styled } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import { useDispatch, useSelector } from 'react-redux'

import { oauthsSelectors } from '../selectors'
import { oauthsActions } from '../slice'
import {
  FilterChipsInHeader,
  IShowablefilterData,
} from 'app/components/common/FilterChipsInHeader'
import { PageHeader } from 'app/components/common/globals/pageHeader'
import PrimaryButton from 'app/components/button/primaryButton'

export const Header = () => {
  const dispatch = useDispatch()

  const filterOauths = useSelector(oauthsSelectors.filterOauths)
  const displayFilterOauthsModal = useSelector(
    oauthsSelectors.displayFilterOauthsModal,
  )

  const handleOpenFilterModal = () => {
    dispatch(oauthsActions.setDisplayFilterOauthsModal(true))
  }

  const showablefilterOauths: IShowablefilterData[] = [
    {
      label: 'Client DomainUUID',
      value: filterOauths.clientDomainUUID,
    },
    { label: 'Client DomainID', value: filterOauths.clientDomainID },
    { label: 'Name', value: filterOauths.name },
    {
      label: 'CreatedAt From',
      value: filterOauths.createdAtFrom,
      isDate: true,
    },
    { label: 'CreatedAt To', value: filterOauths.createdAtTo, isDate: true },
    {
      label: 'UpdateAt From',
      value: filterOauths.updateAtFrom,
      isDate: true,
    },
    { label: 'UpdateAt To', value: filterOauths.updateAtTo, isDate: true },
  ]

  const handleDelete = (label: string) => {
    dispatch(
      oauthsActions.setFilterOauths({
        ...filterOauths,
        [label]: undefined,
      }),
    )
    dispatch(oauthsActions.getOauthsList())
  }

  return (
    <>
      <PageHeader title="Oauths">
        <PrimaryButton
          onClick={handleOpenFilterModal}
          endIcon={<StyledSearchIcon />}
        >
          Search
        </PrimaryButton>
      </PageHeader>
      <FilterSection>
        {!displayFilterOauthsModal && (
          <FilterChipsInHeader
            showableFilter={showablefilterOauths}
            deleteFilter={handleDelete}
          />
        )}
      </FilterSection>
    </>
  )
}

const FilterSection = styled('div')`
  display: flex;
  gap: 8px;
  width: 100%;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
`
const StyledSearchIcon = styled(SearchIcon)`
  color: var(--white);
`
