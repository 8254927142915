import { createSelector } from '@reduxjs/toolkit'

import { RootState } from 'store/types'
import { initialState } from './slice'

export const domainsDomains = {
  root: (state: RootState) => state.domains || initialState,
  domainsList: (state: RootState) => state.domains.domainsList,
  isLoadingDomains: (state: RootState) => state.domains.isLoadingDomains,
  filterDomains: (state: RootState) => state.domains.filterDomains,
  lastPageOfDomainsTable: (state: RootState) =>
    state.domains.lastPageOfDomainsTable,
  isAllDomainsFetched: (state: RootState) => state.domains.isAllDomainsFetched,
  displayFilterDomainsModal: (state: RootState) =>
    state.domains.displayFilterDomainsModal,
}

export const domainsSelectors = {
  root: createSelector(domainsDomains.root, root => root),
  domainsList: createSelector(domainsDomains.domainsList, domains => domains),
  isLoadingDomains: createSelector(
    domainsDomains.isLoadingDomains,
    isLoading => isLoading,
  ),
  filterDomains: createSelector(
    domainsDomains.filterDomains,
    filterData => filterData,
  ),
  lastPageOfDomainsTable: createSelector(
    domainsDomains.lastPageOfDomainsTable,
    lastPageOfTable => lastPageOfTable,
  ),
  isAllDomainsFetched: createSelector(
    domainsDomains.isAllDomainsFetched,
    isAllFetched => isAllFetched,
  ),
  displayFilterDomainsModal: createSelector(
    domainsDomains.displayFilterDomainsModal,
    displayFilterModal => displayFilterModal,
  ),
}
