import { PayloadAction } from '@reduxjs/toolkit'
import { BotOrdersState } from './types'
import { createSlice } from 'store/toolkit'
import { useInjectReducer, useInjectSaga } from 'store/redux-injectors'

import { botOrdersSaga } from './saga'
import { localPageSize, localSortField, localSortType } from './constants'

export const initialState: BotOrdersState = {
  botOrderList: [],
  isLoadingBotOrders: false,
  filterBotOrders: {
    page: 0,
    pageSize: localPageSize ? localPageSize : 100,
    sortField: localSortField,
    sortType: localSortType,
  },
  lastPageOfBotOrdersTable: 0,
  isAllBotOrdersFetched: false,
  displayFilterBotOrdersModal: false,
}

const botOrdersSlice = createSlice({
  name: 'botOrders',
  initialState,
  reducers: {
    getBotOrders(state) {},
    setBotOrders(state, action: PayloadAction<BotOrdersState['botOrderList']>) {
      state.botOrderList = action.payload
    },
    setIsLoadingBotOrders(
      state,
      action: PayloadAction<BotOrdersState['isLoadingBotOrders']>,
    ) {
      state.isLoadingBotOrders = action.payload
    },
    setFilterBotOrders(
      state,
      action: PayloadAction<BotOrdersState['filterBotOrders']>,
    ) {
      state.filterBotOrders = action.payload
    },
    setLastPageOfBotOrdersTable(state, action: PayloadAction<number>) {
      state.lastPageOfBotOrdersTable = action.payload
    },
    setIsAllBotOrdersFetched(state, action: PayloadAction<boolean>) {
      state.isAllBotOrdersFetched = action.payload
    },
    setDisplayFilterBotOrdersModal(state, action: PayloadAction<boolean>) {
      state.displayFilterBotOrdersModal = action.payload
    },
  },
})

export const {
  actions: botOrdersActions,
  reducer: botOrdersReducer,
  name: sliceKey,
} = botOrdersSlice

export const useBotOrdersSlice = () => {
  useInjectReducer({ key: sliceKey, reducer: botOrdersReducer })
  useInjectSaga({ key: sliceKey, saga: botOrdersSaga })
  return { botOrdersActions: botOrdersActions }
}
