import { PayloadAction } from '@reduxjs/toolkit'
import { EmailRejectionsState } from './types'
import { createSlice } from 'store/toolkit'
import { useInjectReducer, useInjectSaga } from 'store/redux-injectors'

import { emailRejectionsSaga } from './saga'
import { localPageSize, localSortField, localSortType } from './constants'

export const initialState: EmailRejectionsState = {
  emailRejectionsList: [],
  isLoadingEmailRejections: false,
  filterEmailRejections: {
    page: 0,
    pageSize: localPageSize ? localPageSize : 100,
    sortField: localSortField,
    sortType: localSortType,
  },
  lastPageOfEmailRejectionsTable: 0,
  isAllEmailRejectionsFetched: false,
  displayFilterEmailRejectionsModal: false,
}

const emailRejectionsSlice = createSlice({
  name: 'emailRejections',
  initialState,
  reducers: {
    getEmailRejections(state) {},
    setEmailRejections(
      state,
      action: PayloadAction<EmailRejectionsState['emailRejectionsList']>,
    ) {
      state.emailRejectionsList = action.payload
    },
    setIsLoadingEmailRejections(
      state,
      action: PayloadAction<EmailRejectionsState['isLoadingEmailRejections']>,
    ) {
      state.isLoadingEmailRejections = action.payload
    },
    setFilterEmailRejections(
      state,
      action: PayloadAction<EmailRejectionsState['filterEmailRejections']>,
    ) {
      state.filterEmailRejections = action.payload
    },
    setLastPageOfEmailRejectionsTable(state, action: PayloadAction<number>) {
      state.lastPageOfEmailRejectionsTable = action.payload
    },
    setIsAllEmailRejectionsFetched(state, action: PayloadAction<boolean>) {
      state.isAllEmailRejectionsFetched = action.payload
    },
    setDisplayFilterEmailRejectionsModal(
      state,
      action: PayloadAction<boolean>,
    ) {
      state.displayFilterEmailRejectionsModal = action.payload
    },
  },
})

export const {
  actions: emailRejectionsActions,
  reducer: emailRejectionsReducer,
  name: sliceKey,
} = emailRejectionsSlice

export const useEmailRejectionsSlice = () => {
  useInjectReducer({ key: sliceKey, reducer: emailRejectionsReducer })
  useInjectSaga({ key: sliceKey, saga: emailRejectionsSaga })
  return { emailRejectionsActions: emailRejectionsActions }
}
