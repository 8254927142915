import { useDispatch, useSelector } from 'react-redux'
import { submissionsSelectors } from '../../../selectors'
import { submissionsActions } from '../../../slice'
import { ConfirmDeleteModalContent } from './content'
import { Modal } from 'app/components/modal'

export const ConfirmDeleteModal = () => {
  const dispatch = useDispatch()
  const confirmDeleteModalItem = useSelector(
    submissionsSelectors.confirmDeleteModalItem,
  )

  const handleModalClose = () =>
    dispatch(submissionsActions.setConfirmDeleteModalItem(undefined))

  return (
    <Modal open={!!confirmDeleteModalItem} onClose={handleModalClose}>
      <ConfirmDeleteModalContent />
    </Modal>
  )
}
