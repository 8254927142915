import axiosInstance from 'api/axios'
import { AxiosResponse } from 'axios'
import { IFilterDocumentInvalidations } from '../types'
import { DocumentInvalidationsEndpoints } from 'api/endpoints/verifications'

export function* getDocumentInvalidationsReq(
  filterData: IFilterDocumentInvalidations,
) {
  const response: AxiosResponse = yield axiosInstance.get(
    DocumentInvalidationsEndpoints.documentInvalidationsList(filterData),
  )
  return response
}
