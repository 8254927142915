import axiosInstance from 'api/axios'
import { AxiosResponse } from 'axios'
import { IFilterPayments } from '../types'
import { PaymentsEndpoints } from 'api/endpoints/shop'

export function* getPaymentReq(filterData: IFilterPayments) {
  const response: AxiosResponse = yield axiosInstance.get(
    PaymentsEndpoints.paymentsList(filterData),
  )
  return response
}
