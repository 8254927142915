import { useEffect, useState } from 'react'
import { Box, styled } from '@mui/material'

import { useDispatch, useSelector } from 'react-redux'
import BaseDropdownInput from 'app/components/input/dropdownInput'

import BaseInput from 'app/components/input/baseInput'
import { MaskInput } from 'app/components/input/inputWithMask'
import { getDateFromTimestamp, getTimestampFromDate } from 'utils/date'
import { DeleteForeverIcon } from 'app/components/icon/deleteForeverIcon'
import { transmissionsSelectors } from 'app/containers/Transmissions/selectors'
import { transmissionsActions } from 'app/containers/Transmissions/slice'
import {
  PLAN_OPTIONS,
  STATUS_OPTIONS,
} from 'app/containers/Transmissions/constants'
import { mediaQueries } from 'styles/mediaQueries'
import PrimaryButton from 'app/components/button/primaryButton'

export const FilterTransmissionsContent = () => {
  const dispatch = useDispatch()
  const filterTransmissions = useSelector(
    transmissionsSelectors.filterTransmissions,
  )

  const [createAtFrom, setCreateAtFrom] = useState<string>(
    filterTransmissions?.createdAtFrom?.toString() ?? '',
  )
  const [createAtTo, setCreateAtTo] = useState<string>(
    filterTransmissions?.createdAtTo?.toString() ?? '',
  )

  const handleInputChange = (event: any) => {
    dispatch(
      transmissionsActions.setFilterTransmissions({
        ...filterTransmissions,
        [event.target.name]: event.target.value,
      }),
    )
  }

  const handleDateInputChange = (event: any) => {
    const timeStamp = getTimestampFromDate(event.target.value)

    dispatch(
      transmissionsActions.setFilterTransmissions({
        ...filterTransmissions,
        [event.target.name]: timeStamp,
      }),
    )
  }

  useEffect(() => {
    setCreateAtFrom(getDateFromTimestamp(filterTransmissions?.createdAtFrom))
    setCreateAtTo(getDateFromTimestamp(filterTransmissions?.createdAtTo))
  }, [])

  const handleSearching = () => {
    dispatch(transmissionsActions.setDisplayFilterTransmissionsModal(false))
    dispatch(transmissionsActions.getTransmissionsList())
  }

  return (
    <Wrapper>
      <TwoItemsContainer>
        <ItemConatiner>
          <ItemLabel>Plan:</ItemLabel>
          <BaseDropdownInput
            label="Plan"
            placeholder="Plan"
            name="plan"
            fullWidth
            options={PLAN_OPTIONS}
            onChange={handleInputChange}
            value={filterTransmissions?.plan ?? ''}
            endAdornment={
              filterTransmissions?.plan ? (
                <DeleteForeverIcon
                  sx={{
                    position: 'absolute',
                    right: '20px',
                  }}
                  onClick={() =>
                    dispatch(
                      transmissionsActions.setFilterTransmissions({
                        ...filterTransmissions,
                        plan: '',
                      }),
                    )
                  }
                />
              ) : undefined
            }
          />
        </ItemConatiner>
        <ItemConatiner>
          <ItemLabel>Order Status:</ItemLabel>
          <BaseDropdownInput
            label="Order Status"
            placeholder="Order Status"
            name="orderStatus"
            fullWidth
            options={STATUS_OPTIONS}
            onChange={handleInputChange}
            value={filterTransmissions?.orderStatus ?? ''}
            endAdornment={
              filterTransmissions?.orderStatus ? (
                <DeleteForeverIcon
                  sx={{
                    position: 'absolute',
                    right: '20px',
                  }}
                  onClick={() =>
                    dispatch(
                      transmissionsActions.setFilterTransmissions({
                        ...filterTransmissions,
                        orderStatus: '',
                      }),
                    )
                  }
                />
              ) : undefined
            }
          />
        </ItemConatiner>
      </TwoItemsContainer>
      <TwoItemsContainer>
        <ItemConatiner>
          <ItemLabel>Reference Key:</ItemLabel>
          <BaseInput
            fullWidth
            type="text"
            id="referenceKey"
            name="referenceKey"
            label="Reference Key"
            onChange={handleInputChange}
            value={filterTransmissions?.referenceKey}
            InputProps={{
              endAdornment: filterTransmissions?.referenceKey ? (
                <DeleteForeverIcon
                  onClick={() =>
                    dispatch(
                      transmissionsActions.setFilterTransmissions({
                        ...filterTransmissions,
                        referenceKey: '',
                      }),
                    )
                  }
                />
              ) : undefined,
            }}
          />
        </ItemConatiner>
        <ItemConatiner>
          <ItemLabel>User CoreID:</ItemLabel>
          <BaseInput
            fullWidth
            type="text"
            id="userCoreID"
            name="userCoreID"
            label="User CoreID"
            onChange={handleInputChange}
            value={filterTransmissions?.userCoreID}
            InputProps={{
              endAdornment: filterTransmissions?.userCoreID ? (
                <DeleteForeverIcon
                  onClick={() =>
                    dispatch(
                      transmissionsActions.setFilterTransmissions({
                        ...filterTransmissions,
                        userCoreID: '',
                      }),
                    )
                  }
                />
              ) : undefined,
            }}
          />
        </ItemConatiner>
      </TwoItemsContainer>
      <TwoItemsContainer>
        <ItemConatiner>
          <ItemLabel>Transmission ID:</ItemLabel>
          <BaseInput
            fullWidth
            type="text"
            id="transmissionID"
            name="transmissionID"
            label="Transmission ID"
            onChange={handleInputChange}
            value={filterTransmissions?.transmissionID}
            InputProps={{
              endAdornment: filterTransmissions?.transmissionID ? (
                <DeleteForeverIcon
                  onClick={() =>
                    dispatch(
                      transmissionsActions.setFilterTransmissions({
                        ...filterTransmissions,
                        transmissionID: '',
                      }),
                    )
                  }
                />
              ) : undefined,
            }}
          />
        </ItemConatiner>
        <ItemConatiner>
          <ItemLabel>Client DomainUUID:</ItemLabel>
          <BaseInput
            fullWidth
            type="text"
            id="clientDomainUUID"
            name="clientDomainUUID"
            label="Client DomainUUID"
            onChange={handleInputChange}
            value={filterTransmissions?.clientDomainUUID}
            InputProps={{
              endAdornment: filterTransmissions?.clientDomainUUID ? (
                <DeleteForeverIcon
                  onClick={() =>
                    dispatch(
                      transmissionsActions.setFilterTransmissions({
                        ...filterTransmissions,
                        clientDomainUUID: '',
                      }),
                    )
                  }
                />
              ) : undefined,
            }}
          />
        </ItemConatiner>
      </TwoItemsContainer>
      <TwoItemsContainer>
        <ItemConatiner>
          <ItemLabel>Source Key:</ItemLabel>
          <BaseInput
            fullWidth
            type="text"
            id="sourceKey"
            name="sourceKey"
            label="Source Key"
            onChange={handleInputChange}
            value={filterTransmissions?.sourceKey}
            InputProps={{
              endAdornment: filterTransmissions?.sourceKey ? (
                <DeleteForeverIcon
                  onClick={() =>
                    dispatch(
                      transmissionsActions.setFilterTransmissions({
                        ...filterTransmissions,
                        sourceKey: '',
                      }),
                    )
                  }
                />
              ) : undefined,
            }}
          />
        </ItemConatiner>
        <ItemConatiner>
          <ItemLabel>Price:</ItemLabel>
          <BaseInput
            fullWidth
            type="text"
            id="price"
            name="price"
            label="Price"
            onChange={handleInputChange}
            value={filterTransmissions?.price}
            InputProps={{
              endAdornment: filterTransmissions?.price ? (
                <DeleteForeverIcon
                  onClick={() =>
                    dispatch(
                      transmissionsActions.setFilterTransmissions({
                        ...filterTransmissions,
                        price: 0,
                      }),
                    )
                  }
                />
              ) : undefined,
            }}
          />
        </ItemConatiner>
      </TwoItemsContainer>
      <TwoItemsContainer>
        <ItemConatiner>
          <ItemLabel>CreatedAt From:</ItemLabel>
          <MaskInput
            fullWidth
            variant="outlined"
            maskedValue={createAtFrom}
            setMaskedValue={setCreateAtFrom}
            type="text"
            id="createdAtFrom"
            name="createdAtFrom"
            label="CreatedAt From"
            placeholder="YYYY-MM-DD"
            onChange={handleDateInputChange}
            InputProps={{
              endAdornment: createAtFrom ? (
                <DeleteForeverIcon
                  onClick={() => {
                    dispatch(
                      transmissionsActions.setFilterTransmissions({
                        ...filterTransmissions,
                        createdAtFrom: undefined,
                      }),
                    )
                    setCreateAtFrom('')
                  }}
                />
              ) : undefined,
            }}
          />
        </ItemConatiner>
        <ItemConatiner>
          <ItemLabel>CreatedAt To:</ItemLabel>
          <MaskInput
            fullWidth
            variant="outlined"
            maskedValue={createAtTo}
            setMaskedValue={setCreateAtTo}
            type="text"
            id="createdAtTo"
            name="createdAtTo"
            label="CreatedAt To"
            placeholder="YYYY-MM-DD"
            onChange={handleDateInputChange}
            InputProps={{
              endAdornment: createAtTo ? (
                <DeleteForeverIcon
                  onClick={() => {
                    dispatch(
                      transmissionsActions.setFilterTransmissions({
                        ...filterTransmissions,
                        createdAtTo: undefined,
                      }),
                    )
                    setCreateAtTo('')
                  }}
                />
              ) : undefined,
            }}
          />
        </ItemConatiner>
      </TwoItemsContainer>
      <CTAContainer>
        <PrimaryButtons onClick={handleSearching}>Search</PrimaryButtons>
      </CTAContainer>
    </Wrapper>
  )
}

const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 32px;
  width: 100%;
  ${mediaQueries.lessThan('sm')`
  gap: 23px;`}
  ${mediaQueries.lessThan('xs')`
  gap: 16px;`}
`

const TwoItemsContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 32px;
  width: 100%;
  ${mediaQueries.lessThan('xs')`
  flex-direction: column;
  gap: 16px;
  `}
`

const ItemConatiner = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  width: 100%;
  min-width: 452px;
  ${mediaQueries.lessThan('lg')`
  min-width: 417px;
  `}
  ${mediaQueries.lessThan('md')`
  min-width: 239px;
  `}
  ${mediaQueries.lessThan('sm')`
  min-width: 241px;
  `}
`

const ItemLabel = styled(Box)`
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.palette.text.primary};
  word-wrap: normal;
  min-width: 120px;
  font-weight: 700;
`
const CTAContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 32px;
  margin-top: 28px;
  width: 100%;
  ${mediaQueries.lessThan('sm')`
  margin-top: 16px;`}
  ${mediaQueries.lessThan('xs')`
  margin-top: 8px;`}
`

const PrimaryButtons = styled(PrimaryButton)`
  width: 200px;
  ${mediaQueries.lessThan('sm')`
  width: 111px;
`}
  ${mediaQueries.lessThan('xs')`
  width: 100%;`}
`
