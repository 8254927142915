import { FC } from 'react'
import { Box, styled } from '@mui/material'
import { ROW_CENTER } from 'styles/globalStyles'
import { useDispatch, useSelector } from 'react-redux'
import { resubmissionItemActions } from '../../../slice'
import { resubmissionItemSelectors } from '../../../selectors'
import { IKYCProviderAddress, IKYCProviderData } from '../../../types'
import {
  doesAddressExist,
  isIKYCProviderAddress,
} from '../../../providers/utils'
import { ThreeDotLoadingIndicator } from 'app/components/common/loading/ThreeDotLoadingIndicator/threeDotLoading'
import { Row } from './components/row'
import { useSearchParams } from 'react-router-dom'
import { mediaQueries } from 'styles/mediaQueries'
import PrimaryButton from 'app/components/button/primaryButton'

interface IConfirmationContentProps {}

export const ConfirmationContent: FC<IConfirmationContentProps> = () => {
  const dispatch = useDispatch()
  const [searchParams] = useSearchParams()

  const verificationId = searchParams.get('verificationId')

  const isSubmitLoading = useSelector(resubmissionItemSelectors.isSubmitLoading)
  const isLoading = useSelector(resubmissionItemSelectors.isLoading)
  const kycData = useSelector(resubmissionItemSelectors.kycData)
  const resubmissionItem = useSelector(
    resubmissionItemSelectors.resubmissionItem,
  )
  // TODO: IMAN, this is the exact copy of the other component, please refactor this

  const initialValues: IKYCProviderData = doesAddressExist(
    resubmissionItem.kycProviderData,
  )
    ? {
        firstName: '',
        firstNameEn: '',
        middleName: '',
        middleNameEn: '',
        lastName: '',
        lastNameEn: '',
        dob: '',
        placeOfBirth: '',
        nationality: '',
        number: '',
        issuedDate: '',
        validUntil: '',
        address: {
          street: '',
          streetEn: '',
          subStreet: '',
          subStreetEn: '',
          state: '',
          stateEn: '',
          town: '',
          townEn: '',
          postCode: '',
        },
      }
    : {
        firstName: '',
        middleName: '',
        middleNameEn: '',
        gender: '',
        firstNameEn: '',
        lastName: '',
        lastNameEn: '',
        dob: '',
        placeOfBirth: '',
        nationality: '',
        number: '',
        issuedDate: '',
        validUntil: '',
      }

  const handleSubmit = () => {
    dispatch(
      resubmissionItemActions.sendResubmissionConfirmation({
        verificationId: Number(verificationId),
        providerSuggestion: JSON.stringify(kycData),
        applicantId: resubmissionItem.applicantId,
      }),
    )
    dispatch(resubmissionItemActions.setDisplayConfirmationModal(false))
  }

  return (
    <Wrapper>
      {isLoading ? (
        <ThreeDotLoadingIndicator />
      ) : (
        <div>
          <HeaderContainer>
            <HeaderTitle>Field</HeaderTitle>
            <HeaderTitle>SumSub Extracted</HeaderTitle>

            <HeaderTitle>User Suggestion</HeaderTitle>
            <HeaderTitle>You Are Sending</HeaderTitle>
          </HeaderContainer>
          {Object.keys(initialValues).map(key => {
            const safeKey = key as keyof IKYCProviderData

            if (
              typeof initialValues[safeKey] === 'object' &&
              initialValues[safeKey] !== null &&
              isIKYCProviderAddress(initialValues[safeKey])
            ) {
              return Object.keys(initialValues[safeKey] as Object).map(
                nestedKey => {
                  const nestedSafeKey = nestedKey as keyof IKYCProviderAddress

                  return (
                    <Row
                      key={`${key}.${nestedSafeKey}`}
                      name={`${key}.${nestedSafeKey}`}
                      value={
                        kycData[safeKey]
                          ? (kycData[safeKey] as IKYCProviderAddress)[
                              nestedSafeKey
                            ]
                          : ''
                      }
                      userValue={
                        resubmissionItem.userSuggestion[safeKey]
                          ? (
                              resubmissionItem.userSuggestion[
                                safeKey
                              ] as IKYCProviderAddress
                            )[nestedSafeKey]
                          : ''
                      }
                      providerValue={
                        resubmissionItem.kycProviderData[safeKey]
                          ? (
                              resubmissionItem.kycProviderData[
                                safeKey
                              ] as IKYCProviderAddress
                            )[nestedSafeKey]
                          : ''
                      }
                    />
                  )
                },
              )
            }

            return (
              <Row
                key={key}
                name={key}
                value={kycData[safeKey]?.toString() || ''}
                userValue={
                  resubmissionItem.userSuggestion[safeKey]?.toString() || ''
                }
                providerValue={
                  resubmissionItem.kycProviderData[safeKey]?.toString() || ''
                }
              />
            )
          })}
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              gap: '20px',
              marginTop: '20px',
            }}
          >
            <PrimaryButton
              type="submit"
              style={{ margin: '16px 0' }}
              isLoading={isSubmitLoading}
              onClick={handleSubmit}
            >
              {isSubmitLoading ? 'Submitting...' : 'Submit'}
            </PrimaryButton>
          </div>
        </div>
      )}
    </Wrapper>
  )
}

export const Wrapper = styled('div')``

export const Title = styled('div')`
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: -0.02em;
  color: var(--black-knight);
`

export const ButtonContainer = styled('div')`
  ${ROW_CENTER}
  width: 100%;
  button {
    width: 333px;
  }
`

export const HeaderContainer = styled(Box)`
  display: grid;
  grid-template-columns: 2fr 2fr 2fr 2fr;
  padding: 10px 0;
  border-bottom: 1px solid #e0e0e0;
`

export const HeaderTitle = styled('h4')`
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: var(--black-knight);
  display: flex;
  align-items: center;
  justify-content: center;
  &:first-of-type {
    justify-self: flex-start !important;
  }
  ${mediaQueries.lessThan('md')`
  font-size: 14px;
  margin:10px 0;
  `}
  ${mediaQueries.lessThan('sm')`
  font-size: 12px;
  margin:5px 0;
  `}
`
