/* eslint-disable react-hooks/rules-of-hooks */
import { PayloadAction } from '@reduxjs/toolkit'
import { IIssueVoucher, FilterVouchers, VouchersState } from './types'
import { createSlice } from 'store/toolkit'
import { useInjectReducer, useInjectSaga } from 'store/redux-injectors'

import { vouchersSaga } from './saga'
import { localPageSize, localSortField, localSortType } from './constants'

export const initialState: VouchersState = {
  vouchersList: [],
  isLoadingVouchers: false,
  displayVoucherModal: false,
  filterVouchers: {
    page: 0,
    pageSize: localPageSize ? localPageSize : 100,
    sortType: localSortType,
    sortField: localSortField,
  },
  lastPageOfVouchersTable: 0,
  isAllVouchersFetched: false,
  displayFilterVouchersModal: false,
}

const vouchersSlice = createSlice({
  name: 'vouchers',
  initialState,
  reducers: {
    getVouchersList(state) {},
    setVoucherslist(
      state,
      action: PayloadAction<VouchersState['vouchersList']>,
    ) {
      state.vouchersList = action.payload
    },
    setIsLoadingVouchers(
      state,
      action: PayloadAction<VouchersState['isLoadingVouchers']>,
    ) {
      state.isLoadingVouchers = action.payload
    },
    setDisplayVoucherModal(
      state,
      action: PayloadAction<VouchersState['displayVoucherModal']>,
    ) {
      state.displayVoucherModal = action.payload
    },
    issueVoucher(state, action: PayloadAction<IIssueVoucher>) {},
    setFilterVouchers(state, action: PayloadAction<FilterVouchers>) {
      state.filterVouchers = action.payload
    },
    setLastPageOfVouchersTable(
      state,
      action: PayloadAction<VouchersState['lastPageOfVouchersTable']>,
    ) {
      state.lastPageOfVouchersTable = action.payload
    },
    setIsAllVouchersFetched(
      state,
      action: PayloadAction<VouchersState['isAllVouchersFetched']>,
    ) {
      state.isAllVouchersFetched = action.payload
    },
    setDisplayFilterVouchersModal(
      state,
      action: PayloadAction<VouchersState['displayFilterVouchersModal']>,
    ) {
      state.displayFilterVouchersModal = action.payload
    },
  },
})

export const {
  actions: vouchersActions,
  reducer: vouchersReducer,
  name: sliceKey,
} = vouchersSlice

export const useoverviewSlice = () => {
  useInjectReducer({ key: sliceKey, reducer: vouchersReducer })
  useInjectSaga({ key: sliceKey, saga: vouchersSaga })
  return { vouchersActions: vouchersActions }
}
