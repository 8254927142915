import { call, put, select, takeLatest } from 'redux-saga/effects'
import { clientSourceKeysActions } from './slice'
import { getClientSourceKeysReq } from './providers'
import { AxiosResponse } from 'axios'
import { customToast } from 'app/components/toast/CustomToast'
import { errorMessage } from 'utils/parser'
import { IFilterClientSourceKeys, IClientSourceKeys } from './types'
import { clientSourceKeysSelectors } from './selectors'

function* getClientSourceKeys(): any {
  yield put(clientSourceKeysActions.setIsLoadingClientSourceKeys(true))
  try {
    const filterData: IFilterClientSourceKeys = yield select(
      clientSourceKeysSelectors.filterClientSourceKeys,
    )
    const response: AxiosResponse = yield call(
      getClientSourceKeysReq,
      filterData,
    )

    const listOfNewData = (response?.data?.data?.clientSourceKeys ||
      []) as IClientSourceKeys[]

    yield put(clientSourceKeysActions.setClientSourceKeys(listOfNewData))

    listOfNewData.length === 0 || listOfNewData.length < filterData.pageSize
      ? yield put(clientSourceKeysActions.setIsAllClientSourceKeysFetched(true))
      : yield put(
          clientSourceKeysActions.setIsAllClientSourceKeysFetched(false),
        )
  } catch (error) {
    customToast.error(errorMessage(error))
  } finally {
    yield put(clientSourceKeysActions.setIsLoadingClientSourceKeys(false))
  }
}

export function* clientSourceKeysSaga() {
  yield takeLatest(
    clientSourceKeysActions.getClientSourceKeysList.type,
    getClientSourceKeys,
  )
}
