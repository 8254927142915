import { createSelector } from '@reduxjs/toolkit'

import { RootState } from 'store/types'
import { initialState } from './slice'

export const organizationsDomains = {
  root: (state: RootState) => state.organizations || initialState,
  organizationsList: (state: RootState) =>
    state.organizations.organizationsList,
  isLoadingOrganizations: (state: RootState) =>
    state.organizations.isLoadingOrganizations,
  filterOrganizations: (state: RootState) =>
    state.organizations.filterOrganizations,
  lastPageOfOrganizationsTable: (state: RootState) =>
    state.organizations.lastPageOfOrganizationsTable,
  isAllOrganizationsFetched: (state: RootState) =>
    state.organizations.isAllOrganizationsFetched,
  displayFilterOrganizationsModal: (state: RootState) =>
    state.organizations.displayFilterOrganizationsModal,
}

export const organizationsSelectors = {
  root: createSelector(organizationsDomains.root, root => root),
  organizationsList: createSelector(
    organizationsDomains.organizationsList,
    organizations => organizations,
  ),
  isLoadingOrganizations: createSelector(
    organizationsDomains.isLoadingOrganizations,
    isLoading => isLoading,
  ),
  filterOrganizations: createSelector(
    organizationsDomains.filterOrganizations,
    filterData => filterData,
  ),
  lastPageOfOrganizationsTable: createSelector(
    organizationsDomains.lastPageOfOrganizationsTable,
    lastPageOfTable => lastPageOfTable,
  ),
  isAllOrganizationsFetched: createSelector(
    organizationsDomains.isAllOrganizationsFetched,
    isAllFetched => isAllFetched,
  ),
  displayFilterOrganizationsModal: createSelector(
    organizationsDomains.displayFilterOrganizationsModal,
    displayFilterModal => displayFilterModal,
  ),
}
