import { SearchBar } from './components/searchBar'
import { TabContainer } from './components/tabContainer'

export function SearchUser() {
  return (
    <>
      <SearchBar />
      <TabContainer />
    </>
  )
}
