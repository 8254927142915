import { ColumnOfTable } from 'app/components/table/sortableTable'
import { getTableSettingsFromStorage } from 'utils/storage'

export const EMAIL_VERIFICATIONS_TABLE_KEY = 'emailVerificationsTable'

export const columnsForShowInEmailVerificationsTable: ColumnOfTable[] = [
  {
    id: 'coreID',
    label: 'CoreID',
  },
  {
    id: 'vouchererName',
    label: 'Voucherer Name',
  },
  {
    id: 'status',
    label: 'Status',
  },
  {
    id: 'mintType',
    label: 'Mint Type',
  },
  {
    id: 'fingerprints',
    label: 'Fingerprints',
  },
  {
    id: 'txHash',
    label: 'TxHash',
  },

  {
    id: 'bctxID',
    label: 'BCTX ID',
  },
  {
    id: 'verifyRetry',
    label: 'Verify Retry',
  },
  {
    id: 'createdAt',
    label: 'Created At',
    isDate: true,
  },

  {
    id: 'expiration',
    label: 'Expiration',
    isDate: true,
  },
]

export const {
  pageSize: localPageSize,
  sortType: localSortType,
  sortField: localSortField,
} = getTableSettingsFromStorage({ tableKey: EMAIL_VERIFICATIONS_TABLE_KEY })

export const EMAIL_VERIFICATIONS_STATUS_OPTIONS = [
  'NEWLY_ADDED',
  'IN_PROGRESS',
  'UNKNOWN_FAILED',
  'FAILED',
  'SUCCESSFUL',
]

export const EMAIL_VERIFICATIONS_MINT_TYPE_OPTIONS = ['REJECT', 'CONFIRM']
