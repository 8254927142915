import { submissionsActions } from '../slice'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { submissionsSelectors } from '../selectors'
import { styled } from '@mui/material'
import { TABLE_KEY, columnsForShowInTable } from '../constants'
import SortableTable from 'app/components/table/sortableTable'
import { searchUserSelectors } from 'app/containers/SearchUser/selector'
import { ActionComponent } from './actionComponent'
import { globalSelectors } from 'app/containers/Initialize/selector'
import { redirectToBlockBox } from 'utils/redirections'

export const TableOfData = () => {
  const dispatch = useDispatch()

  const isLoadingSubmissions = useSelector(
    submissionsSelectors.isLoadingSubmissions,
  )
  const searchUserCoreId = useSelector(searchUserSelectors.coreId)
  const submittedKycVaults = useSelector(submissionsSelectors.submitted)
  const isLoadingContractAddresses = useSelector(
    globalSelectors.isLoadingContractAddresses,
  )

  useEffect(() => {
    searchUserCoreId
      ? dispatch(submissionsActions.getSubmissionsByAddress(searchUserCoreId))
      : dispatch(submissionsActions.setSubmissions([]))
  }, [searchUserCoreId])

  const speceficRowStyleBasedOnCellValue = {
    cellKey: 'isInvalidated',
    style: {
      backgroundColor: 'var(--error)',
    },
    cellValue: true,
    actionComponent: ActionComponent,
  }

  return (
    <Wrapper>
      <SortableTable
        name={TABLE_KEY}
        isLoading={isLoadingSubmissions || isLoadingContractAddresses}
        data={submittedKycVaults}
        columns={columnsForShowInTable}
        showNumberOfPages={false}
        countOfData={submittedKycVaults?.length}
        speceficRowStyleBasedOnCellValue={speceficRowStyleBasedOnCellValue}
        onCellClick={(row, column) => {
          if (column === 'transactionHash') {
            redirectToBlockBox(row[column])
          }
        }}
      />
    </Wrapper>
  )
}

const Wrapper = styled('div')`
  justify-content: center;
  grid-gap: 10px 0;
  margin-top: 20px;
  margin-bottom: 20px;
  min-width: 0;
`
