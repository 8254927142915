import { DocumentInvalidationsHeader } from './components/Header'
import { FilterDocumentInvalidationsModal } from './components/modals/filter'
import { DocumentInvalidationsTable } from './components/table'

export function DocumentInvalidations() {
  return (
    <>
      <DocumentInvalidationsHeader />
      <DocumentInvalidationsTable />
      <FilterDocumentInvalidationsModal />
    </>
  )
}
