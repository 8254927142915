import { call, put, select, takeLatest } from 'redux-saga/effects'
import { resubmissionsActions } from './slice'
import { getResubmissionUsersWithFilter } from './providers'
import { IServerResponse } from 'app/types'
import { customToast } from 'app/components/toast/CustomToast'
import { IResubmissionItem } from './types'
import { allResubmissionsSelectors } from './selectors'

function* getResubmissionsList(): any {
  yield put(resubmissionsActions.setIsLoadingResubmissions(true))
  try {
    const filterData = yield select(
      allResubmissionsSelectors.filterResubmissions,
    )

    const response = (yield call(
      getResubmissionUsersWithFilter,
      filterData,
    )) as IServerResponse

    const resubmissionList = response.data.verifications as IResubmissionItem[]

    yield put(resubmissionsActions.setResubmissions(resubmissionList))

    resubmissionList.length === 0 ||
    resubmissionList.length < filterData.pageSize
      ? yield put(resubmissionsActions.setIsAllResubmissionsFetched(true))
      : yield put(resubmissionsActions.setIsAllResubmissionsFetched(false))
  } catch (error) {
    console.log('Error fetching resubmissions', error)

    yield call(customToast.error, 'Error fetching resubmissions')
  } finally {
    yield put(resubmissionsActions.setIsLoadingResubmissions(false))
  }
}

export function* resubmissionSaga() {
  yield takeLatest(
    resubmissionsActions.getResubmissionsList.type,
    getResubmissionsList,
  )
}
