import { styled } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import { useDispatch, useSelector } from 'react-redux'
import { domainsSelectors } from '../selectors'
import { domainsActions } from '../slice'
import {
  FilterChipsInHeader,
  IShowablefilterData,
} from 'app/components/common/FilterChipsInHeader'
import { PageHeader } from 'app/components/common/globals/pageHeader'
import PrimaryButton from 'app/components/button/primaryButton'

export const Header = () => {
  const dispatch = useDispatch()

  const filterDomains = useSelector(domainsSelectors.filterDomains)
  const displayFilterDomainsModal = useSelector(
    domainsSelectors.displayFilterDomainsModal,
  )

  const handleOpenFilterModal = () => {
    dispatch(domainsActions.setDisplayFilterDomainsModal(true))
  }

  const showablefilterDomains: IShowablefilterData[] = [
    { label: 'Domain', value: filterDomains.domain },
    { label: 'OrganizationID', value: filterDomains.organizationID },
    { label: 'OrganizationUUID', value: filterDomains.organizationUUID },
    { label: 'Name', value: filterDomains.name },
    { label: 'Framework', value: filterDomains.framework },
    { label: 'Platform', value: filterDomains.platform },
    { label: 'Status', value: filterDomains.status },
    {
      label: 'CreatedAt From',
      value: filterDomains.createdAtFrom,
      isDate: true,
    },
    { label: 'CreatedAt To', value: filterDomains.createdAtTo, isDate: true },
    {
      label: 'UpdateAt From',
      value: filterDomains.updateAtFrom,
      isDate: true,
    },
    { label: 'UpdateAt To', value: filterDomains.updateAtTo, isDate: true },
  ]

  const handleDelete = (label: string) => {
    dispatch(
      domainsActions.setFilterDomains({
        ...filterDomains,
        [label]: undefined,
      }),
    )
    dispatch(domainsActions.getDomainsList())
  }

  return (
    <>
      <PageHeader title="Domains">
        <PrimaryButton
          onClick={handleOpenFilterModal}
          endIcon={<StyledSearchIcon />}
        >
          Search
        </PrimaryButton>
      </PageHeader>
      <FilterSection>
        {!displayFilterDomainsModal && (
          <FilterChipsInHeader
            showableFilter={showablefilterDomains}
            deleteFilter={handleDelete}
          />
        )}
      </FilterSection>
    </>
  )
}

const FilterSection = styled('div')`
  display: flex;
  gap: 8px;
  width: 100%;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
`
const StyledSearchIcon = styled(SearchIcon)`
  color: var(--white);
`
