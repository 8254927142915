import axiosInstance from 'api/axios'
import { baseURLPrefix } from 'app/constants'
import { AxiosResponse } from 'axios'
import { mainPageActions } from '../slice'

export function* getMetricsDataReq(
  action: ReturnType<typeof mainPageActions.getMetricsData>,
) {
  const queryParams = new URLSearchParams(action.payload).toString()

  const response: AxiosResponse = yield axiosInstance.get(
    `${baseURLPrefix}dashboard/metrics?${queryParams}`,
  )
  return response
}
