import { createSelector } from '@reduxjs/toolkit'

import { RootState } from 'store/types'
import { initialState } from './slice'

export const searchUserDomains = {
  root: (state: RootState) => state.searchUser || initialState,
  coreId: (state: RootState) => state.searchUser.coreId,
}

export const searchUserSelectors = {
  root: createSelector(searchUserDomains.root, root => root),
  coreId: createSelector(
    searchUserDomains.coreId,
    searchUserList => searchUserList,
  ),
}
