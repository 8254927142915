import { call, put, select, takeLatest } from 'redux-saga/effects'
import { organizationsActions } from './slice'
import { getOrganizationsReq } from './providers'
import { AxiosResponse } from 'axios'
import { customToast } from 'app/components/toast/CustomToast'
import { errorMessage } from 'utils/parser'
import { organizationsSelectors } from './selectors'
import { IFilterOrganizations, IOrganization } from './types'

function* getOrganizations(): any {
  yield put(organizationsActions.setIsLoadingOrganizations(true))
  try {
    const filterData: IFilterOrganizations = yield select(
      organizationsSelectors.filterOrganizations,
    )
    const response: AxiosResponse = yield call(getOrganizationsReq, filterData)

    const listOfNewData = (response?.data?.data?.organizations ||
      []) as IOrganization[]

    yield put(organizationsActions.setOrganizations(listOfNewData))

    listOfNewData.length === 0 || listOfNewData.length < filterData.pageSize
      ? yield put(organizationsActions.setIsAllOrganizationsFetched(true))
      : yield put(organizationsActions.setIsAllOrganizationsFetched(false))
  } catch (error) {
    customToast.error(errorMessage(error))
  } finally {
    yield put(organizationsActions.setIsLoadingOrganizations(false))
  }
}

export function* organizationsSaga() {
  yield takeLatest(
    organizationsActions.getOrganizationsList.type,
    getOrganizations,
  )
}
