import axiosInstance from 'api/axios'
import { AxiosResponse } from 'axios'
import { RecordsEndpoints } from 'api/endpoints/connector'
import { IFilterRecords } from '../types'

export function* getRecordsReq(filterData: IFilterRecords) {
  const response: AxiosResponse = yield axiosInstance.get(
    RecordsEndpoints.recordsList(filterData),
  )
  return response
}
