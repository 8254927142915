import { createSelector } from '@reduxjs/toolkit'

import { RootState } from 'store/types'
import { initialState } from './slice'

export const phoneVerificationsDomains = {
  root: (state: RootState) => state.phoneVerifications || initialState,
  phoneVerificationsList: (state: RootState) =>
    state.phoneVerifications.phoneVerificationsList,
  isLoadingPhoneVerifications: (state: RootState) =>
    state.phoneVerifications.isLoadingPhoneVerifications,
  filterPhoneVerifications: (state: RootState) =>
    state.phoneVerifications.filterPhoneVerifications,
  lastPageOfPhoneVerificationsTable: (state: RootState) =>
    state.phoneVerifications.lastPageOfPhoneVerificationsTable,
  isAllPhoneVerificationsFetched: (state: RootState) =>
    state.phoneVerifications.isAllPhoneVerificationsFetched,
  displayFilterPhoneVerificationsModal: (state: RootState) =>
    state.phoneVerifications.displayFilterPhoneVerificationsModal,
}

export const phoneVerificationsSelectors = {
  root: createSelector(phoneVerificationsDomains.root, root => root),
  phoneVerificationsList: createSelector(
    phoneVerificationsDomains.phoneVerificationsList,
    phoneVerificationsList => phoneVerificationsList,
  ),
  isLoadingPhoneVerifications: createSelector(
    phoneVerificationsDomains.isLoadingPhoneVerifications,
    isLoading => isLoading,
  ),
  filterPhoneVerifications: createSelector(
    phoneVerificationsDomains.filterPhoneVerifications,
    filterData => filterData,
  ),
  lastPageOfPhoneVerificationsTable: createSelector(
    phoneVerificationsDomains.lastPageOfPhoneVerificationsTable,
    lastPageOfTable => lastPageOfTable,
  ),
  isAllPhoneVerificationsFetched: createSelector(
    phoneVerificationsDomains.isAllPhoneVerificationsFetched,
    isAllFetched => isAllFetched,
  ),
  displayFilterPhoneVerificationsModal: createSelector(
    phoneVerificationsDomains.displayFilterPhoneVerificationsModal,
    displayFilterModal => displayFilterModal,
  ),
}
