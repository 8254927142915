import { useState } from 'react'
import { customToast } from 'app/components/toast/CustomToast'
import { copyTextToClipboard } from 'utils/string'

export const useCopy = (toastText = 'Copied to clipboard') => {
  const [isCopied, setIsCopied] = useState<'false' | 'true'>('false')

  const handleCopy = (copyText: string) => {
    setIsCopied('true')
    customToast.success(toastText, { autoClose: 2000 })

    copyTextToClipboard(copyText)

    setTimeout(() => {
      setIsCopied('false')
    }, 3000)
  }

  return {
    isCopied,
    handleCopy,
  }
}
