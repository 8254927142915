import DeleteIcon from '@mui/icons-material/Delete'
import { IconButton } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { nftsSelectors } from '../selectors'
import { nftsActions } from '../slice'

interface ActionProps {
  rowId: string
  actionHasBeenDone?: boolean
}
export const ActionComponent = ({ rowId, actionHasBeenDone }: ActionProps) => {
  const dispatch = useDispatch()
  const selectedNft = useSelector(nftsSelectors.getNftById(rowId))

  const handleDeleteClick = () => {
    dispatch(nftsActions.setConfirmRejectModalItem(selectedNft))
  }

  return (
    <IconButton onClick={handleDeleteClick}>
      {!actionHasBeenDone ? <DeleteIcon /> : <></>}
    </IconButton>
  )
}
