import { useEffect, useState } from 'react'
import { Box, styled } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { MaskInput } from 'app/components/input/inputWithMask'
import { getDateFromTimestamp, getTimestampFromDate } from 'utils/date'
import { DeleteForeverIcon } from 'app/components/icon/deleteForeverIcon'
import { mediaQueries } from 'styles/mediaQueries'
import { oauthsSelectors } from 'app/containers/Oauths/selectors'
import { oauthsActions } from 'app/containers/Oauths/slice'
import BaseInput from 'app/components/input/baseInput'
import PrimaryButton from 'app/components/button/primaryButton'

export const FilterOauthsContent = () => {
  const dispatch = useDispatch()
  const filterOauths = useSelector(oauthsSelectors.filterOauths)

  const [createAtFrom, setCreateAtFrom] = useState<string>(
    filterOauths?.createdAtFrom?.toString() ?? '',
  )
  const [createAtTo, setCreateAtTo] = useState<string>(
    filterOauths?.createdAtTo?.toString() ?? '',
  )
  const [updateAtFrom, setUpdateAtFrom] = useState<string>(
    filterOauths?.updateAtFrom?.toString() ?? '',
  )
  const [updateAtTo, setUpdateAtTo] = useState<string>(
    filterOauths?.updateAtTo?.toString() ?? '',
  )

  const handleInputChange = (event: any) => {
    dispatch(
      oauthsActions.setFilterOauths({
        ...filterOauths,
        [event.target.name]: event.target.value,
      }),
    )
  }

  const handleDateInputChange = (event: any) => {
    const timeStamp = getTimestampFromDate(event.target.value)

    dispatch(
      oauthsActions.setFilterOauths({
        ...filterOauths,
        [event.target.name]: timeStamp,
      }),
    )
  }

  useEffect(() => {
    setCreateAtFrom(getDateFromTimestamp(filterOauths?.createdAtFrom))
    setCreateAtTo(getDateFromTimestamp(filterOauths?.createdAtTo))
    setUpdateAtFrom(getDateFromTimestamp(filterOauths?.updateAtFrom))
    setUpdateAtTo(getDateFromTimestamp(filterOauths?.updateAtTo))
  }, [])

  const handleSearching = () => {
    dispatch(oauthsActions.setDisplayFilterOauthsModal(false))
    dispatch(oauthsActions.getOauthsList())
  }

  return (
    <Wrapper>
      <TwoItemsContainer>
        <ItemConatiner>
          <ItemLabel>Client DomainUUID:</ItemLabel>
          <BaseInput
            fullWidth
            type="text"
            id="clientDomainUUID"
            name="clientDomainUUID"
            label="Client DomainUUID"
            onChange={handleInputChange}
            value={filterOauths?.clientDomainUUID}
            InputProps={{
              endAdornment: filterOauths?.clientDomainUUID ? (
                <DeleteForeverIcon
                  onClick={() =>
                    dispatch(
                      oauthsActions.setFilterOauths({
                        ...filterOauths,
                        clientDomainUUID: '',
                      }),
                    )
                  }
                />
              ) : undefined,
            }}
          />
        </ItemConatiner>
        <ItemConatiner>
          <ItemLabel>client DomainID:</ItemLabel>
          <BaseInput
            fullWidth
            type="text"
            id="clientDomainID"
            name="clientDomainID"
            label="Client DomainID"
            onChange={handleInputChange}
            value={filterOauths?.clientDomainID}
            InputProps={{
              endAdornment: filterOauths?.clientDomainID ? (
                <DeleteForeverIcon
                  onClick={() =>
                    dispatch(
                      oauthsActions.setFilterOauths({
                        ...filterOauths,
                        clientDomainID: undefined,
                      }),
                    )
                  }
                />
              ) : undefined,
            }}
          />
        </ItemConatiner>
      </TwoItemsContainer>
      <TwoItemsContainer>
        <ItemConatiner>
          <ItemLabel>name:</ItemLabel>
          <BaseInput
            fullWidth
            type="text"
            id="name"
            name="name"
            label="Name"
            onChange={handleInputChange}
            value={filterOauths?.name}
            InputProps={{
              endAdornment: filterOauths?.name ? (
                <DeleteForeverIcon
                  onClick={() =>
                    dispatch(
                      oauthsActions.setFilterOauths({
                        ...filterOauths,
                        name: '',
                      }),
                    )
                  }
                />
              ) : undefined,
            }}
          />
        </ItemConatiner>
        <ItemConatiner></ItemConatiner>
      </TwoItemsContainer>
      <TwoItemsContainer>
        <ItemConatiner>
          <ItemLabel>CreatedAt From:</ItemLabel>
          <MaskInput
            fullWidth
            variant="outlined"
            maskedValue={createAtFrom}
            setMaskedValue={setCreateAtFrom}
            type="text"
            id="createdAtFrom"
            name="createdAtFrom"
            label="CreatedAt From"
            placeholder="YYYY-MM-DD"
            onChange={handleDateInputChange}
            InputProps={{
              endAdornment: createAtFrom ? (
                <DeleteForeverIcon
                  onClick={() => {
                    dispatch(
                      oauthsActions.setFilterOauths({
                        ...filterOauths,
                        createdAtFrom: undefined,
                      }),
                    )
                    setCreateAtFrom('')
                  }}
                />
              ) : undefined,
            }}
          />
        </ItemConatiner>
        <ItemConatiner>
          <ItemLabel>CreatedAt To:</ItemLabel>
          <MaskInput
            fullWidth
            variant="outlined"
            maskedValue={createAtTo}
            setMaskedValue={setCreateAtTo}
            type="text"
            id="createdAtTo"
            name="createdAtTo"
            label="CreatedAt To"
            placeholder="YYYY-MM-DD"
            onChange={handleDateInputChange}
            InputProps={{
              endAdornment: createAtTo ? (
                <DeleteForeverIcon
                  onClick={() => {
                    dispatch(
                      oauthsActions.setFilterOauths({
                        ...filterOauths,
                        createdAtTo: undefined,
                      }),
                    )
                    setCreateAtTo('')
                  }}
                />
              ) : undefined,
            }}
          />
        </ItemConatiner>
      </TwoItemsContainer>
      <TwoItemsContainer>
        <ItemConatiner>
          <ItemLabel>UpdateAt From:</ItemLabel>
          <MaskInput
            fullWidth
            variant="outlined"
            maskedValue={updateAtFrom}
            setMaskedValue={setUpdateAtFrom}
            type="text"
            id="updateAtFrom"
            name="updateAtFrom"
            label="UpdateAt From"
            placeholder="YYYY-MM-DD"
            onChange={handleDateInputChange}
            InputProps={{
              endAdornment: updateAtFrom ? (
                <DeleteForeverIcon
                  onClick={() => {
                    dispatch(
                      oauthsActions.setFilterOauths({
                        ...filterOauths,
                        updateAtFrom: undefined,
                      }),
                    )
                    setUpdateAtFrom('')
                  }}
                />
              ) : undefined,
            }}
          />
        </ItemConatiner>
        <ItemConatiner>
          <ItemLabel>UpdateAt To:</ItemLabel>
          <MaskInput
            fullWidth
            variant="outlined"
            maskedValue={updateAtTo}
            setMaskedValue={setUpdateAtTo}
            type="text"
            id="updateAtTo"
            name="updateAtTo"
            label="UpdateAt To"
            placeholder="YYYY-MM-DD"
            onChange={handleDateInputChange}
            InputProps={{
              endAdornment: updateAtTo ? (
                <DeleteForeverIcon
                  onClick={() => {
                    dispatch(
                      oauthsActions.setFilterOauths({
                        ...filterOauths,
                        updateAtTo: undefined,
                      }),
                    )
                    setUpdateAtTo('')
                  }}
                />
              ) : undefined,
            }}
          />
        </ItemConatiner>
      </TwoItemsContainer>
      <CTAContainer>
        <PrimaryButtons onClick={handleSearching}>Search</PrimaryButtons>
      </CTAContainer>
    </Wrapper>
  )
}

const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 32px;
  width: 100%;
  ${mediaQueries.lessThan('sm')`
  gap: 23px;`}
  ${mediaQueries.lessThan('xs')`
  gap: 16px;`}
`

const TwoItemsContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 32px;
  width: 100%;
  ${mediaQueries.lessThan('xs')`
  flex-direction: column;
  gap: 16px;
  `}
`

const ItemConatiner = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  width: 100%;
`

const ItemLabel = styled(Box)`
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.palette.text.primary};
  word-wrap: normal;
  min-width: 120px;
  font-weight: 700;
`
const CTAContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 32px;
  margin-top: 28px;
  width: 100%;
  ${mediaQueries.lessThan('sm')`
  margin-top: 16px;`}
  ${mediaQueries.lessThan('xs')`
  margin-top: 8px;`}
`
const PrimaryButtons = styled(PrimaryButton)`
  width: 200px;
  ${mediaQueries.lessThan('sm')`
  width: 111px;
`}
  ${mediaQueries.lessThan('xs')`
  width: 100%;`}
`
