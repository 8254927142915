import { PayloadAction } from '@reduxjs/toolkit'
import { ApiKeysState } from './types'
import { createSlice } from 'store/toolkit'
import { useInjectReducer, useInjectSaga } from 'store/redux-injectors'

import { apiKeysSaga } from './saga'
import { localPageSize, localSortField, localSortType } from './constants'

export const initialState: ApiKeysState = {
  apiKeysList: [],
  isLoadingApiKeys: false,
  filterApiKeys: {
    page: 0,
    pageSize: localPageSize ? localPageSize : 100,
    sortField: localSortField,
    sortType: localSortType,
  },
  lastPageOfApiKeysTable: 0,
  isAllApiKeysFetched: false,
  displayFilterApiKeysModal: false,
}

const apiKeysSlice = createSlice({
  name: 'apiKeys',
  initialState,
  reducers: {
    getApiKeysList(state) {},
    setApiKeys(state, action: PayloadAction<ApiKeysState['apiKeysList']>) {
      state.apiKeysList = action.payload
    },
    setIsLoadingApiKeys(
      state,
      action: PayloadAction<ApiKeysState['isLoadingApiKeys']>,
    ) {
      state.isLoadingApiKeys = action.payload
    },
    setFilterApiKeys(
      state,
      action: PayloadAction<ApiKeysState['filterApiKeys']>,
    ) {
      state.filterApiKeys = action.payload
    },
    setLastPageOfApiKeysTable(state, action: PayloadAction<number>) {
      state.lastPageOfApiKeysTable = action.payload
    },
    setIsAllApiKeysFetched(state, action: PayloadAction<boolean>) {
      state.isAllApiKeysFetched = action.payload
    },
    setDisplayFilterApiKeysModal(state, action: PayloadAction<boolean>) {
      state.displayFilterApiKeysModal = action.payload
    },
  },
})

export const {
  actions: apiKeysActions,
  reducer: apiKeysReducer,
  name: sliceKey,
} = apiKeysSlice

export const useApiKeysSlice = () => {
  useInjectReducer({ key: sliceKey, reducer: apiKeysReducer })
  useInjectSaga({ key: sliceKey, saga: apiKeysSaga })
  return { apiKeysActions: apiKeysActions }
}
