import { baseURLPrefix } from 'app/constants'

export const AdminsEndpoints = {
  adminsList: `${baseURLPrefix}auth/role/get-all-admins-with-roles`,
  deleteRoleFromAdmin: `${baseURLPrefix}auth/admin/delete-roles-from-admin`,
  addRoleToAdmin: `${baseURLPrefix}auth/admin/add-roles-to-admin`,
  blockAdmin: `${baseURLPrefix}auth/admin/block`,
  unblockAdmin: `${baseURLPrefix}auth/admin/unblock`,
  addAdmin: `${baseURLPrefix}auth/admin/create`,
}
