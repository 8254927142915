import { styled } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import { useDispatch, useSelector } from 'react-redux'

import { documentInvalidationsSelectors } from '../selectors'
import { documentInvalidationsActions } from '../slice'
import {
  FilterChipsInHeader,
  IShowablefilterData,
} from 'app/components/common/FilterChipsInHeader'
import { PageHeader } from 'app/components/common/globals/pageHeader'
import PrimaryButton from 'app/components/button/primaryButton'

export const DocumentInvalidationsHeader = () => {
  const dispatch = useDispatch()

  const filterDocumentInvalidations = useSelector(
    documentInvalidationsSelectors.filterDocumentInvalidations,
  )
  const displayFilterDocumentInvalidationsModal = useSelector(
    documentInvalidationsSelectors.displayFilterDocumentInvalidationsModal,
  )

  const handleOpenFilterModal = () => {
    dispatch(
      documentInvalidationsActions.setDisplayFilterDocumentInvalidationsModal(
        true,
      ),
    )
  }

  const showablefilterDocumentInvalidations: IShowablefilterData[] = [
    { label: 'AdminID', value: filterDocumentInvalidations?.adminID },
    { label: 'UserCoreID', value: filterDocumentInvalidations?.userCoreID },
    {
      label: 'VouchererName',
      value: filterDocumentInvalidations?.vouchererName,
    },
    { label: 'Status', value: filterDocumentInvalidations?.status },
    {
      label: 'CreatedAt From',
      value: filterDocumentInvalidations?.createdAtFrom,
      isDate: true,
    },
    {
      label: 'CreatedAt To',
      value: filterDocumentInvalidations?.createdAtTo,
      isDate: true,
    },
    {
      label: 'UpdateAt From',
      value: filterDocumentInvalidations?.updateAtFrom,
      isDate: true,
    },
    {
      label: 'UpdateAt To',
      value: filterDocumentInvalidations?.updateAtTo,
      isDate: true,
    },
  ]

  const handleDelete = (label: string) => {
    dispatch(
      documentInvalidationsActions.setFilterDocumentInvalidationsData({
        ...filterDocumentInvalidations,
        [label]: undefined,
      }),
    )
    dispatch(documentInvalidationsActions.getDocumentInvalidations())
  }

  return (
    <>
      <PageHeader title="Document Invalidations">
        <PrimaryButton
          onClick={handleOpenFilterModal}
          endIcon={<StyledSearchIcon />}
        >
          Search
        </PrimaryButton>
      </PageHeader>
      <FilterSection>
        {!displayFilterDocumentInvalidationsModal && (
          <FilterChipsInHeader
            showableFilter={showablefilterDocumentInvalidations}
            deleteFilter={handleDelete}
          />
        )}
      </FilterSection>
    </>
  )
}

const FilterSection = styled('div')`
  display: flex;
  gap: 8px;
  width: 100%;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
`
const StyledSearchIcon = styled(SearchIcon)`
  color: var(--white);
`
