import { createSelector } from '@reduxjs/toolkit'

import { RootState } from 'store/types'
import { initialState } from './slice'

export const resubmissionItemDomains = {
  root: (state: RootState) => state.resubmissionItem || initialState,
  displayConfirmationModal: (state: RootState) =>
    state.resubmissionItem.displayConfirmationModal,
  kycData: (state: RootState) => state.resubmissionItem.kycData,
}

export const resubmissionItemSelectors = {
  root: createSelector(resubmissionItemDomains.root, root => root),
  resubmissionItem: createSelector(
    resubmissionItemDomains.root,
    root => root.resubmissionItem,
  ),
  isLoading: createSelector(
    resubmissionItemDomains.root,
    root => root.isLoading,
  ),
  isSubmitLoading: createSelector(
    resubmissionItemDomains.root,
    root => root.isSubmitLoading,
  ),

  displayConfirmationModal: createSelector(
    resubmissionItemDomains.displayConfirmationModal,
    displayConfirmationModal => displayConfirmationModal,
  ),
  kycData: createSelector(resubmissionItemDomains.kycData, kycData => kycData),
}
