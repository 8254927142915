import { call, put, select, takeLatest } from 'redux-saga/effects'
import { ordersActions } from './slice'
import { getOrdersReq } from './providers'
import { AxiosResponse } from 'axios'
import { customToast } from 'app/components/toast/CustomToast'
import { errorMessage } from 'utils/parser'
import { ordersSelectors } from './selectors'
import { IFilterOrders, IOrder } from './types'

function* getOrders(): any {
  yield put(ordersActions.setIsLoadingOrders(true))
  try {
    const filterOrders: IFilterOrders = yield select(
      ordersSelectors.filterOrders,
    )

    const response: AxiosResponse = yield call(getOrdersReq, filterOrders)

    const ordersList = (response?.data?.data?.orders || []) as IOrder[]

    yield put(ordersActions.setOrders(ordersList))

    ordersList.length === 0 || ordersList.length < filterOrders.pageSize
      ? yield put(ordersActions.setIsAllOrdersFetched(true))
      : yield put(ordersActions.setIsAllOrdersFetched(false))
  } catch (error) {
    customToast.error(errorMessage(error))
  } finally {
    yield put(ordersActions.setIsLoadingOrders(false))
  }
}

export function* ordersSaga() {
  yield takeLatest(ordersActions.getOrders.type, getOrders)
}
