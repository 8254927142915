import { FC } from 'react'
import styled from 'styled-components'
import { ROW_ALIGN_CENTER__SPACE_B } from 'styles/globalStyles'
import { mediaQueries } from 'styles/mediaQueries'

interface IPageHeaderProps {
  title: string
  children?: React.ReactNode
}

export const PageHeader: FC<IPageHeaderProps> = ({ title, children }) => {
  return (
    <TitleAndActionWrapper>
      <Title>{title}</Title>
      {children}
    </TitleAndActionWrapper>
  )
}

const Title = styled('div')`
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: -0.02em;
  color: var(--black-knight);
  ${mediaQueries.lessThan('sm')`
    font-size: 26px;
    line-height: 32px;
  `}
  ${mediaQueries.lessThan('xs')`
    font-size: 22px;
    line-height: 24px;
    `}
`
const TitleAndActionWrapper = styled('div')`
  ${ROW_ALIGN_CENTER__SPACE_B}
  width:100%;
  margin-bottom: 24px;
  ${mediaQueries.lessThan('sm')`
   margin-bottom: 20px;
  `}
  ${mediaQueries.lessThan('xs')`
   margin-bottom: 16px;
    `}
`
