import axiosInstance from 'api/axios'
import { AxiosResponse } from 'axios'
import { FilterVouchers, IIssueVoucher } from '../types'
import { removePrefix } from 'utils/string'
import { VouchersEndpoints } from 'api/endpoints/shop'

export function* getVouchersReq(filterData: FilterVouchers) {
  const response: AxiosResponse = yield axiosInstance.get(
    VouchersEndpoints.vouchersList(filterData),
  )
  return response
}

export function* addVoucherReq(params: IIssueVoucher) {
  const { userCoreID, voucherName, amount } = params
  const formattedUserCoreID = removePrefix(userCoreID)
  const response: AxiosResponse = yield axiosInstance.post(
    VouchersEndpoints.addVoucher(),
    {
      userCoreID: formattedUserCoreID,
      voucherName,
      amount,
    },
  )
  return response
}
