import { styled } from '@mui/material/styles'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import CardActions from '@mui/material/CardActions'
import Avatar from '@mui/material/Avatar'
import Typography from '@mui/material/Typography'
import { useDispatch } from 'react-redux'
import { RolesList } from './rolesList'
import { Admin } from '../../types'
import { adminsActions } from '../../slice'
import BlockIcon from '@mui/icons-material/Block'
import { ellipsisBetween, generateUniqueColor } from 'utils/string'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { IconButton } from '@mui/material'
import { mediaQueries } from 'styles/mediaQueries'
import PrimaryButton from 'app/components/button/primaryButton'
import { useCopy } from 'hooks/useCopy'

interface AdminCardProps {
  admin: Admin
}

export default function AdminCard({ admin }: AdminCardProps) {
  const dispatch = useDispatch()

  const { handleCopy, isCopied } = useCopy()

  const { coreID, id, fullName, isBlocked } = admin

  const handleBlockClick = () => {
    dispatch(adminsActions.blockUnblockAdmin({ admin }))
  }

  const avatarColor = generateUniqueColor(coreID)

  return (
    <Card sx={{ width: '100%' }}>
      <CardHeaderStyles
        avatar={
          <Avatar sx={{ bgcolor: avatarColor }} aria-label="recipe">
            {fullName
              .split(' ')
              .map(part => part.charAt(0))
              .join('')
              .toUpperCase()}
          </Avatar>
        }
        action={
          <PrimaryButtonStyle
            isblocked={isBlocked ? 'true' : 'false'}
            onClick={handleBlockClick}
          >
            {isBlocked ? 'Unblock' : 'Block'}
          </PrimaryButtonStyle>
        }
        title={
          <FlexStartRow>
            <Typography variant="h5">{fullName}</Typography>
            {isBlocked ? <BlockIcon color="error" /> : <></>}
          </FlexStartRow>
        }
      />

      <StyledCardContent>
        <ItemRow>
          <ItemTitle>ID:</ItemTitle>
          <Typography variant="body2" color="text.secondary">
            {id}
          </Typography>
        </ItemRow>
        <ItemRow>
          <ItemTitle>Code:</ItemTitle>
          <WidgetContainer>
            <Typography variant="body2" color="text.secondary">
              {ellipsisBetween(coreID, 8)}
            </Typography>
            <StyledIconButton
              disabled={!coreID}
              onClick={() => handleCopy(coreID)}
            >
              <StyledContentCopyIcon
                fontSize="small"
                iscopied={isCopied ? 'true' : 'false'}
              />
            </StyledIconButton>
          </WidgetContainer>
        </ItemRow>
      </StyledCardContent>
      <CardActions disableSpacing>
        <ExpndMoreTitle>Roles</ExpndMoreTitle>
      </CardActions>
      <RolesList admin={admin} />
    </Card>
  )
}

const ItemRow = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
  padding: 4px 16px;
`

const ItemTitle = styled('h1')`
  color: var(--causeway);
  font-size: 16px;
  ${mediaQueries.lessThan('xs')`
  font-size: 14px;
   margin:5px 0;
  `}
`

const FlexStartRow = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 14px;
  ${mediaQueries.lessThan('xs')`
  h5 {
    font-size: 20px;
  }
  `}
`

const StyledCardContent = styled(CardContent)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 14px;
  color: var(--causeway);
  ${mediaQueries.lessThan('xs')`
  gap: 8px;
  padding:8px`}
`

const ExpndMoreTitle = styled(Typography)`
  font-size: 18px;
  font-weight: 700;
  color: var(--mirage);
  padding-left: 10px;
  ${mediaQueries.lessThan('sm')`
    font-size: 16px;
    `}
  ${mediaQueries.lessThan('xs')`
    font-size: 14px;`}
`

const PrimaryButtonStyle = styled(PrimaryButton)<{
  isblocked: 'true' | 'false'
}>`
  background-color: ${({ isblocked }) =>
    isblocked === 'true' ? 'var(--error)' : 'var(--success)'};
  width: 75px;
  &:hover {
    background-color: ${({ isblocked }) =>
      isblocked === 'true' ? 'var(--error)' : 'var(--success)'};
  }
`

const StyledIconButton = styled(IconButton)`
  padding: 0;
  margin: 0;
  width: 32px;
  height: 32px;
  align-self: center;
  justify-self: center;
  & > * {
    color: ${({ disabled, theme }) =>
      disabled ? theme.palette.grey[400] : theme.palette.common.black};
  }
`

const StyledContentCopyIcon = styled(ContentCopyIcon)<{
  iscopied: 'false' | 'true'
}>`
  color: ${({ iscopied }) => (iscopied ? 'gray' : 'black')};
`

const WidgetContainer = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
`
const CardHeaderStyles = styled(CardHeader)`
  && .MuiCardHeader-action {
    margin: 0;
    ${mediaQueries.lessThan('sm')`
    margin-top: 5px;
    margin-right: 0px;
    margin-bottom: 0px;
     `}
  }
  ${mediaQueries.lessThan('xs')`
    padding: 8px;
`}
`
