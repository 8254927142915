import { styled } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import { useDispatch, useSelector } from 'react-redux'
import { documentRejectionsSelectors } from '../selectors'
import { documentRejectionsActions } from '../slice'
import {
  FilterChipsInHeader,
  IShowablefilterData,
} from 'app/components/common/FilterChipsInHeader'
import { PageHeader } from 'app/components/common/globals/pageHeader'
import PrimaryButton from 'app/components/button/primaryButton'

export const DocumentRejectionsHeader = () => {
  const dispatch = useDispatch()

  const filterDocumentRejections = useSelector(
    documentRejectionsSelectors.filterDocumentRejections,
  )
  const displayFilterDocumentRejectionsModal = useSelector(
    documentRejectionsSelectors.displayFilterDocumentRejectionsModal,
  )

  const handleOpenFilterModal = () => {
    dispatch(
      documentRejectionsActions.setDisplayFilterDocumentRejectionsModal(true),
    )
  }

  const showablefilterDocumentRejections: IShowablefilterData[] = [
    { label: 'AdminID', value: filterDocumentRejections?.adminID },
    { label: 'UserCoreID', value: filterDocumentRejections?.userCoreID },
    { label: 'VouchererName', value: filterDocumentRejections?.vouchererName },
    { label: 'Status', value: filterDocumentRejections?.status },
    {
      label: 'CreatedAt From',
      value: filterDocumentRejections?.createdAtFrom,
      isDate: true,
    },
    {
      label: 'CreatedAt To',
      value: filterDocumentRejections?.createdAtTo,
      isDate: true,
    },
    {
      label: 'UpdateAt From',
      value: filterDocumentRejections?.updateAtFrom,
      isDate: true,
    },
    {
      label: 'UpdateAt To',
      value: filterDocumentRejections?.updateAtTo,
      isDate: true,
    },
  ]

  const handleDelete = (label: string) => {
    dispatch(
      documentRejectionsActions.setFilterDocumentRejections({
        ...filterDocumentRejections,
        [label]: undefined,
      }),
    )
    dispatch(documentRejectionsActions.getDocumentRejections())
  }

  return (
    <>
      <PageHeader title="Document Rejections">
        <PrimaryButton
          onClick={handleOpenFilterModal}
          endIcon={<StyledSearchIcon />}
        >
          Search
        </PrimaryButton>
      </PageHeader>
      <FilterSection>
        {!displayFilterDocumentRejectionsModal && (
          <FilterChipsInHeader
            showableFilter={showablefilterDocumentRejections}
            deleteFilter={handleDelete}
          />
        )}
      </FilterSection>
    </>
  )
}

const FilterSection = styled('div')`
  display: flex;
  gap: 8px;
  width: 100%;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
`
const StyledSearchIcon = styled(SearchIcon)`
  color: var(--white);
`
