import { assetsManagementActions } from '../slice'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { assetsManagementSelectors } from '../selectors'
import { styled } from '@mui/material'
import {
  ASSETS_MANAGEMENT_TABLE_KEY,
  columnsForShowInAssetsManagementTable,
} from '../constants'
import SortableTable from 'app/components/table/sortableTable'
import { capitalizeAllCharacters } from 'utils/string'
import { IAssetManagement } from '../types'
import { MAX_SAFE_INTEGER } from 'app/constants'
import { ISortType } from 'app/types'

export const AssetsManagementTable = () => {
  const dispatch = useDispatch()

  const assetsManagement = useSelector(
    assetsManagementSelectors.assetsManagementList,
  )
  const isLoadingAssetsManagement = useSelector(
    assetsManagementSelectors.isLoadingAssetsManagement,
  )
  const filterAssetsManagement = useSelector(
    assetsManagementSelectors.filterAssetsManagement,
  )
  const lastPageOfAssetsManagementTable = useSelector(
    assetsManagementSelectors.lastPageOfAssetsManagementTable,
  )
  const isAllAssetsManagementFetched = useSelector(
    assetsManagementSelectors.isAllAssetsManagementFetched,
  )

  useEffect(() => {
    dispatch(assetsManagementActions.getAssetsManagement())
  }, [])

  const handleSetNumberRowsPerPage = (num: number) => {
    const tablePageSizeTitle = `${ASSETS_MANAGEMENT_TABLE_KEY}_pageSize`
    localStorage.setItem(tablePageSizeTitle, num.toString())
    dispatch(
      assetsManagementActions.setFilterAssetsManagement({
        ...filterAssetsManagement,
        pageSize: num,
      }),
    )
    dispatch(assetsManagementActions.getAssetsManagement())
  }
  return (
    <Wrapper>
      <SortableTable
        name={ASSETS_MANAGEMENT_TABLE_KEY}
        isLoading={isLoadingAssetsManagement}
        data={assetsManagement}
        columns={columnsForShowInAssetsManagementTable}
        handleChangeLastPage={(newPage: number) => {
          dispatch(
            assetsManagementActions.setFilterAssetsManagement({
              ...filterAssetsManagement,
              page: newPage,
            }),
          )

          dispatch(assetsManagementActions.getAssetsManagement())
        }}
        setPage={page =>
          dispatch(
            assetsManagementActions.setLastPageOfAssetsManagementTable(page),
          )
        }
        page={lastPageOfAssetsManagementTable}
        setNumberRowsPerPage={num => handleSetNumberRowsPerPage(num)}
        handleCustomRequestSort={(property, order) => {
          dispatch(
            assetsManagementActions.setFilterAssetsManagement({
              ...filterAssetsManagement,
              sortField: property,
              sortType: capitalizeAllCharacters(order) as ISortType,
            }),
          )
          const oneRow = assetsManagement[0]
          if (oneRow) {
            const propertyType = typeof oneRow[property as keyof typeof oneRow]
            if (propertyType === 'number') {
              dispatch(assetsManagementActions.getAssetsManagement())
            }
          }
        }}
        countOfData={MAX_SAFE_INTEGER}
        showNumberOfPages={false}
        disableNextButton={isAllAssetsManagementFetched}
        onRowClick={row => {
          dispatch(
            assetsManagementActions.setDisplayDetailAssetsManagementModal(
              row as IAssetManagement,
            ),
          )
        }}
      />
    </Wrapper>
  )
}

const Wrapper = styled('div')`
  justify-content: center;
  grid-gap: 10px 0;
  margin-top: 20px;
  margin-bottom: 20px;
  min-width: 0;
`
