import { styled } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import { useDispatch, useSelector } from 'react-redux'
import { emailInvalidationsSelectors } from '../selectors'
import { emailInvalidationsActions } from '../slice'
import {
  FilterChipsInHeader,
  IShowablefilterData,
} from 'app/components/common/FilterChipsInHeader'
import { PageHeader } from 'app/components/common/globals/pageHeader'
import PrimaryButton from 'app/components/button/primaryButton'

export const EmailInvalidationsHeader = () => {
  const dispatch = useDispatch()

  const filterEmailInvalidations = useSelector(
    emailInvalidationsSelectors.filterEmailInvalidations,
  )
  const displayFilterEmailInvalidationsModal = useSelector(
    emailInvalidationsSelectors.displayFilterEmailInvalidationsModal,
  )

  const handleOpenFilterModal = () => {
    dispatch(
      emailInvalidationsActions.setDisplayFilterEmailInvalidationsModal(true),
    )
  }

  const showablefilterEmailInvalidations: IShowablefilterData[] = [
    { label: 'AdminID', value: filterEmailInvalidations?.adminID },
    { label: 'UserCoreID', value: filterEmailInvalidations?.userCoreID },
    { label: 'VouchererName', value: filterEmailInvalidations?.vouchererName },
    { label: 'Status', value: filterEmailInvalidations?.status },
    {
      label: 'CreatedAt From',
      value: filterEmailInvalidations?.createdAtFrom,
      isDate: true,
    },
    {
      label: 'CreatedAt To',
      value: filterEmailInvalidations?.createdAtTo,
      isDate: true,
    },
    {
      label: 'UpdateAt From',
      value: filterEmailInvalidations?.updateAtFrom,
      isDate: true,
    },
    {
      label: 'UpdateAt To',
      value: filterEmailInvalidations?.updateAtTo,
      isDate: true,
    },
  ]

  const handleDelete = (label: string) => {
    dispatch(
      emailInvalidationsActions.setFilterEmailInvalidations({
        ...filterEmailInvalidations,
        [label]: undefined,
      }),
    )
    dispatch(emailInvalidationsActions.getEmailInvalidations())
  }

  return (
    <>
      <PageHeader title="Email Invalidations">
        <PrimaryButton
          onClick={handleOpenFilterModal}
          endIcon={<StyledSearchIcon />}
        >
          Search
        </PrimaryButton>
      </PageHeader>
      <FilterSection>
        {!displayFilterEmailInvalidationsModal && (
          <FilterChipsInHeader
            showableFilter={showablefilterEmailInvalidations}
            deleteFilter={handleDelete}
          />
        )}
      </FilterSection>
    </>
  )
}

const FilterSection = styled('div')`
  display: flex;
  gap: 8px;
  width: 100%;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
`
const StyledSearchIcon = styled(SearchIcon)`
  color: var(--white);
`
