import { createSelector } from '@reduxjs/toolkit'

import { RootState } from 'store/types'
import { initialState } from './slice'

export const paymentsDomains = {
  root: (state: RootState) => state.payments || initialState,
  isLoadingPayments: (state: RootState) => state.payments.isLoadingPayments,
  displayPaymentModal: (state: RootState) => state.payments.displayPaymentModal,
  paymentsError: (state: RootState) => state.payments.error,
  paymentList: (state: RootState) => state.payments.paymentList,
  filterPayments: (state: RootState) => state.payments.filterPayments,
  lastPageOfPaymentsTable: (state: RootState) =>
    state.payments.lastPageOfPaymentsTable,
  isAllPaymentsFetched: (state: RootState) =>
    state.payments.isAllPaymentsFetched,
}

export const paymentsSelectors = {
  root: createSelector(paymentsDomains.root, root => root),
  isLoadingPayments: createSelector(
    paymentsDomains.isLoadingPayments,
    isLoadingPayments => isLoadingPayments,
  ),
  paymentError: createSelector(
    paymentsDomains.paymentsError,
    paymentsError => paymentsError,
  ),
  displayPaymentModal: createSelector(
    paymentsDomains.displayPaymentModal,
    displayPaymentModal => displayPaymentModal,
  ),
  paymentList: createSelector(paymentsDomains.paymentList, payment => payment),
  filterPayments: createSelector(
    paymentsDomains.filterPayments,
    filterPayments => filterPayments,
  ),
  lastPageOfPaymentsTable: createSelector(
    paymentsDomains.lastPageOfPaymentsTable,
    lastPageOfTable => lastPageOfTable,
  ),
  isAllPaymentsFetched: createSelector(
    paymentsDomains.isAllPaymentsFetched,
    isAllFetched => isAllFetched,
  ),
}
