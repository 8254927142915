import { createSelector } from '@reduxjs/toolkit'

import { RootState } from 'store/types'
import { initialState } from './slice'
import { KYCNFT_STATE } from './constants'

export const nftsDomains = {
  root: (state: RootState) => state.nfts || initialState,
  nfts: (state: RootState) => state.nfts.Nfts,
  isLoading: (state: RootState) => state.nfts.isLoading,
  confirmRejectModalItem: (state: RootState) =>
    state.nfts.confirmRejectModalItem,
  isRejectingNft: (state: RootState) => state.nfts.isRejectingNft,
}

export const nftsSelectors = {
  root: createSelector(nftsDomains.root, root => root),
  nfts: createSelector(nftsDomains.nfts, nfts => nfts),
  issuedNfts: createSelector(nftsDomains.nfts, nfts =>
    nfts.filter(nft => nft.state !== KYCNFT_STATE[0]),
  ),
  isLoading: createSelector(nftsDomains.isLoading, isLoading => isLoading),
  getNftById: (id: string) =>
    createSelector(nftsDomains.nfts, nfts => {
      return nfts.find(nft => nft.id === id)
    }),
  confirmRejectModalItem: createSelector(
    nftsDomains.confirmRejectModalItem,
    confirmRejectModalItem => confirmRejectModalItem,
  ),
  isRejectingNft: createSelector(
    nftsDomains.isRejectingNft,
    isRejectingNft => isRejectingNft,
  ),
}
