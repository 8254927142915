import { createSelector } from '@reduxjs/toolkit'

import { RootState } from 'store/types'
import { initialState } from './slice'

export const phoneInvalidationsDomains = {
  root: (state: RootState) => state.phoneInvalidations || initialState,
  phoneInvalidationList: (state: RootState) =>
    state.phoneInvalidations.phoneInvalidationList,
  isLoadingPhoneInvalidations: (state: RootState) =>
    state.phoneInvalidations.isLoadingPhoneInvalidations,
  filterPhoneInvalidations: (state: RootState) =>
    state.phoneInvalidations.filterPhoneInvalidations,
  lastPageOfPhoneInvalidationsTable: (state: RootState) =>
    state.phoneInvalidations.lastPageOfPhoneInvalidationsTable,
  isAllPhoneInvalidationsFetched: (state: RootState) =>
    state.phoneInvalidations.isAllPhoneInvalidationsFetched,
  displayFilterPhoneInvalidationsModal: (state: RootState) =>
    state.phoneInvalidations.displayFilterPhoneInvalidationsModal,
}

export const phoneInvalidationsSelectors = {
  root: createSelector(phoneInvalidationsDomains.root, root => root),
  phoneInvalidationList: createSelector(
    phoneInvalidationsDomains.phoneInvalidationList,
    phoneInvalidations => phoneInvalidations,
  ),
  isLoadingPhoneInvalidations: createSelector(
    phoneInvalidationsDomains.isLoadingPhoneInvalidations,
    isLoading => isLoading,
  ),
  filterPhoneInvalidations: createSelector(
    phoneInvalidationsDomains.filterPhoneInvalidations,
    filterData => filterData,
  ),
  lastPageOfPhoneInvalidationsTable: createSelector(
    phoneInvalidationsDomains.lastPageOfPhoneInvalidationsTable,
    lastPageOfTable => lastPageOfTable,
  ),
  isAllPhoneInvalidationsFetched: createSelector(
    phoneInvalidationsDomains.isAllPhoneInvalidationsFetched,
    isAllFetched => isAllFetched,
  ),
  displayFilterPhoneInvalidationsModal: createSelector(
    phoneInvalidationsDomains.displayFilterPhoneInvalidationsModal,
    displayFilterModal => displayFilterModal,
  ),
}
