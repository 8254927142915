import { PayloadAction } from '@reduxjs/toolkit'
import { Role, RolesState, Route } from './types'
import { createSlice } from 'store/toolkit'

export const initialState: RolesState = {
  rolesList: [],
  isLoadingRoles: false,
  deleteConfirmationModal: {
    display: false,
    role: null,
    route: null,
  },
  routesList: [],
  isRouteAdding: null,
  addRoleModalDisplay: false,
}

const rolesSlice = createSlice({
  name: 'roles',
  initialState,
  reducers: {
    // create a action named fetch Roles without any payload
    fetchRoles(state, action: PayloadAction<{ silent?: boolean }>) {},
    setRolesList(state, action: PayloadAction<Role[]>) {
      state.rolesList = action.payload
    },
    setIsLoadingRoles(state, action: PayloadAction<boolean>) {
      state.isLoadingRoles = action.payload
    },
    setDeleteConfirmationModalData(
      state,
      action: PayloadAction<RolesState['deleteConfirmationModal']>,
    ) {
      state.deleteConfirmationModal = action.payload
    },
    deleteRouteFromRole(state) {},
    fetchRoutes(state) {},
    setRoutesList(state, action: PayloadAction<Route[]>) {
      state.routesList = action.payload
    },
    addRouteToRole(
      state,
      action: PayloadAction<{ role: Role; route: Route }>,
    ) {},
    setIsRouteAdding(
      state,
      action: PayloadAction<RolesState['isRouteAdding']>,
    ) {
      state.isRouteAdding = action.payload
    },
    setAddRoleModalDisplay(state, action: PayloadAction<boolean>) {
      state.addRoleModalDisplay = action.payload
    },
    addRole(state, action: PayloadAction<{ code: string; name: string }>) {},
  },
})

export const {
  actions: rolesActions,
  reducer: rolesReducer,
  name: sliceKey,
} = rolesSlice
