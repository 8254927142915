import {
  sha256,
  toUtf8Bytes,
  toUtf8String,
  Provider,
  getDefaultProvider,
  BigNumber,
} from 'corebc'
import { KYCNFT_STATE } from 'app/containers/Nfts/constants'
import { Nft, NftContractResponse } from 'app/containers/Nfts/types'

export const getAllNftResponseParser = (
  nfts: NftContractResponse[],
  nftsAddressesKeys: string[],
): Nft[] => {
  const formatedNfts: Nft[] = []
  nfts?.forEach((documentRelatedNft: NftContractResponse, index: number) => {
    if (isNonEmptyTruthy(documentRelatedNft[0])) {
      Object.values(documentRelatedNft[0]).forEach((value: bigint, idInex) => {
        const itemState = BigNumber.from(
          documentRelatedNft[1][idInex],
        ).toNumber()
        formatedNfts.push({
          id: BigNumber.from(value).toString(),
          state: KYCNFT_STATE[itemState as keyof typeof KYCNFT_STATE],
          type: nftsAddressesKeys[index],
        })
      })
    }
  })
  return formatedNfts
}

export const getRpcProvider = (
  providerLink = process.env.REACT_APP_REGISTERY_PROVIDER_ADDRESS as string,
): Provider => {
  const provider = getDefaultProvider(providerLink)
  return provider
}

export const getSha3FromString = (text: string): string =>
  sha256(toUtf8Bytes(text))
// Decode the data from bytes to string

export const decodeByteToString = (data: string) => toUtf8String(data)

const isNonEmptyTruthy = (input: any): boolean => {
  // Check for falsy values
  if (!input) {
    return false
  }
  // Check for an empty object
  if (
    typeof input === 'object' &&
    !Array.isArray(input) &&
    Object.keys(input).length === 0
  ) {
    return false
  }
  // Check for an empty array

  if (Array.isArray(input) && input.length === 0) {
    return false
  }
  // If none of the above, return true
  return true
}
