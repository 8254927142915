import { useEffect, useState } from 'react'
import { Box, styled } from '@mui/material'

import { useDispatch, useSelector } from 'react-redux'
import BaseDropdownInput from 'app/components/input/dropdownInput'

import BaseInput from 'app/components/input/baseInput'
import { MaskInput } from 'app/components/input/inputWithMask'
import { getDateFromTimestamp, getTimestampFromDate } from 'utils/date'
import { DeleteForeverIcon } from 'app/components/icon/deleteForeverIcon'
import { emailVerificationsSelectors } from 'app/containers/EmailVerifications/selectors'
import { emailVerificationsActions } from 'app/containers/EmailVerifications/slice'
import {
  EMAIL_VERIFICATIONS_MINT_TYPE_OPTIONS,
  EMAIL_VERIFICATIONS_STATUS_OPTIONS,
} from 'app/containers/EmailVerifications/constants'
import { mediaQueries } from 'styles/mediaQueries'
import PrimaryButton from 'app/components/button/primaryButton'

export const FilterEmailVerificationsContent = () => {
  const dispatch = useDispatch()
  const filterEmailVerifications = useSelector(
    emailVerificationsSelectors.filterEmailVerifications,
  )

  const [createAtFrom, setCreateAtFrom] = useState<string>(
    filterEmailVerifications?.createdAtFrom?.toString() ?? '',
  )
  const [createAtTo, setCreateAtTo] = useState<string>(
    filterEmailVerifications?.createdAtTo?.toString() ?? '',
  )
  const [expiration, setExpiration] = useState<string>(
    filterEmailVerifications?.expiration?.toString() ?? '',
  )

  const handleInputChange = (event: any) => {
    dispatch(
      emailVerificationsActions.setFilterEmailVerifications({
        ...filterEmailVerifications,
        [event.target.name]: event.target.value,
      }),
    )
  }

  const handleDateInputChange = (event: any) => {
    const timeStamp = getTimestampFromDate(event.target.value)

    dispatch(
      emailVerificationsActions.setFilterEmailVerifications({
        ...filterEmailVerifications,
        [event.target.name]: timeStamp,
      }),
    )
  }

  useEffect(() => {
    setCreateAtFrom(
      getDateFromTimestamp(filterEmailVerifications?.createdAtFrom),
    )
    setCreateAtTo(getDateFromTimestamp(filterEmailVerifications?.createdAtTo))
  }, [])

  const handleSearching = () => {
    dispatch(
      emailVerificationsActions.setDisplayFilterEmailVerificationsModal(false),
    )
    dispatch(emailVerificationsActions.getEmailVerifications())
  }

  return (
    <Wrapper>
      <TwoItemsContainer>
        <ItemConatiner>
          <ItemLabel>CoreID:</ItemLabel>
          <BaseInput
            fullWidth
            type="text"
            id="coreId"
            name="coreID"
            label="CoreID"
            onChange={handleInputChange}
            value={filterEmailVerifications?.coreID}
            InputProps={{
              endAdornment: filterEmailVerifications?.coreID ? (
                <DeleteForeverIcon
                  onClick={() =>
                    dispatch(
                      emailVerificationsActions.setFilterEmailVerifications({
                        ...filterEmailVerifications,
                        coreID: '',
                      }),
                    )
                  }
                />
              ) : undefined,
            }}
          />
        </ItemConatiner>
        <ItemConatiner>
          <ItemLabel>Voucherer Name:</ItemLabel>
          <BaseInput
            fullWidth
            type="text"
            id="vouchererName"
            name="vouchererName"
            label="VouchererName"
            onChange={handleInputChange}
            value={filterEmailVerifications?.vouchererName}
            InputProps={{
              endAdornment: filterEmailVerifications?.vouchererName ? (
                <DeleteForeverIcon
                  onClick={() =>
                    dispatch(
                      emailVerificationsActions.setFilterEmailVerifications({
                        ...filterEmailVerifications,
                        vouchererName: '',
                      }),
                    )
                  }
                />
              ) : undefined,
            }}
          />
        </ItemConatiner>
      </TwoItemsContainer>
      <TwoItemsContainer>
        <ItemConatiner>
          <ItemLabel>Status:</ItemLabel>
          <BaseDropdownInput
            label="Status"
            placeholder="Status"
            name="status"
            fullWidth
            options={EMAIL_VERIFICATIONS_STATUS_OPTIONS}
            onChange={handleInputChange}
            value={filterEmailVerifications?.status ?? ''}
            endAdornment={
              filterEmailVerifications?.status ? (
                <DeleteForeverIcon
                  sx={{
                    position: 'absolute',
                    right: '20px',
                  }}
                  onClick={() =>
                    dispatch(
                      emailVerificationsActions.setFilterEmailVerifications({
                        ...filterEmailVerifications,
                        status: '',
                      }),
                    )
                  }
                />
              ) : undefined
            }
          />
        </ItemConatiner>
        <ItemConatiner>
          <ItemLabel>MintType:</ItemLabel>
          <BaseDropdownInput
            label="MintType"
            placeholder="MintType"
            name="mintType"
            fullWidth
            options={EMAIL_VERIFICATIONS_MINT_TYPE_OPTIONS}
            onChange={handleInputChange}
            value={filterEmailVerifications?.mintType ?? ''}
            endAdornment={
              filterEmailVerifications?.mintType ? (
                <DeleteForeverIcon
                  sx={{
                    position: 'absolute',
                    right: '20px',
                  }}
                  onClick={() =>
                    dispatch(
                      emailVerificationsActions.setFilterEmailVerifications({
                        ...filterEmailVerifications,
                        mintType: '',
                      }),
                    )
                  }
                />
              ) : undefined
            }
          />
        </ItemConatiner>
      </TwoItemsContainer>
      <TwoItemsContainer>
        <ItemConatiner>
          <ItemLabel>ID:</ItemLabel>
          <BaseInput
            fullWidth
            type="text"
            id="id"
            name="id"
            label="ID"
            onChange={handleInputChange}
            value={filterEmailVerifications?.id}
            InputProps={{
              endAdornment: filterEmailVerifications?.id ? (
                <DeleteForeverIcon
                  onClick={() =>
                    dispatch(
                      emailVerificationsActions.setFilterEmailVerifications({
                        ...filterEmailVerifications,
                        id: '',
                      }),
                    )
                  }
                />
              ) : undefined,
            }}
          />
        </ItemConatiner>
        <ItemConatiner>
          <ItemLabel>NFTID:</ItemLabel>
          <BaseInput
            fullWidth
            type="text"
            id="nftID"
            name="nftID"
            label="NFTID"
            onChange={handleInputChange}
            value={filterEmailVerifications?.nftID}
            InputProps={{
              endAdornment: filterEmailVerifications?.nftID ? (
                <DeleteForeverIcon
                  onClick={() =>
                    dispatch(
                      emailVerificationsActions.setFilterEmailVerifications({
                        ...filterEmailVerifications,
                        nftID: '',
                      }),
                    )
                  }
                />
              ) : undefined,
            }}
          />
        </ItemConatiner>
      </TwoItemsContainer>
      <TwoItemsContainer>
        <ItemConatiner>
          <ItemLabel>BcTxID:</ItemLabel>
          <BaseInput
            fullWidth
            type="text"
            id="bcTxID"
            name="bcTxID"
            label="BcTxID"
            onChange={handleInputChange}
            value={filterEmailVerifications?.bcTxID}
            InputProps={{
              endAdornment: filterEmailVerifications?.bcTxID ? (
                <DeleteForeverIcon
                  onClick={() =>
                    dispatch(
                      emailVerificationsActions.setFilterEmailVerifications({
                        ...filterEmailVerifications,
                        bcTxID: '',
                      }),
                    )
                  }
                />
              ) : undefined,
            }}
          />
        </ItemConatiner>
        <ItemConatiner>
          <ItemLabel>VerifyRetry:</ItemLabel>
          <BaseInput
            fullWidth
            type="text"
            id="verifyRetry"
            name="verifyRetry"
            label="VerifyRetry"
            onChange={handleInputChange}
            value={filterEmailVerifications?.verifyRetry}
            InputProps={{
              endAdornment: filterEmailVerifications?.verifyRetry ? (
                <DeleteForeverIcon
                  onClick={() =>
                    dispatch(
                      emailVerificationsActions.setFilterEmailVerifications({
                        ...filterEmailVerifications,
                        verifyRetry: '',
                      }),
                    )
                  }
                />
              ) : undefined,
            }}
          />
        </ItemConatiner>
      </TwoItemsContainer>

      <TwoItemsContainer>
        <ItemConatiner>
          <ItemLabel>TxHash:</ItemLabel>
          <BaseInput
            fullWidth
            type="text"
            id="txHash"
            name="txHash"
            label="TxHash"
            onChange={handleInputChange}
            value={filterEmailVerifications?.txHash}
            InputProps={{
              endAdornment: filterEmailVerifications?.txHash ? (
                <DeleteForeverIcon
                  onClick={() =>
                    dispatch(
                      emailVerificationsActions.setFilterEmailVerifications({
                        ...filterEmailVerifications,
                        txHash: '',
                      }),
                    )
                  }
                />
              ) : undefined,
            }}
          />
        </ItemConatiner>
        <ItemConatiner>
          <ItemLabel>Expiration:</ItemLabel>
          <MaskInput
            fullWidth
            variant="outlined"
            maskedValue={expiration}
            setMaskedValue={setExpiration}
            type="text"
            id="expiration"
            name="expiration"
            label="Expiration"
            placeholder="YYYY-MM-DD"
            onChange={handleDateInputChange}
            InputProps={{
              endAdornment: expiration ? (
                <DeleteForeverIcon
                  onClick={() => {
                    dispatch(
                      emailVerificationsActions.setFilterEmailVerifications({
                        ...filterEmailVerifications,
                        expiration: undefined,
                      }),
                    )
                    setExpiration('')
                  }}
                />
              ) : undefined,
            }}
          />
        </ItemConatiner>
      </TwoItemsContainer>
      <TwoItemsContainer>
        <ItemConatiner>
          <ItemLabel>CreatedAt From:</ItemLabel>
          <MaskInput
            fullWidth
            variant="outlined"
            maskedValue={createAtFrom}
            setMaskedValue={setCreateAtFrom}
            type="text"
            id="createdAtFrom"
            name="createdAtFrom"
            label="CreatedAt From"
            placeholder="YYYY-MM-DD"
            onChange={handleDateInputChange}
            InputProps={{
              endAdornment: createAtFrom ? (
                <DeleteForeverIcon
                  onClick={() => {
                    dispatch(
                      emailVerificationsActions.setFilterEmailVerifications({
                        ...filterEmailVerifications,
                        createdAtFrom: undefined,
                      }),
                    )
                    setCreateAtFrom('')
                  }}
                />
              ) : undefined,
            }}
          />
        </ItemConatiner>
        <ItemConatiner>
          <ItemLabel>CreatedAt To:</ItemLabel>
          <MaskInput
            fullWidth
            variant="outlined"
            maskedValue={createAtTo}
            setMaskedValue={setCreateAtTo}
            type="text"
            id="createdAtTo"
            name="createdAtTo"
            label="CreatedAt To"
            placeholder="YYYY-MM-DD"
            onChange={handleDateInputChange}
            InputProps={{
              endAdornment: createAtTo ? (
                <DeleteForeverIcon
                  onClick={() => {
                    dispatch(
                      emailVerificationsActions.setFilterEmailVerifications({
                        ...filterEmailVerifications,
                        createdAtTo: undefined,
                      }),
                    )
                    setCreateAtTo('')
                  }}
                />
              ) : undefined,
            }}
          />
        </ItemConatiner>
      </TwoItemsContainer>

      <CTAContainer>
        <PrimaryButtons onClick={handleSearching}>Search</PrimaryButtons>
      </CTAContainer>
    </Wrapper>
  )
}

const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 32px;
  width: 100%;
  ${mediaQueries.lessThan('sm')`
  gap: 23px;`}
  ${mediaQueries.lessThan('xs')`
  gap: 16px;`}
`

const TwoItemsContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 32px;
  width: 100%;
  ${mediaQueries.lessThan('xs')`
  flex-direction: column;
  gap: 16px;
  `}
`

const ItemConatiner = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  width: 100%;
  min-width: 452px;
  ${mediaQueries.lessThan('lg')`
  min-width: 417px;
  `}
  ${mediaQueries.lessThan('md')`
  min-width: 239px;
  `}
  ${mediaQueries.lessThan('sm')`
  min-width: 241px;
  `}
`

const ItemLabel = styled(Box)`
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.palette.text.primary};
  word-wrap: normal;
  min-width: 120px;
  font-weight: 700;
`
const CTAContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 32px;
  margin-top: 28px;
  width: 100%;
  ${mediaQueries.lessThan('sm')`
  margin-top: 16px;`}
  ${mediaQueries.lessThan('xs')`
  margin-top: 8px;`}
`

const PrimaryButtons = styled(PrimaryButton)`
  width: 200px;
  ${mediaQueries.lessThan('sm')`
  width: 111px;
`}
  ${mediaQueries.lessThan('xs')`
  width: 100%;`}
`
