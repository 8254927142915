import { Widget } from './widget'
import { Box, styled } from '@mui/material'
import { mediaQueries } from 'styles/mediaQueries'
import { capitalizeWithDot } from 'utils/string'

interface IRowProps {
  name: string
  userValue?: string
  providerValue?: string
  value?: string
}
export const Row = (props: IRowProps) => {
  return (
    <Wrapper>
      <Title>{capitalizeWithDot(props.name)}</Title>
      <Widget title={props.providerValue ?? ''} type="provider" />
      <Widget title={props.userValue ?? ''} type="user" />
      <Widget title={props.value ?? ''} type="value" />
    </Wrapper>
  )
}

const Wrapper = styled(Box)`
  display: grid;
  grid-template-columns: 2fr 2fr 2fr 2fr;
  border-bottom: 1px solid #e0e0e0;
  border-left: 1px solid #e0e0e0;
`

const Title = styled('h4')`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 16px;
  word-break: break-word;
  ${mediaQueries.lessThan('sm')`
    font-size: 14px;
    `}
  ${mediaQueries.lessThan('xs')`
    font-size: 12px;
    `}
`
