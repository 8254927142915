const CommonTypographyStyle = `
  text-align: center;
  letter-spacing: -0.01em;
  font-style: normal;
`

//HeadLine H1
export const H1 = `
  ${CommonTypographyStyle}
  font-size: 72px;
  line-height: 90px;
`

export const H1_REGULAR = `
  ${H1}
  font-weight: 400;
`

export const H1_MEDIUM = `
  ${H1}
  font-weight: 500;
`

export const H1_SEMIBOLD = `
  ${H1}
  font-weight: 600;
`

export const H1_BOLD = `
  ${H1}
  font-weight: 700;
`

//HeadLine H2

export const H2 = `
  ${CommonTypographyStyle}
  font-size: 30px;
  line-height: 38px;
`

export const H2_REGULAR = `
  ${H2}
  font-weight: 400;
`

export const H2_MEDIUM = `
  ${H2}
  font-weight: 500;
`

export const H2_SEMIBOLD = `
  ${H2}
  font-weight: 600;
`

export const H2_BOLD = `
  ${H2}
  font-weight: 700;
`

//HeadLine H2

export const H3 = `
  ${CommonTypographyStyle}
  font-size: 24px;
  line-height: 32px;
`

export const H3_REGULAR = `
  ${H3}
  font-weight: 400;
`

export const H3_MEDIUM = `
  ${H3}
  font-weight: 500;
`

export const H3_SEMIBOLD = `
  ${H3}
  font-weight: 600;
`

export const H3_BOLD = `
  ${H3}
  font-weight: 700;
`

//Text Extra Small

export const TEXT_XS = `
  ${CommonTypographyStyle}
  font-size: 10px;
  line-height: 14px;
`

export const TEXT_XS_REGULAR = `
  ${TEXT_XS}
  font-weight: 400;
`

export const TEXT_XS_MEDIUM = `
  ${TEXT_XS}
  font-weight: 500;
`

export const TEXT_XS_SEMIBOLD = `
  ${TEXT_XS}
  font-weight: 600;
`

//Text Small

export const TEXT_SM = `
  ${CommonTypographyStyle}
  font-size: 12px;
  line-height: 18px;
`

export const TEXT_SM_REGULAR = `
  ${TEXT_SM}
  font-weight: 400;
`

export const TEXT_SM_MEDIUM = `
  ${TEXT_SM}
  font-weight: 500;
`

export const TEXT_SM_BOLD = `
  ${TEXT_SM}
  font-weight: 700;
`

//Text Medium

export const TEXT_M = `
  ${CommonTypographyStyle}
  font-size: 14px;
  line-height: 24px;
`

export const TEXT_M_REGULAR = `
  ${TEXT_M}
  font-weight: 400;
`

export const TEXT_M_MEDIUM = `
  ${TEXT_M}
  font-weight: 500;
`

export const TEXT_M_SEMIBOLD = `
  ${TEXT_M}
  font-weight: 600;
`

export const TEXT_M_BOLD = `
  ${TEXT_M}
  font-weight: 700;
`

//Text Large

export const TEXT_LG = `
  ${CommonTypographyStyle}
  font-size: 16px;
  line-height: 24px;
`

export const TEXT_LG_REGULAR = `
  ${TEXT_LG}
  font-weight: 400;
`

export const TEXT_LG_MEDIUM = `
  ${TEXT_LG}
  font-weight: 500;
`

export const TEXT_LG_SEMIBOLD = `
  ${TEXT_LG}
  font-weight: 600;
`

//Text Extra Large
export const TEXT_XL = `
  ${CommonTypographyStyle}
  font-size: 18px;
  line-height: 28px;
`

export const TEXT_XL_REGULAR = `
  ${TEXT_XL}
  font-weight: 400;
`

export const TEXT_XL_MEDIUM = `
  ${TEXT_XL}
  font-weight: 500;
`

// global typography

const CommonStyle = `
  text-align: center;
  letter-spacing: -0.02em;
  font-style: normal;
`

export const H4 = `
  ${CommonStyle}
  font-weight: 700;
  font-size: 32px;
  line-height: 48px;
`
export const H5 = `
  ${CommonStyle}
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
`
export const H5_light = `
  ${CommonStyle}
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
`
//B1 20/30
export const B1_Large_Regular = `
  ${CommonStyle}
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
`
export const B1_Large_Medium = `
  ${CommonStyle}
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
`
export const B1_Large_Semibold = `
  ${CommonStyle}
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
`
export const B1_Medium_Regular = `
  ${CommonStyle}
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
`
export const B1_Medium_Medium = `
  ${CommonStyle}
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
`
export const B1_Medium_Semibold = `
  ${CommonStyle}
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
`
export const B2_Large_Regular = `
  ${CommonStyle}
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
`
export const B2_Large_Medium = `
  ${CommonStyle}
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
`
export const B2_Large_Semibold = `
  ${CommonStyle}
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
`
export const B2_Medium_Regular = `
  ${CommonStyle}
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
`
export const B2_Medium_Medium = `
  ${CommonStyle}
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
`
export const B2_Medium_Semibold = `
  ${CommonStyle}
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
`
export const C1_Regular = `
  ${CommonStyle}
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
`
export const C1_Medium = `
  ${CommonStyle}
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
`
export const C1_Semibold = `
  ${CommonStyle}
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
`
export const C2_Regular = `
  ${CommonStyle}
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
`
export const C2_Medium = `
  ${CommonStyle}
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
`
export const C2_Semibold = `
  ${CommonStyle}
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
`
export const C3_Regular = `
  ${CommonStyle}
  font-weight: 400;
  font-size: 10px;
  line-height: 16px;
`
export const C3_Medium = `
  ${CommonStyle}
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;
`
export const C3_Semibold = `
  ${CommonStyle}
  font-weight: 600;
  font-size: 10px;
  line-height: 16px;
`
export const C4_Medium = `
  ${CommonStyle}
  font-weight: 500;
  font-size: 10px;
  line-height: 14px;
`
