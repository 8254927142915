import axiosInstance from 'api/axios'
import { AxiosResponse } from 'axios'
import { ClientSourceKeysEndpoints } from 'api/endpoints/connector'
import { IFilterClientSourceKeys } from '../types'

export function* getClientSourceKeysReq(filterData: IFilterClientSourceKeys) {
  const response: AxiosResponse = yield axiosInstance.get(
    ClientSourceKeysEndpoints.clientSourceKeysList(filterData),
  )
  return response
}
