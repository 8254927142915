import axiosInstance from 'api/axios'
import { AxiosResponse } from 'axios'
import { OauthsEndpoints } from 'api/endpoints/connector'
import { IFilterOauths } from '../types'

export function* getOauthsReq(filterData: IFilterOauths) {
  const response: AxiosResponse = yield axiosInstance.get(
    OauthsEndpoints.oauthsList(filterData),
  )
  return response
}
