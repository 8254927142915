import { FC, useEffect, useState } from 'react'
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom'
import { ThreeDotLoadingIndicator } from 'app/components/common/loading/ThreeDotLoadingIndicator/threeDotLoading'
interface Props {
  children: any
}
export const PrivateRoute: FC<Props> = ({ children }) => {
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const [isAuthenticated, setIsAuthenticated] = useState(false)

  // add loading state, initially true for initial render
  const [isLoading, setIsLoading] = useState(true)

  //FIXME: the logic of the private route based on the logic of the project may be changed
  const token = localStorage.getItem('AUTH_ACCESS_TOKEN')

  const checkAuth = async () => {
    setIsLoading(true)
    // <-- set true when starting auth check
    try {
      const token = localStorage.getItem('AUTH_ACCESS_TOKEN')

      if (token) {
        setIsAuthenticated(true)
      }
    } catch (e) {
      console.error(e)
      setIsAuthenticated(false)
    } finally {
      setIsLoading(false)
      // <-- clear loading state when completed
    }
  }

  useEffect(() => {
    checkAuth()
    // <-- check auth status on mount/when location changes
  }, [pathname, navigate])

  if (isLoading) {
    return <ThreeDotLoadingIndicator fullPageHeight />
  }

  let output

  output = children || <Outlet />
  return token ? output : <Navigate to={'/login'} replace />
}
