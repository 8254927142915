import { baseURLPrefix } from 'app/constants'
import { IFilterAssetsManagement } from 'app/containers/AssetsManagement/types'
import { IFilterBotOrders } from 'app/containers/BotOrders/types'
import { IFilterConfigurations } from 'app/containers/Configurations/types'
import { constructQueryParamsFromObject } from 'utils/objects'

export const AssetsManagementEndpoints = {
  assetsManagementList: (queryParams: IFilterAssetsManagement) =>
    `${baseURLPrefix}asset-management/assets-management${constructQueryParamsFromObject(queryParams)}`,
}

export const ConfigurationsEndpoints = {
  configurationsList: (queryParams: IFilterConfigurations) =>
    `${baseURLPrefix}asset-management/configurations${constructQueryParamsFromObject(queryParams)}`,
  setNewConfiguration: () =>
    `${baseURLPrefix}asset-management/configuration/upsert`,
}

export const BotOrdersEndpoints = {
  botOrdersList: (queryParams: IFilterBotOrders) =>
    `${baseURLPrefix}asset-management/bot-orders${constructQueryParamsFromObject(queryParams)}`,
}
