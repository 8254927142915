import { createSelector } from '@reduxjs/toolkit'

import { RootState } from 'store/types'
import { initialState } from './slice'

export const submissionsDomains = {
  root: (state: RootState) => state.submissions || initialState,
  submitted: (state: RootState) => state.submissions.submitted,
  invalidated: (state: RootState) => state.submissions.invalidated,
  isLoadingSubmissions: (state: RootState) =>
    state.submissions.isLoadingSubmissions,
  confirmDeleteModalItem: (state: RootState) =>
    state.submissions.confirmDeleteModalItem,
  isInvalidating: (state: RootState) => state.submissions.isInvalidating,
}

export const submissionsSelectors = {
  root: createSelector(submissionsDomains.root, root => root),
  submitted: createSelector(
    submissionsDomains.submitted,
    submitted => submitted,
  ),
  invalidated: createSelector(
    submissionsDomains.invalidated,
    invalidated => invalidated,
  ),
  isLoadingSubmissions: createSelector(
    submissionsDomains.isLoadingSubmissions,
    isLoading => isLoading,
  ),
  confirmDeleteModalItem: createSelector(
    submissionsDomains.confirmDeleteModalItem,
    confirmDeleteModalItem => confirmDeleteModalItem,
  ),
  getSubmittedByTransactionHash: (transactionHash: string) =>
    createSelector(submissionsDomains.submitted, submittedEvents => {
      {
        return submittedEvents.find(
          submissions => submissions.transactionHash === transactionHash,
        )
      }
    }),
  isInvalidating: createSelector(
    submissionsDomains.isInvalidating,
    isInvalidating => isInvalidating,
  ),
}
