import { ColumnOfTable } from 'app/components/table/sortableTable'
import { getTableSettingsFromStorage } from 'utils/storage'

export const RECORDS_TABLE_KEY = 'recordsTable'

export const columnsForShowInRecordsTable: ColumnOfTable[] = [
  { id: 'failureType', label: 'Failure Type' },
  { id: 'userCoreID', label: 'User CoreID' },
  { id: 'orderID', label: 'Order ID' },
  { id: 'txHash', label: 'TxHash' },
  { id: 'clientDomainUUID', label: 'Client DomainUUID' },
  { id: 'createdAt', label: 'Created At', isDate: true },
  { id: 'updatedAt', label: 'Updated At', isDate: true },
]

export const {
  pageSize: localPageSize,
  sortType: localSortType,
  sortField: localSortField,
} = getTableSettingsFromStorage({ tableKey: RECORDS_TABLE_KEY })

export const FAILURE_TYPE_OPTIONS = [
  'INVALID_CONFIRM_DATA',
  'FAILED_TO_MINED',
  'UNKNOWN',
  'PENDING_FOR_LONG_TIME',
]
