import { PayloadAction } from '@reduxjs/toolkit'
import { TransmissionsState } from './types'
import { createSlice } from 'store/toolkit'
import { useInjectReducer, useInjectSaga } from 'store/redux-injectors'

import { transmissionsSaga } from './saga'
import { localPageSize, localSortField, localSortType } from './constants'

export const initialState: TransmissionsState = {
  transmissionsList: [],
  isLoadingTransmissions: false,
  filterTransmissions: {
    page: 0,
    pageSize: localPageSize ? localPageSize : 100,
    sortField: localSortField,
    sortType: localSortType,
  },
  lastPageOfTransmissionsTable: 0,
  isAllTransmissionsFetched: false,
  displayFilterTransmissionsModal: false,
}

const transmissionsSlice = createSlice({
  name: 'transmissions',
  initialState,
  reducers: {
    getTransmissionsList(state) {},
    setTransmissions(
      state,
      action: PayloadAction<TransmissionsState['transmissionsList']>,
    ) {
      state.transmissionsList = action.payload
    },
    setIsLoadingTransmissions(
      state,
      action: PayloadAction<TransmissionsState['isLoadingTransmissions']>,
    ) {
      state.isLoadingTransmissions = action.payload
    },
    setFilterTransmissions(
      state,
      action: PayloadAction<TransmissionsState['filterTransmissions']>,
    ) {
      state.filterTransmissions = action.payload
    },
    setLastPageOfTransmissionsTable(state, action: PayloadAction<number>) {
      state.lastPageOfTransmissionsTable = action.payload
    },
    setIsAllTransmissionsFetched(state, action: PayloadAction<boolean>) {
      state.isAllTransmissionsFetched = action.payload
    },
    setDisplayFilterTransmissionsModal(state, action: PayloadAction<boolean>) {
      state.displayFilterTransmissionsModal = action.payload
    },
  },
})

export const {
  actions: transmissionsActions,
  reducer: transmissionsReducer,
  name: sliceKey,
} = transmissionsSlice

export const useTransmissionsSlice = () => {
  useInjectReducer({ key: sliceKey, reducer: transmissionsReducer })
  useInjectSaga({ key: sliceKey, saga: transmissionsSaga })
  return { transmissionsActions: transmissionsActions }
}
