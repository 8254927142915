/* eslint-disable react-hooks/rules-of-hooks */
import { PayloadAction } from '@reduxjs/toolkit'
import { IErrorResponse, IPayment, PaymentsState } from './types'
import { createSlice } from 'store/toolkit'
import { useInjectReducer, useInjectSaga } from 'store/redux-injectors'
import { paymentsSaga } from './saga'
import { localPageSize, localSortField, localSortType } from './constants'

export const initialState: PaymentsState = {
  isLoadingPayments: false,
  displayPaymentModal: false,
  error: null,
  paymentList: [],
  filterPayments: {
    page: 0,
    pageSize: localPageSize ? localPageSize : 100,
    sortField: localSortField,
    sortType: localSortType,
    coreID: '',
  },
  lastPageOfPaymentsTable: 0,
  isAllPaymentsFetched: false,
}

const paymentSlice = createSlice({
  name: 'payments',
  initialState,
  reducers: {
    setIsLoadingPayments(state, action: PayloadAction<boolean>) {
      state.isLoadingPayments = action.payload
    },
    setPaymentsError(state, action: PayloadAction<IErrorResponse>) {
      state.error = action.payload
    },
    setDisplayPaymentModal(state, action: PayloadAction<boolean>) {
      state.displayPaymentModal = action.payload
    },
    getPayments(state) {},
    setPayments(state, action: PayloadAction<IPayment[]>) {
      state.paymentList = action.payload
    },
    setFilterPayments(
      state,
      action: PayloadAction<PaymentsState['filterPayments']>,
    ) {
      state.filterPayments = action.payload
    },
    setLastPageOfPaymentsTable(state, action: PayloadAction<number>) {
      state.lastPageOfPaymentsTable = action.payload
    },
    setIsAllPaymentsFetched(state, action: PayloadAction<boolean>) {
      state.isAllPaymentsFetched = action.payload
    },
  },
})

export const {
  actions: paymentsActions,
  reducer: paymentsReducer,
  name: sliceKey,
} = paymentSlice

export const usePaymentsSlice = () => {
  useInjectReducer({ key: sliceKey, reducer: paymentsReducer })
  useInjectSaga({ key: sliceKey, saga: paymentsSaga })
  return { paymentActions: paymentsActions }
}
