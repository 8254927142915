import { styled } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import { useDispatch, useSelector } from 'react-redux'
import { botOrdersSelectors } from '../selectors'
import { botOrdersActions } from '../slice'
import {
  FilterChipsInHeader,
  IShowablefilterData,
} from 'app/components/common/FilterChipsInHeader'
import { PageHeader } from 'app/components/common/globals/pageHeader'
import PrimaryButton from 'app/components/button/primaryButton'

export const BotOrdersHeader = () => {
  const dispatch = useDispatch()

  const filterBotOrders = useSelector(botOrdersSelectors.filterBotOrders)
  const displayFilterBotOrdersModal = useSelector(
    botOrdersSelectors.displayFilterBotOrdersModal,
  )

  const handleOpenFilterModal = () => {
    dispatch(botOrdersActions.setDisplayFilterBotOrdersModal(true))
  }

  const showablefilterBotOrders: IShowablefilterData[] = [
    { label: 'Status', value: filterBotOrders?.status },
    { label: 'Type', value: filterBotOrders?.type },
    { label: 'Price', value: filterBotOrders?.price },
    { label: 'Amount', value: filterBotOrders?.amount },
    { label: 'Symbol', value: filterBotOrders?.symbol },
    {
      label: 'CreatedAt From',
      value: filterBotOrders?.createdAtFrom,
      isDate: true,
    },
    {
      label: 'CreatedAt To',
      value: filterBotOrders?.createdAtTo,
      isDate: true,
    },
    {
      label: 'UpdateAt From',
      value: filterBotOrders?.updateAtFrom,
      isDate: true,
    },
    { label: 'UpdateAt To', value: filterBotOrders?.updateAtTo, isDate: true },
  ]

  const handleDelete = (label: string) => {
    dispatch(
      botOrdersActions.setFilterBotOrders({
        ...filterBotOrders,
        [label]: undefined,
      }),
    )
    dispatch(botOrdersActions.getBotOrders())
  }

  return (
    <>
      <PageHeader title="Bot Orders">
        <PrimaryButton
          onClick={handleOpenFilterModal}
          endIcon={<StyledSearchIcon />}
        >
          Search
        </PrimaryButton>
      </PageHeader>
      <FilterSection>
        {!displayFilterBotOrdersModal && (
          <FilterChipsInHeader
            showableFilter={showablefilterBotOrders}
            deleteFilter={handleDelete}
          />
        )}
      </FilterSection>
    </>
  )
}

const FilterSection = styled('div')`
  display: flex;
  gap: 8px;
  width: 100%;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
`
const StyledSearchIcon = styled(SearchIcon)`
  color: var(--white);
`
