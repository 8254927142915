import axiosInstance from 'api/axios'
import { AxiosResponse } from 'axios'
import { IFilterEmailRejections } from '../types'
import { EmailRejectionsEndpoints } from 'api/endpoints/verifications'

export function* getEmailRejectionsReq(filterData: IFilterEmailRejections) {
  const response: AxiosResponse = yield axiosInstance.get(
    EmailRejectionsEndpoints.emailRejectionsList(filterData),
  )
  return response
}
