import axiosInstance from 'api/axios'
import { AxiosResponse } from 'axios'
import { ClientsEndpoints } from 'api/endpoints/connector'
import { IFilterClients } from '../types'

export function* getClientsReq(filterData: IFilterClients) {
  const response: AxiosResponse = yield axiosInstance.get(
    ClientsEndpoints.clientsList(filterData),
  )
  return response
}
