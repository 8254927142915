import { DocumentRejectionsHeader } from './components/Header'
import { FilterDocumentRejectionsModal } from './components/modals/filter'
import { DocumentRejectionsTable } from './components/table'

export function DocumentRejections() {
  return (
    <>
      <DocumentRejectionsHeader />
      <DocumentRejectionsTable />
      <FilterDocumentRejectionsModal />
    </>
  )
}
