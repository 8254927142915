import { PhoneInvalidationsHeader } from './components/Header'
import { FilterPhoneInvalidationsModal } from './components/modals/filter'
import { PhoneInvalidationsTable } from './components/table'

export function PhoneInvalidations() {
  return (
    <>
      <PhoneInvalidationsHeader />
      <PhoneInvalidationsTable />
      <FilterPhoneInvalidationsModal />
    </>
  )
}
