import { Header } from './components/Header'
import { FilterOrganizationsModal } from './components/modals/filter'
import { TableOfData } from './components/table'

export function Organizations() {
  return (
    <>
      <Header />
      <TableOfData />
      <FilterOrganizationsModal />
    </>
  )
}
