import { EmailVerificationsTable } from './components/table'
import { EmailVerificationsHeader } from './components/Header'
import { FilterEmailVerificationsModal } from './components/modals/filter'

export function EmailVerifications() {
  return (
    <>
      <EmailVerificationsHeader />
      <EmailVerificationsTable />
      <FilterEmailVerificationsModal />
    </>
  )
}
