import { PayloadAction } from '@reduxjs/toolkit'
import { ContractNames } from 'app/constants'
import { EventLog } from 'corebc'
import { createSlice } from 'store/toolkit'
export interface IGlobalState {
  loggedIn: boolean
  unauthorized: boolean
  contractAddresses: {
    [key: string]: string
  }
  kycVault: {
    submitted: KycVaultSubmitted
    invalidated: EventLog[]
  }
  isLoadingContractAddresses: boolean
}

export interface KycVaultSubmitted {
  address: EventLog[]
  idCard: EventLog[]
  passport: EventLog[]
  residencePermit: EventLog[]
  driverLicense: EventLog[]
  email: EventLog[]
  phone: EventLog[]
}

export interface Submission {
  role: string
  expiration: number
  user: string
  removed: boolean
  invalidated: boolean
}

export interface contractAdressesType {
  IDCardVerifier: string
}

// The initial state of the LoginPage container
export const initialState: IGlobalState = {
  loggedIn: false,
  unauthorized: false,
  contractAddresses: Object.values(ContractNames).reduce(
    (acc, contractName) => {
      acc[contractName] = ''
      return acc
    },
    {} as any,
  ),
  kycVault: {
    submitted: {
      address: [],
      idCard: [],
      passport: [],
      residencePermit: [],
      driverLicense: [],
      email: [],
      phone: [],
    },
    invalidated: [],
  },
  isLoadingContractAddresses: false,
}

const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    setIsLoggedIn(state, action: PayloadAction<boolean>) {
      state.loggedIn = action.payload
      if (action.payload === false) {
        localStorage.clear()
      }
    },
    setUnauthorized(state, action: PayloadAction<boolean>) {
      state.unauthorized = action.payload
    },
    fetchContractAddresses() {},
    setContractAddresses(
      state,
      action: PayloadAction<{
        contractName: string
        contractAddress: string
      }>,
    ) {
      state.contractAddresses[action.payload.contractName] =
        action.payload.contractAddress
    },
    fetchAllKYCValutSubmissions(
      state,
      action: PayloadAction<{ kycVaultcontractAddress: string }>,
    ) {},
    setSubmittedSubmissions(state, action: PayloadAction<KycVaultSubmitted>) {
      //@ts-ignore
      state.kycVault.submitted = {
        ...state.kycVault.submitted,
        ...action.payload,
      }
    },
    setInvalidatedSubmissions(state, action: PayloadAction<EventLog[]>) {
      //@ts-ignore
      state.kycVault.invalidated = action.payload
    },
    setIsLoadingContractAddresses(state, action: PayloadAction<boolean>) {
      state.isLoadingContractAddresses = action.payload
    },
  },
})

export const {
  actions: globalActions,
  reducer: globalReducer,
  name: sliceKey,
} = globalSlice
