import { Header } from './components/Header'
import { FilterOauthsModal } from './components/modals/filter'
import { TableOfData } from './components/table'

export function Oauths() {
  return (
    <>
      <Header />
      <TableOfData />
      <FilterOauthsModal />
    </>
  )
}
