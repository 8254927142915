import axiosInstance from 'api/axios'
import { AxiosResponse } from 'axios'
import { IFilterBotOrders } from '../types'
import { BotOrdersEndpoints } from 'api/endpoints/ping'

export function* getBotOrdersReq(filterData: IFilterBotOrders) {
  const response: AxiosResponse = yield axiosInstance.get(
    BotOrdersEndpoints.botOrdersList(filterData),
  )
  return response
}
