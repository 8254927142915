import { useEffect } from 'react'
import { UnAuthorizedModal } from './components/modal'
import { useLocation } from 'react-router-dom'
import { AppPages } from 'app/constants'
import { getApiKeyFromStorage } from 'api/axios'
import { useDispatch, useSelector } from 'react-redux'
import { globalActions } from 'store/slice'
import { globalSelectors } from './selector'

function InitializeApp() {
  const dispatch = useDispatch()
  const [authToken] = getApiKeyFromStorage()
  const { pathname } = useLocation()
  const contractAddresses = useSelector(globalSelectors.contractAddresses)

  useEffect(() => {
    if (authToken) {
      dispatch(globalActions.fetchContractAddresses())
    }
  }, [authToken])
  // uncomment this if we want to get the submissions in startup
  // useEffect(() => {
  //   if (authToken && contractAddresses?.KYCVault) {
  //     dispatch(submissionsActions.fetchAllInvalidatedSubmissions())
  //   }
  // }, [contractAddresses?.KYCVault])

  return pathname !== AppPages.Login ? <UnAuthorizedModal /> : null
}

export default InitializeApp
