import { useDispatch, useSelector } from 'react-redux'
import AddIcon from '@mui/icons-material/Add'
import { adminsActions } from './slice'
import { adminsSelectors } from './selectors'
import { useEffect } from 'react'
import { PageLoading } from 'app/components/common/loading/pageLoading'
import { DeleteConfirmationModal } from './components/modals/deleteConfirmation'
import { Admin } from './types'
import AdminCard from './components/card/adminCard'
import { rolesActions } from '../Roles/slice'
import { AddAdminModal } from './components/modals/addAdmin'
import PrimaryButton from 'app/components/button/primaryButton'
import { mediaQueries } from 'styles/mediaQueries'
import { styled } from '@mui/material'
import { PageHeader } from 'app/components/common/globals/pageHeader'

export function Admins() {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(adminsActions.fetchAdmins({}))
    dispatch(rolesActions.fetchRoles({}))
  }, [])

  const adminsList = useSelector(adminsSelectors.adminList)
  const isLoadingAdmins = useSelector(adminsSelectors.isLoadingAdmins)

  const handleAddClick = () => {
    dispatch(adminsActions.setAddAdminModalDisplay(true))
  }

  return (
    <>
      <DeleteConfirmationModal />
      <AddAdminModal />

      {isLoadingAdmins ? (
        <PageLoading />
      ) : (
        <>
          <PageHeader title="Admins">
            <PrimaryButton onClick={handleAddClick} endIcon={<StyledAddIcon />}>
              Add New Admin
            </PrimaryButton>
          </PageHeader>

          <AdminsContainer>
            {adminsList?.map((admin: Admin, index) => {
              return <AdminCard key={index} admin={admin} />
            })}
          </AdminsContainer>
        </>
      )}
    </>
  )
}

export const AdminsContainer = styled('div')`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(420px, 1fr));
  width: 100%;
  gap: 1rem;
  ${mediaQueries.lessThan('xs')`
   grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
   gap: 0.5rem;
  `}
`
const StyledAddIcon = styled(AddIcon)`
  color: var(--white);
`
