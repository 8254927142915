import { createSelector } from '@reduxjs/toolkit'

import { RootState } from 'store/types'

export const globalDomains = {
  root: (state: RootState) => state.global,
  contractAddresses: (state: RootState) => state.global?.contractAddresses,
  kycVault: (state: RootState) => state.global.kycVault,
  isLoadingContractAddresses: (state: RootState) =>
    state.global.isLoadingContractAddresses,
}

export const globalSelectors = {
  root: createSelector(globalDomains.root, root => root),
  contractAddresses: createSelector(
    globalDomains.contractAddresses,
    rolesList => rolesList,
  ),
  kycVault: createSelector(globalDomains.kycVault, kycVault => kycVault),
  submittedKyvVault: createSelector(globalDomains.kycVault, kycVault => {
    // concat all the arrays of the submitted kyc vault and add the key as the type of the submission
    const submitted = Object.keys(kycVault.submitted).reduce((acc, key) => {
      return acc.concat(
        //@ts-ignore
        kycVault.submitted[key].map(submission => ({
          ...submission,
          type: key,
        })),
      )
    }, [])
    return submitted
  }),
  invalidatedKycVault: createSelector(
    globalDomains.kycVault,
    kycVault => kycVault.invalidated,
  ),
  isLoadingContractAddresses: createSelector(
    globalDomains.isLoadingContractAddresses,
    isLoading => isLoading,
  ),
}
