import { transmissionsActions } from '../slice'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { transmissionsSelectors } from '../selectors'
import { styled } from '@mui/material'
import {
  TRANSMISSIONS_TABLE_KEY,
  columnsForShowInTransmissionsTable,
} from '../constants'
import SortableTable from 'app/components/table/sortableTable'
import { capitalizeAllCharacters } from 'utils/string'
import { redirectToBlockBox } from 'utils/redirections'
import { ISortType } from 'app/types'
import { MAX_SAFE_INTEGER } from 'app/constants'

export const TableOfData = () => {
  const dispatch = useDispatch()

  const transmissionsList = useSelector(
    transmissionsSelectors.transmissionsList,
  )
  const isLoadingTransmissions = useSelector(
    transmissionsSelectors.isLoadingTransmissions,
  )
  const filterTransmissions = useSelector(
    transmissionsSelectors.filterTransmissions,
  )
  const lastPageOfTransmissionsTable = useSelector(
    transmissionsSelectors.lastPageOfTransmissionsTable,
  )
  const isAllTransmissionsFetched = useSelector(
    transmissionsSelectors.isAllTransmissionsFetched,
  )

  useEffect(() => {
    dispatch(transmissionsActions.getTransmissionsList())
  }, [])

  const handleSetNumberRowsPerPage = (num: number) => {
    const tablePageSizeTitle = `${TRANSMISSIONS_TABLE_KEY}_pageSize`
    localStorage.setItem(tablePageSizeTitle, num.toString())
    dispatch(
      transmissionsActions.setFilterTransmissions({
        ...filterTransmissions,
        pageSize: num,
      }),
    )
    dispatch(transmissionsActions.getTransmissionsList())
  }

  return (
    <Wrapper>
      <SortableTable
        name={TRANSMISSIONS_TABLE_KEY}
        isLoading={isLoadingTransmissions}
        data={transmissionsList}
        columns={columnsForShowInTransmissionsTable}
        handleChangeLastPage={(newPage: number) => {
          dispatch(
            transmissionsActions.setFilterTransmissions({
              ...filterTransmissions,
              page: newPage,
            }),
          )

          dispatch(transmissionsActions.getTransmissionsList())
        }}
        setPage={page =>
          dispatch(transmissionsActions.setLastPageOfTransmissionsTable(page))
        }
        page={lastPageOfTransmissionsTable}
        setNumberRowsPerPage={num => handleSetNumberRowsPerPage(num)}
        handleCustomRequestSort={(property, order) => {
          dispatch(
            transmissionsActions.setFilterTransmissions({
              ...filterTransmissions,
              sortField: property,
              sortType: capitalizeAllCharacters(order) as ISortType,
            }),
          )
          const oneRow = transmissionsList[0]
          if (oneRow) {
            const propertyType = typeof oneRow[property as keyof typeof oneRow]
            if (propertyType === 'number') {
              dispatch(transmissionsActions.getTransmissionsList())
            }
          }
        }}
        showNumberOfPages={false}
        countOfData={MAX_SAFE_INTEGER}
        disableNextButton={isAllTransmissionsFetched}
        onCellClick={(row, column) => {
          if (column === 'confirmTxHash' || column === 'initiateTxHash') {
            redirectToBlockBox(row[column])
          }
        }}
      />
    </Wrapper>
  )
}

const Wrapper = styled('div')`
  justify-content: center;
  grid-gap: 10px 0;
  margin-top: 20px;
  margin-bottom: 20px;
  min-width: 0;
`
