import axiosInstance from 'api/axios'
import { AxiosResponse } from 'axios'
import { IFilterResubmissions } from '../types'
import { ResubmissionsEndpoints } from 'api/endpoints/verifications'

export function* getResubmissionUsersWithFilter(
  filterData: IFilterResubmissions,
) {
  const response: AxiosResponse = yield axiosInstance.get(
    ResubmissionsEndpoints.resubmissionList(filterData),
  )
  return response.data
}
