import { styled } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import { useDispatch, useSelector } from 'react-redux'
import { paymentsActions } from '../slice'
import { paymentsSelectors } from '../selectors'
import {
  FilterChipsInHeader,
  IShowablefilterData,
} from 'app/components/common/FilterChipsInHeader'
import { PageHeader } from 'app/components/common/globals/pageHeader'
import PrimaryButton from 'app/components/button/primaryButton'

export const Header = () => {
  const dispatch = useDispatch()
  const displayPaymentModal = useSelector(paymentsSelectors.displayPaymentModal)
  const filterPayments = useSelector(paymentsSelectors.filterPayments)

  const showAbleFilterPayments: IShowablefilterData[] = [
    { label: 'coreID', value: filterPayments?.coreID },
    { label: 'OrderID', value: filterPayments?.orderID },
    { label: 'Status', value: filterPayments?.status },
    {
      label: 'PaymentProviderCode',
      value: filterPayments?.paymentProviderCode,
    },
    {
      label: 'CreatedAt From',
      value: filterPayments?.createdAtFrom,
      isDate: true,
    },
    {
      label: 'CreatedAt To',
      value: filterPayments?.createdAtTo,
      isDate: true,
    },
    {
      label: 'UpdateAt From',
      value: filterPayments?.updateAtFrom,
      isDate: true,
    },
    { label: 'UpdateAt To', value: filterPayments?.updateAtTo, isDate: true },
  ]

  const handleOpenPaymentModal = () =>
    dispatch(paymentsActions.setDisplayPaymentModal(true))

  const handleDelete = (label: string) => {
    dispatch(
      paymentsActions.setFilterPayments({
        ...filterPayments,
        [label]: undefined,
      }),
    )
    dispatch(paymentsActions.getPayments())
  }

  return (
    <>
      <PageHeader title="Payments">
        <PrimaryButton
          onClick={handleOpenPaymentModal}
          endIcon={<StyledSearchIcon />}
        >
          Search
        </PrimaryButton>
      </PageHeader>
      <FilterSection>
        {!displayPaymentModal && (
          <FilterChipsInHeader
            showableFilter={showAbleFilterPayments}
            deleteFilter={handleDelete}
          />
        )}
      </FilterSection>
    </>
  )
}

const FilterSection = styled('div')`
  display: flex;
  gap: 8px;
  width: 100%;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
`
const StyledSearchIcon = styled(SearchIcon)`
  color: var(--white);
`
