import { EmailInvalidationsHeader } from './components/Header'
import { FilterEmailInvalidationsModal } from './components/modals/filter'
import { EmailInvalidationsTable } from './components/table'

export function EmailInvalidations() {
  return (
    <>
      <EmailInvalidationsHeader />
      <EmailInvalidationsTable />
      <FilterEmailInvalidationsModal />
    </>
  )
}
