import { documentInvalidationsActions } from '../slice'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { documentInvalidationsSelectors } from '../selectors'
import { styled } from '@mui/material'
import {
  DOCUMENT_INVALIDATIONS_TABLE_KEY,
  columnsForShowInDocumentInvalidationsTable,
} from '../constants'
import SortableTable from 'app/components/table/sortableTable'
import { capitalizeAllCharacters } from 'utils/string'
import { useLocation } from 'react-router-dom'
import { searchUserSelectors } from 'app/containers/SearchUser/selector'
import { AppPages, MAX_SAFE_INTEGER } from 'app/constants'
import { redirectToBlockBox } from 'utils/redirections'
import { ISortType } from 'app/types'

export const DocumentInvalidationsTable = () => {
  const dispatch = useDispatch()

  const documentInvalidationsList = useSelector(
    documentInvalidationsSelectors.documentInvalidationsList,
  )
  const isLoadingDocumentInvalidations = useSelector(
    documentInvalidationsSelectors.isLoadingDocumentInvalidations,
  )
  const filterDocumentInvalidationsData = useSelector(
    documentInvalidationsSelectors.filterDocumentInvalidations,
  )
  const lastPageOfDocumentInvalidationsTable = useSelector(
    documentInvalidationsSelectors.lastPageOfDocumentInvalidationsTable,
  )
  const isAllDocumentInvalidationsFetched = useSelector(
    documentInvalidationsSelectors.isAllDocumentInvalidationsFetched,
  )
  const searchUserCoreId = useSelector(searchUserSelectors.coreId)
  const { pathname } = useLocation()

  useEffect(() => {
    if (pathname === AppPages.SearchUser) {
      // add coreId to filterData if the user is coming from search user page
      // and the search term (coreId) is not empty
      // if the user is coming from search user page and the search term (coreId) is empty
      // remove coreId filter and get all orders

      const searchUserFilters = {
        ...filterDocumentInvalidationsData,
        userCoreID: searchUserCoreId ? searchUserCoreId : '',
      }
      dispatch(
        documentInvalidationsActions.setFilterDocumentInvalidationsData(
          searchUserFilters,
        ),
      )
    }
    dispatch(documentInvalidationsActions.getDocumentInvalidations())
  }, [searchUserCoreId])

  const handleSetNumberRowsPerPage = (num: number) => {
    const tablePageSizeTitle = `${DOCUMENT_INVALIDATIONS_TABLE_KEY}_pageSize`
    localStorage.setItem(tablePageSizeTitle, num.toString())
    dispatch(
      documentInvalidationsActions.setFilterDocumentInvalidationsData({
        ...filterDocumentInvalidationsData,
        pageSize: num,
      }),
    )
    dispatch(documentInvalidationsActions.getDocumentInvalidations())
  }

  return (
    <Wrapper>
      <SortableTable
        name={DOCUMENT_INVALIDATIONS_TABLE_KEY}
        isLoading={isLoadingDocumentInvalidations}
        data={documentInvalidationsList}
        columns={columnsForShowInDocumentInvalidationsTable}
        handleChangeLastPage={(newPage: number) => {
          dispatch(
            documentInvalidationsActions.setFilterDocumentInvalidationsData({
              ...filterDocumentInvalidationsData,
              page: newPage,
            }),
          )

          dispatch(documentInvalidationsActions.getDocumentInvalidations())
        }}
        setPage={page =>
          dispatch(
            documentInvalidationsActions.setLastPageOfDocumentInvalidationsTable(
              page,
            ),
          )
        }
        page={lastPageOfDocumentInvalidationsTable}
        setNumberRowsPerPage={num => handleSetNumberRowsPerPage(num)}
        handleCustomRequestSort={(property, order) => {
          dispatch(
            documentInvalidationsActions.setFilterDocumentInvalidationsData({
              ...filterDocumentInvalidationsData,
              sortField: property,
              sortType: capitalizeAllCharacters(order) as ISortType,
            }),
          )
          const oneRow = documentInvalidationsList[0]
          if (oneRow) {
            const propertyType = typeof oneRow[property as keyof typeof oneRow]
            if (propertyType === 'number') {
              dispatch(documentInvalidationsActions.getDocumentInvalidations())
            }
          }
        }}
        showNumberOfPages={false}
        countOfData={MAX_SAFE_INTEGER}
        disableNextButton={isAllDocumentInvalidationsFetched}
        onCellClick={(row, column) => {
          if (column === 'txHash') {
            redirectToBlockBox(row[column])
          }
        }}
      />
    </Wrapper>
  )
}

const Wrapper = styled('div')`
  justify-content: center;
  grid-gap: 10px 0;
  margin-top: 20px;
  margin-bottom: 20px;
  min-width: 0;
`
