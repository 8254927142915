import axiosInstance from 'api/axios'
import { AxiosResponse } from 'axios'
import { IFilterDocumentRejections } from '../types'
import { DocumentRejectionsEndpoints } from 'api/endpoints/verifications'

export function* getDocumentRejectionsReq(
  filterData: IFilterDocumentRejections,
) {
  const response: AxiosResponse = yield axiosInstance.get(
    DocumentRejectionsEndpoints.documentRejectionsList(filterData),
  )
  return response
}
