import { History } from 'history'
import { FC, ReactElement, useEffect, useLayoutEffect, useState } from 'react'
import { Navigate, Outlet, Route, Router, Routes } from 'react-router-dom'
import history from '../router/history'
import { AppPages, THIRTY_MINUTES } from './constants'
import { NotFoundPage } from './containers/NotFound'
import { Login } from './containers/Login/Loadable'
import { PrivateRoute } from 'router/privateRoute'
import InitializeApp from './containers/Initialize'
import { routes as appRoutes } from './routes'
import { AppLayout } from './components/common/layouts/appLayout'
import { useDispatch } from 'react-redux'
import { LocalStorageKeys } from 'store/storage'
import { loginActions } from './containers/Login/slice'

interface CustomRouterProps {
  history: History
  children?: ReactElement
}

const CustomRouter: FC<CustomRouterProps> = ({ history, ...props }) => {
  const dispatch = useDispatch()

  const [state, setState] = useState({
    action: history.action,
    location: history.location,
  })

  useLayoutEffect(() => history.listen(setState), [history])

  useEffect(() => {
    const interval = setInterval(() => {
      const accessToken = localStorage.getItem(
        LocalStorageKeys.AUTH_ACCESS_TOKEN,
      )
      const refreshToken = localStorage.getItem(
        LocalStorageKeys.AUTH_REFRESH_TOKEN,
      )
      if (accessToken && refreshToken) {
        dispatch(loginActions.setAuthCodeWithRefreshToken())
      }
    }, THIRTY_MINUTES)

    return () => {
      clearInterval(interval)
    }
  }, [dispatch, window.location.pathname]) //eslint-disable-line

  return (
    <Router
      {...props}
      location={state.location}
      navigationType={state.action}
      navigator={history}
    />
  )
}
export const NavLayout = () => (
  <>
    <AppLayout>
      <Outlet />
    </AppLayout>
  </>
)

function App() {
  return (
    <>
      <CustomRouter history={history}>
        <>
          <InitializeApp />
          <Routes>
            {/* Including SideBar */}
            <Route path={AppPages.Sidebar} element={<NavLayout />}>
              {/* FIXME: change the root url base on the ux of this project */}
              {/* Redirect the root URL to "/test" */}

              <Route
                path="/"
                element={<Navigate to="/resubmissions" replace />}
              />
              {appRoutes.map(route => (
                <Route
                  key={route.key}
                  path={route.path}
                  element={
                    <PrivateRoute>
                      <route.component />
                    </PrivateRoute>
                  }
                />
              ))}
            </Route>
            {/* No SideBar */}
            <Route path={AppPages.Login} element={<Login />} />
            <Route path={AppPages.LoginCallBack} element={<Login />} />
            <Route path={AppPages.NotFoundPage} element={<NotFoundPage />} />
          </Routes>
        </>
      </CustomRouter>
    </>
  )
}

export default App
