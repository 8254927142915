import { useDispatch, useSelector } from 'react-redux'
import { rolesActions } from './slice'
import AddIcon from '@mui/icons-material/Add'
import { rolesSelectors } from './selectors'
import { useEffect } from 'react'
import { PageLoading } from 'app/components/common/loading/pageLoading'
import RoleCard from 'app/containers/Roles/components/card/roleCard'
import { Role } from './types'
import { DeleteConfirmationModal } from './components/modals/confirmation'
import { AddRoleModal } from './components/modals/addRole'
import PrimaryButton from 'app/components/button/primaryButton'
import { PageHeader } from 'app/components/common/globals/pageHeader'
import { styled } from '@mui/material'

export function Roles() {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(rolesActions.fetchRoles({}))
    dispatch(rolesActions.fetchRoutes())
  }, [])

  const rolesList = useSelector(rolesSelectors.rolesList)
  const isLoadingRoles = useSelector(rolesSelectors.isLoadingRoles)

  const handleAddClick = () => {
    dispatch(rolesActions.setAddRoleModalDisplay(true))
  }

  return (
    <>
      <AddRoleModal />
      <DeleteConfirmationModal />
      <>
        {isLoadingRoles ? (
          <PageLoading />
        ) : (
          <>
            <PageHeader title="Roles">
              <PrimaryButton
                onClick={handleAddClick}
                endIcon={<StyledAddIcon />}
              >
                Add New Role
              </PrimaryButton>
            </PageHeader>
            {rolesList.map((role: Role) => {
              return <RoleCard key={role.id} roleId={role.id} />
            })}
          </>
        )}
      </>
    </>
  )
}

const StyledAddIcon = styled(AddIcon)`
  color: var(--white);
`
