import axiosInstance from 'api/axios'
import { AxiosResponse } from 'axios'
import { DomainsEndpoints } from 'api/endpoints/connector'
import { IFilterDomains } from '../types'

export function* getDomainsReq(filterData: IFilterDomains) {
  const response: AxiosResponse = yield axiosInstance.get(
    DomainsEndpoints.domainsList(filterData),
  )
  return response
}
