import { BotOrdersHeader } from './components/Header'
import { FilterBotOrdersModal } from './components/modals/filter'
import { BotOrdersTable } from './components/table'

export function BotOrders() {
  return (
    <>
      <BotOrdersHeader />
      <BotOrdersTable />
      <FilterBotOrdersModal />
    </>
  )
}
