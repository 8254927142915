import { PayloadAction } from '@reduxjs/toolkit'
import { DomainsState } from './types'
import { createSlice } from 'store/toolkit'
import { useInjectReducer, useInjectSaga } from 'store/redux-injectors'

import { domainsSaga } from './saga'
import { localPageSize, localSortField, localSortType } from './constants'

export const initialState: DomainsState = {
  domainsList: [],
  isLoadingDomains: false,
  filterDomains: {
    page: 0,
    pageSize: localPageSize ? localPageSize : 100,
    sortField: localSortField,
    sortType: localSortType,
  },
  lastPageOfDomainsTable: 0,
  isAllDomainsFetched: false,
  displayFilterDomainsModal: false,
}

const domainsSlice = createSlice({
  name: 'domains',
  initialState,
  reducers: {
    getDomainsList(state) {},
    setDomains(state, action: PayloadAction<DomainsState['domainsList']>) {
      state.domainsList = action.payload
    },
    setIsLoadingDomains(
      state,
      action: PayloadAction<DomainsState['isLoadingDomains']>,
    ) {
      state.isLoadingDomains = action.payload
    },
    setFilterDomains(
      state,
      action: PayloadAction<DomainsState['filterDomains']>,
    ) {
      state.filterDomains = action.payload
    },
    setLastPageOfDomainsTable(state, action: PayloadAction<number>) {
      state.lastPageOfDomainsTable = action.payload
    },
    setIsAllDomainsFetched(state, action: PayloadAction<boolean>) {
      state.isAllDomainsFetched = action.payload
    },
    setDisplayFilterDomainsModal(state, action: PayloadAction<boolean>) {
      state.displayFilterDomainsModal = action.payload
    },
  },
})

export const {
  actions: domainsActions,
  reducer: domainsReducer,
  name: sliceKey,
} = domainsSlice

export const useDomainsSlice = () => {
  useInjectReducer({ key: sliceKey, reducer: domainsReducer })
  useInjectSaga({ key: sliceKey, saga: domainsSaga })
  return { domainsActions: domainsActions }
}
