export function constructQueryParamsFromObject(
  params: Record<string, any> | undefined,
) {
  if (!params) {
    return ''
  }

  const queryString = Object.keys(params)
    .filter(
      key =>
        params[key] !== undefined && params[key] !== null && params[key] !== '',
    )
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
    .join('&')

  return `?${queryString}`
}
