import React, { ChangeEvent, FC } from 'react'
import { OutlinedTextFieldProps } from '@mui/material'
import BaseInput from '../baseInput'

interface MaskInputProps extends OutlinedTextFieldProps {
  maskedValue: string
  setMaskedValue: (value: string) => void
}

export const MaskInput: FC<MaskInputProps> = ({
  value,
  onChange,
  maskedValue = value || '',
  setMaskedValue,
  ...props
}) => {
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value

    const numericValue = inputValue.replace(/\D/g, '')

    const formattedValue = numericValue
      .replace(/(\d{4})(\d{2})/, '$1-$2-')
      .slice(0, 10)

    //@ts-ignore
    const isDeleting = inputValue.length < maskedValue.length

    //@ts-ignore
    if (isDeleting && maskedValue[inputValue.length] === '-') {
      setMaskedValue(formattedValue.slice(0, -1))
    } else {
      setMaskedValue(formattedValue)
    }

    const modifiedEvent: ChangeEvent<HTMLInputElement> = {
      ...event,
      target: {
        ...event.target,
        value: formattedValue,
        name: event.target.name,
        id: event.target.id,
      },
    }

    onChange && onChange(modifiedEvent)
  }

  return (
    <>
      <BaseInput {...props} value={maskedValue} onChange={handleInputChange} />
    </>
  )
}
