import { useDispatch, useSelector } from 'react-redux'
import { FilterBotOrdersContent } from './content'
import { botOrdersSelectors } from 'app/containers/BotOrders/selectors'
import { botOrdersActions } from 'app/containers/BotOrders/slice'
import { Modal } from 'app/components/modal'

export const FilterBotOrdersModal = () => {
  const dispatch = useDispatch()

  const isOpen = useSelector(botOrdersSelectors.displayFilterBotOrdersModal)
  const handleModalClose = () => {
    dispatch(botOrdersActions.setDisplayFilterBotOrdersModal(false))
  }

  return (
    <Modal open={isOpen} onClose={handleModalClose}>
      <FilterBotOrdersContent />
    </Modal>
  )
}
