import { createSelector } from '@reduxjs/toolkit'

import { RootState } from 'store/types'
import { initialState } from './slice'

export const adminsDomains = {
  root: (state: RootState) => state.admins || initialState,
  adminList: (state: RootState) => state.admins.adminsList,
  isLoadingAdmins: (state: RootState) => state.admins.isLoadingAdmins,
  deleteConfirmationModal: (state: RootState) =>
    state.admins.deleteConfirmationModal,
  isAddingRole: (state: RootState) => state.admins.isRoleAdding,
  addAdminModalDisplay: (state: RootState) => state.admins.addAdminModal,
  roleAddingIDOfAdmin: (state: RootState) => state.admins.roleAddingIDOfAdmin,
}

export const adminsSelectors = {
  root: createSelector(adminsDomains.root, root => root),
  adminList: createSelector(adminsDomains.adminList, adminList => adminList),
  isLoadingAdmins: createSelector(
    adminsDomains.isLoadingAdmins,
    isLoadingAdmins => isLoadingAdmins,
  ),
  deleteConfirmationModal: createSelector(
    adminsDomains.deleteConfirmationModal,
    deleteConfirmationModal => deleteConfirmationModal,
  ),
  isAddingRole: createSelector(
    adminsDomains.isAddingRole,
    isAddingRole => isAddingRole,
  ),
  addAdminModalDisplay: createSelector(
    adminsDomains.addAdminModalDisplay,
    addAdminModalDisplay => addAdminModalDisplay,
  ),
  roleAddingIDOfAdmin: createSelector(
    adminsDomains.roleAddingIDOfAdmin,
    roleAddingIDOfAdmin => roleAddingIDOfAdmin,
  ),
}
