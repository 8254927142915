import { createSelector } from '@reduxjs/toolkit'

import { RootState } from 'store/types'
import { initialState } from './slice'

export const emailVerificationsDomains = {
  root: (state: RootState) => state.emailVerifications || initialState,
  emailVerificationsList: (state: RootState) =>
    state.emailVerifications.emailVerificationsList,
  isLoadingEmailVerifications: (state: RootState) =>
    state.emailVerifications.isLoadingEmailVerifications,
  filterEmailVerifications: (state: RootState) =>
    state.emailVerifications.filterEmailVerifications,
  lastPageOfEmailVerificationsTable: (state: RootState) =>
    state.emailVerifications.lastPageOfEmailVerificationsTable,
  isAllEmailVerificationsFetched: (state: RootState) =>
    state.emailVerifications.isAllEmailVerificationsFetched,
  displayFilterEmailVerificationsModal: (state: RootState) =>
    state.emailVerifications.displayFilterEmailVerificationsModal,
}

export const emailVerificationsSelectors = {
  root: createSelector(emailVerificationsDomains.root, root => root),
  emailVerificationsList: createSelector(
    emailVerificationsDomains.emailVerificationsList,
    emailVerificationsList => emailVerificationsList,
  ),
  isLoadingEmailVerifications: createSelector(
    emailVerificationsDomains.isLoadingEmailVerifications,
    isLoading => isLoading,
  ),
  filterEmailVerifications: createSelector(
    emailVerificationsDomains.filterEmailVerifications,
    filterData => filterData,
  ),
  lastPageOfEmailVerificationsTable: createSelector(
    emailVerificationsDomains.lastPageOfEmailVerificationsTable,
    lastPageOfTable => lastPageOfTable,
  ),
  isAllEmailVerificationsFetched: createSelector(
    emailVerificationsDomains.isAllEmailVerificationsFetched,
    isAllFetched => isAllFetched,
  ),
  displayFilterEmailVerificationsModal: createSelector(
    emailVerificationsDomains.displayFilterEmailVerificationsModal,
    displayFilterModal => displayFilterModal,
  ),
}
