import axiosInstance from 'api/axios'
import { AxiosResponse } from 'axios'
import { OrganizationsEndpoints } from 'api/endpoints/connector'
import { IFilterOrganizations } from '../types'

export function* getOrganizationsReq(filterData: IFilterOrganizations) {
  const response: AxiosResponse = yield axiosInstance.get(
    OrganizationsEndpoints.organizationsList(filterData),
  )
  return response
}
