import { ISortType } from 'app/types'
import { capitalizeAllCharacters, parseToNumber } from './string'

interface LocalStorageOptions {
  tableKey: string
}
export const getTableSettingsFromStorage = ({
  tableKey,
}: LocalStorageOptions) => {
  const pageSize = parseToNumber(localStorage.getItem(`${tableKey}_pageSize`))

  const sortType = capitalizeAllCharacters(
    localStorage.getItem(`${tableKey}-order`) ?? '',
  ) as ISortType

  const sortField = localStorage.getItem(`${tableKey}-orderBy`) ?? undefined

  return { pageSize, sortType, sortField }
}
