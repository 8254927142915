import { Chip, styled } from '@mui/material'
import { FC } from 'react'
import { getDateFromTimestamp } from 'utils/date'

export interface IShowablefilterData {
  label: string
  value: string | number | undefined
  isDate?: boolean
}

interface IFilterChipsInHeaderProps {
  showableFilter: IShowablefilterData[]
  deleteFilter: (label: string) => void
}

export const FilterChipsInHeader: FC<IFilterChipsInHeaderProps> = ({
  showableFilter,
  deleteFilter,
}) => {
  const handleDelete = (label: string) => {
    //to lowercase first character of label and remove space between words
    label = label.charAt(0).toLowerCase() + label.slice(1).replace(/\s/g, '')
    deleteFilter(label)
  }

  return (
    <>
      {showableFilter.map(filter => {
        const value = filter.isDate
          ? getDateFromTimestamp(filter.value as number)
          : filter.value
        return filter.value ? (
          <StyledChip
            key={filter.label}
            label={`${filter.label}: ${value}`}
            onDelete={() => handleDelete(filter.label)}
            variant="outlined"
          />
        ) : null
      })}
    </>
  )
}

const StyledChip = styled(Chip)`
  background: var(--alice-blue);
  border-color: var(--chip-color);
  color: var(--chip-color);
  font-weight: 700;
`
