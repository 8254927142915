import { createSelector } from '@reduxjs/toolkit'

import { RootState } from 'store/types'
import { initialState } from './slice'
import { LoginDomains } from '../Login/selectors'
import { adminsDomains } from '../Admins/selectors'

export const vouchersDomains = {
  root: (state: RootState) => state.vouchers || initialState,
  vouchersList: (state: RootState) => state.vouchers.vouchersList,
  isLoadingVouchers: (state: RootState) => state.vouchers.isLoadingVouchers,
  displayVoucherModal: (state: RootState) => state.vouchers.displayVoucherModal,
  filterVouchers: (state: RootState) => state.vouchers.filterVouchers,
  lastPageOfVouchersTable: (state: RootState) =>
    state.vouchers.lastPageOfVouchersTable,
  isAllVouchersFetched: (state: RootState) =>
    state.vouchers.isAllVouchersFetched,
  displayFilterVouchersModal: (state: RootState) =>
    state.vouchers.displayFilterVouchersModal,
}

export const vouchersSelectors = {
  root: createSelector(vouchersDomains.root, root => root),
  vouchersList: createSelector(
    vouchersDomains.vouchersList,
    vouchersList => vouchersList,
  ),
  isLoadingVouchers: createSelector(
    vouchersDomains.isLoadingVouchers,
    isLoading => isLoading,
  ),
  displayVoucherModal: createSelector(
    vouchersDomains.displayVoucherModal,
    displayVoucherModal => displayVoucherModal,
  ),
  filterVouchers: createSelector(
    vouchersDomains.filterVouchers,
    filterVouchers => filterVouchers,
  ),
  lastPageOfVouchersTable: createSelector(
    vouchersDomains.lastPageOfVouchersTable,
    lastPageOfTable => lastPageOfTable,
  ),
  isAllVouchersFetched: createSelector(
    vouchersDomains.isAllVouchersFetched,
    isAllVouchersFetched => isAllVouchersFetched,
  ),
  displayFilterVouchersModal: createSelector(
    vouchersDomains.displayFilterVouchersModal,
    displayFilterModal => displayFilterModal,
  ),
  activeAdminId: createSelector(
    [adminsDomains.adminList, LoginDomains.coreId],
    (adminList, coreId) => {
      const admin = adminList.find(admin => admin.coreID === coreId)
      return admin?.id
    },
  ),
}
