import { call, put, select, takeLatest } from 'redux-saga/effects'
import { transmissionsActions } from './slice'
import { getTransmissionReq } from './providers'
import { AxiosResponse } from 'axios'
import { customToast } from 'app/components/toast/CustomToast'
import { transmissionsSelectors } from './selectors'
import { IFilterTransmissions, ITransmission } from './types'
import { errorMessage } from 'utils/parser'

function* getTransmissions(): any {
  yield put(transmissionsActions.setIsLoadingTransmissions(true))
  try {
    const filterData: IFilterTransmissions = yield select(
      transmissionsSelectors.filterTransmissions,
    )
    const response: AxiosResponse = yield call(getTransmissionReq, filterData)

    const listOfNewData = (response?.data?.data?.orders ||
      []) as ITransmission[]

    yield put(transmissionsActions.setTransmissions(listOfNewData))

    listOfNewData.length === 0 || listOfNewData.length < filterData.pageSize
      ? yield put(transmissionsActions.setIsAllTransmissionsFetched(true))
      : yield put(transmissionsActions.setIsAllTransmissionsFetched(false))
  } catch (error) {
    customToast.error(errorMessage(error))
  } finally {
    yield put(transmissionsActions.setIsLoadingTransmissions(false))
  }
}

export function* transmissionsSaga() {
  yield takeLatest(
    transmissionsActions.getTransmissionsList.type,
    getTransmissions,
  )
}
