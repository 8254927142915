import { styled } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import { useDispatch, useSelector } from 'react-redux'

import { emailVerificationsSelectors } from '../selectors'
import { emailVerificationsActions } from '../slice'
import {
  FilterChipsInHeader,
  IShowablefilterData,
} from 'app/components/common/FilterChipsInHeader'
import { PageHeader } from 'app/components/common/globals/pageHeader'
import PrimaryButton from 'app/components/button/primaryButton'

export const EmailVerificationsHeader = () => {
  const dispatch = useDispatch()

  const filterEmailVerifications = useSelector(
    emailVerificationsSelectors.filterEmailVerifications,
  )

  const displayFilterEmailVerificationsModal = useSelector(
    emailVerificationsSelectors.displayFilterEmailVerificationsModal,
  )

  const handleOpenFilterModal = () => {
    dispatch(
      emailVerificationsActions.setDisplayFilterEmailVerificationsModal(true),
    )
  }

  const showablefilterEmailVerification: IShowablefilterData[] = [
    { label: 'CoreID', value: filterEmailVerifications?.coreID },
    { label: 'Status', value: filterEmailVerifications?.status },
    { label: 'NFTID', value: filterEmailVerifications?.nftID },
    { label: 'Id', value: filterEmailVerifications?.id },
    {
      label: 'Expiration',
      value: filterEmailVerifications?.expiration,
      isDate: true,
    },
    { label: 'VouchererName', value: filterEmailVerifications?.vouchererName },
    { label: 'TxHash', value: filterEmailVerifications?.txHash },
    { label: 'BcTxID', value: filterEmailVerifications?.bcTxID },
    { label: 'MintType', value: filterEmailVerifications?.mintType },
    { label: 'VerifyRetry', value: filterEmailVerifications?.verifyRetry },
    {
      label: 'CreatedAt From',
      value: filterEmailVerifications?.createdAtFrom,
      isDate: true,
    },
    {
      label: 'CreatedAt To',
      value: filterEmailVerifications?.createdAtTo,
      isDate: true,
    },
  ]

  const handleDelete = (label: string) => {
    dispatch(
      emailVerificationsActions.setFilterEmailVerifications({
        ...filterEmailVerifications,
        [label]: undefined,
      }),
    )
    dispatch(emailVerificationsActions.getEmailVerifications())
  }

  return (
    <>
      <PageHeader title="Email Verificaions">
        <PrimaryButton
          onClick={handleOpenFilterModal}
          endIcon={<StyledSearchIcon />}
        >
          Search
        </PrimaryButton>
      </PageHeader>
      <FilterSection>
        {!displayFilterEmailVerificationsModal && (
          <FilterChipsInHeader
            showableFilter={showablefilterEmailVerification}
            deleteFilter={handleDelete}
          />
        )}
      </FilterSection>
    </>
  )
}

const FilterSection = styled('div')`
  display: flex;
  gap: 8px;
  width: 100%;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
`
const StyledSearchIcon = styled(SearchIcon)`
  color: var(--white);
`
