export const redirectToBlockBox = (id: string | number) => {
  const baseBlockBoxUrl = process.env.REACT_APP_BLOCK_INDEX_URL

  window.open(`${baseBlockBoxUrl}/tx/${id}`)?.focus()
}

export const redirectionToSumsub = (id: string | number) => {
  window
    .open(
      `https://cockpit.sumsub.com/checkus#/applicant/${id}/basicInfo?clientId=codetech.cc_54609`,
      '_blank',
    )
    ?.focus()
}
