import { call, put, select, takeLatest } from 'redux-saga/effects'
import { apiKeysActions } from './slice'
import { getApiKeysReq } from './providers'
import { AxiosResponse } from 'axios'
import { customToast } from 'app/components/toast/CustomToast'
import { errorMessage } from 'utils/parser'
import { apiKeysSelectors } from './selectors'
import { IFilterApiKeys, IApiKeys } from './types'

function* getApiKeys(): any {
  yield put(apiKeysActions.setIsLoadingApiKeys(true))
  try {
    const filterData: IFilterApiKeys = yield select(
      apiKeysSelectors.filterApiKeys,
    )
    const response: AxiosResponse = yield call(getApiKeysReq, filterData)

    const listOfNewData = (response?.data?.data?.apiKeys || []) as IApiKeys[]

    yield put(apiKeysActions.setApiKeys(listOfNewData))

    listOfNewData.length === 0 || listOfNewData.length < filterData.pageSize
      ? yield put(apiKeysActions.setIsAllApiKeysFetched(true))
      : yield put(apiKeysActions.setIsAllApiKeysFetched(false))
  } catch (error) {
    customToast.error(errorMessage(error))
  } finally {
    yield put(apiKeysActions.setIsLoadingApiKeys(false))
  }
}

export function* apiKeysSaga() {
  yield takeLatest(apiKeysActions.getApiKeysList.type, getApiKeys)
}
