export const timeOnlyFormat = (item: number | undefined): string => {
  if (!item) return ''
  const date = item
  const transactionTime = formatTimestamp(date, 'timeOnly')
  return transactionTime
}
export const longDateFormat = (item: number | undefined): string => {
  if (!item) return ''
  const date = item
  const transactionDate = formatTimestamp(date)
  return transactionDate
}
export const shortDateFormat = (item: number): string => {
  const date = item
  const transactionDate = formatTimestamp(date, 'short')
  return transactionDate
}
export function formatTimestamp(
  timestamp: number,
  type: string = 'long',
): string {
  const dateObj = new Date(timestamp * 1000)
  const month = dateObj.toLocaleString('default', { month: 'long' })
  const numMonth = dateObj.getMonth() + 1
  const day = dateObj.getDate()
  const year = dateObj.getFullYear()
  const hours = dateObj.getHours()
  const minutes = dateObj.getMinutes()
  const seconds = dateObj.getSeconds()

  let formattedDate
  let formattedTime

  if (type === 'long') {
    formattedDate = `${month} ${day}${getOrdinalSuffix(day)}, ${year}`
    formattedTime = `${formatTimeValue(hours)}:${formatTimeValue(
      minutes,
    )}:${formatTimeValue(seconds)}`
  } else {
    formattedDate = `${formatTimeValue(day)}/${formatTimeValue(
      numMonth,
    )}/${year}`
    formattedTime = `${formatTimeValue(hours)}:${formatTimeValue(
      minutes,
    )}:${formatTimeValue(seconds)}`
  }

  return type === 'timeOnly'
    ? `${formattedTime}`
    : `${formattedDate} - ${formattedTime}`
}

function getOrdinalSuffix(day: number): string {
  if (day >= 11 && day <= 13) {
    return 'th'
  }

  switch (day % 10) {
    case 1:
      return 'st'
    case 2:
      return 'nd'
    case 3:
      return 'rd'
    default:
      return 'th'
  }
}

function formatTimeValue(time: number): string {
  return time.toString().padStart(2, '0')
}

export function today(): string {
  const date = new Date()
  const day = date.getDate()
  const month = date.getMonth() + 1
  const year = date.getFullYear()

  return `${year}-${month}-${day}`
}

export function getTimestampFromDate(date: string): number {
  const timestamp = Date.parse(date)
  if (isNaN(timestamp)) {
    return 0
  }
  return timestamp / 1000
}

export function getDateFromTimestamp(timestamp?: number | string): string {
  if (!timestamp) {
    return ''
  }
  if (typeof timestamp === 'string') {
    timestamp = parseInt(timestamp)
  }
  const date = new Date(timestamp * 1000)
  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const day = String(date.getDate()).padStart(2, '0')

  const formattedDate = `${year}-${month}-${day}`
  return formattedDate
}

export default formatTimestamp
