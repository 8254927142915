import { useDispatch, useSelector } from 'react-redux'
import { FilterResubmissionsContent } from './content'
import { allResubmissionsSelectors } from 'app/containers/Resubmissions/selectors'
import { resubmissionsActions } from 'app/containers/Resubmissions/slice'
import { Modal } from 'app/components/modal'

export const FilterResubmissionsModal = () => {
  const dispatch = useDispatch()

  const isOpen = useSelector(
    allResubmissionsSelectors.displayFilterResubmissionsModal,
  )
  const handleModalClose = () => {
    dispatch(resubmissionsActions.setDisplayFilterResubmissionsModal(false))
  }

  return (
    <Modal open={isOpen} onClose={handleModalClose}>
      <FilterResubmissionsContent />
    </Modal>
  )
}
