/* eslint-disable react-hooks/rules-of-hooks */
import { PayloadAction } from '@reduxjs/toolkit'
import { IMainPageState, IFormData } from './types'
import { createSlice } from 'store/toolkit'
import { useInjectReducer, useInjectSaga } from 'store/redux-injectors'

import { mainPageSaga } from './saga'
import { today } from 'utils/date'

export const initialState: IMainPageState = {
  metricsData: null,
  isLoading: false,
  formData: {
    startDate: '2024-01-01',
    endDate: today(),
    period: 'MONTH',
  },
}

const mainPageSlice = createSlice({
  name: 'mainPage',
  initialState,
  reducers: {
    getMetricsData(state, action: PayloadAction<IFormData>) {},
    setMetricsData(
      state,
      action: PayloadAction<IMainPageState['metricsData']>,
    ) {
      state.metricsData = action.payload
    },
    setIsLoading(state, action: PayloadAction<IMainPageState['isLoading']>) {
      state.isLoading = action.payload
    },
    setFormData(state, action: PayloadAction<IMainPageState['formData']>) {
      state.formData = action.payload
    },
  },
})

export const {
  actions: mainPageActions,
  reducer: mainPageReducer,
  name: sliceKey,
} = mainPageSlice

export const useoverviewSlice = () => {
  useInjectReducer({ key: sliceKey, reducer: mainPageReducer })
  useInjectSaga({ key: sliceKey, saga: mainPageSaga })
  return { mainPageActions: mainPageActions }
}
