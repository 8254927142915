import { TableOfData } from './components/table'
import { Header } from './components/Header'
import { FilterResubmissionsModal } from './components/modals/filter'

export function Resubmissions() {
  return (
    <>
      <Header />
      <TableOfData />
      <FilterResubmissionsModal />
    </>
  )
}
