import * as React from 'react'
import TabContext from '@mui/lab/TabContext'
import { Divider, StyledTab, StyledTabs, TabPage, Wrapper } from './styles'
interface TabsType {
  title: string
  page: JSX.Element
}
interface Props {
  tabs: Array<TabsType>
  hasDivider?: boolean
  centered?: boolean
  scrollable?: boolean
  bgColor?: string
}
const CustomTab: React.FC<Props> = props => {
  const [value, setValue] = React.useState('1')

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue)
  }
  return (
    <TabContext value={value}>
      {props.hasDivider && <Divider />}
      <StyledTabs
        sx={{ backgroundColor: props.bgColor ?? '' }}
        variant={props.scrollable ? 'scrollable' : 'fullWidth'}
        scrollButtons={false}
        TabIndicatorProps={{
          sx: { backgroundColor: 'var(--greyscale-800)' },
        }}
        centered={props.centered}
        onChange={handleChange}
      >
        {props.tabs.map((item, idx) => (
          <StyledTab
            key={idx}
            disableRipple
            label={item.title}
            value={`${idx + 1}`}
          />
        ))}
      </StyledTabs>
      <Divider />
      {props.tabs.map((item, idx) => (
        <TabPage key={idx} value={`${idx + 1}`} title={item.title}>
          {item.page}
        </TabPage>
      ))}
    </TabContext>
  )
}
export default CustomTab
