import { styled } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import { useDispatch, useSelector } from 'react-redux'

import { clientsSelectors } from '../selectors'
import { clientsActions } from '../slice'
import {
  FilterChipsInHeader,
  IShowablefilterData,
} from 'app/components/common/FilterChipsInHeader'
import { PageHeader } from 'app/components/common/globals/pageHeader'
import PrimaryButton from 'app/components/button/primaryButton'

export const Header = () => {
  const dispatch = useDispatch()

  const filterClients = useSelector(clientsSelectors.filterClients)
  const displayFilterModal = useSelector(
    clientsSelectors.displayFilterClientsModal,
  )

  const handleOpenFilterModal = () => {
    dispatch(clientsActions.setDisplayFilterClientsModal(true))
  }

  const showablefilterClients: IShowablefilterData[] = [
    { label: 'corepassID', value: filterClients.corepassID },
    { label: 'name', value: filterClients.name },
    { label: 'email', value: filterClients.email },
    { label: 'phone', value: filterClients.phone },
    {
      label: 'CreatedAt From',
      value: filterClients.createdAtFrom,
      isDate: true,
    },
    { label: 'CreatedAt To', value: filterClients.createdAtTo, isDate: true },
    {
      label: 'UpdateAt From',
      value: filterClients.updateAtFrom,
      isDate: true,
    },
    { label: 'UpdateAt To', value: filterClients.updateAtTo, isDate: true },
  ]

  const handleDelete = (label: string) => {
    dispatch(
      clientsActions.setFilterClients({
        ...filterClients,
        [label]: undefined,
      }),
    )
    dispatch(clientsActions.getClientsList())
  }

  return (
    <>
      <PageHeader title="Clients">
        <PrimaryButton
          onClick={handleOpenFilterModal}
          endIcon={<StyledSearchIcon />}
        >
          Search
        </PrimaryButton>
      </PageHeader>
      <FilterSection>
        {!displayFilterModal && (
          <FilterChipsInHeader
            showableFilter={showablefilterClients}
            deleteFilter={handleDelete}
          />
        )}
      </FilterSection>
    </>
  )
}

const FilterSection = styled('div')`
  display: flex;
  gap: 8px;
  width: 100%;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
`
const StyledSearchIcon = styled(SearchIcon)`
  color: var(--white);
`
