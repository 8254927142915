import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import BaseInput from 'app/components/input/baseInput'
import { MaskInput } from 'app/components/input/inputWithMask'
import { getDateFromTimestamp, getTimestampFromDate } from 'utils/date'
import { DeleteForeverIcon } from 'app/components/icon/deleteForeverIcon'
import { documentRejectionsSelectors } from 'app/containers/DocumentRejections/selectors'
import { documentRejectionsActions } from 'app/containers/DocumentRejections/slice'
import { DOCUMENT_REJECTIONS_STATUS_OPTIONS } from 'app/containers/DocumentRejections/constants'
import { mediaQueries } from 'styles/mediaQueries'
import PrimaryButton from 'app/components/button/primaryButton'
import BaseDropdownInput from 'app/components/input/dropdownInput'
import { Box, styled } from '@mui/material'

export const FilterDocumentRejectionsContent = () => {
  const dispatch = useDispatch()
  const filterDocumentRejections = useSelector(
    documentRejectionsSelectors.filterDocumentRejections,
  )

  const [createAtFrom, setCreateAtFrom] = useState<string>(
    filterDocumentRejections?.createdAtFrom?.toString() ?? '',
  )
  const [createAtTo, setCreateAtTo] = useState<string>(
    filterDocumentRejections?.createdAtTo?.toString() ?? '',
  )
  const [updateAtFrom, setUpdateAtFrom] = useState<string>(
    filterDocumentRejections?.updateAtFrom?.toString() ?? '',
  )
  const [updateAtTo, setUpdateAtTo] = useState<string>(
    filterDocumentRejections?.updateAtTo?.toString() ?? '',
  )

  const handleInputChange = (event: any) => {
    dispatch(
      documentRejectionsActions.setFilterDocumentRejections({
        ...filterDocumentRejections,
        [event.target.name]: event.target.value,
      }),
    )
  }

  const handleDateInputChange = (event: any) => {
    const timeStamp = getTimestampFromDate(event.target.value)

    dispatch(
      documentRejectionsActions.setFilterDocumentRejections({
        ...filterDocumentRejections,
        [event.target.name]: timeStamp,
      }),
    )
  }

  useEffect(() => {
    setCreateAtFrom(
      getDateFromTimestamp(filterDocumentRejections?.createdAtFrom),
    )
    setCreateAtTo(getDateFromTimestamp(filterDocumentRejections?.createdAtTo))
    setUpdateAtFrom(
      getDateFromTimestamp(filterDocumentRejections?.updateAtFrom),
    )
    setUpdateAtTo(getDateFromTimestamp(filterDocumentRejections?.updateAtTo))
  }, [])

  const handleSearching = () => {
    dispatch(
      documentRejectionsActions.setDisplayFilterDocumentRejectionsModal(false),
    )
    dispatch(documentRejectionsActions.getDocumentRejections())
  }

  return (
    <Wrapper>
      <TwoItemsContainer>
        <ItemConatiner>
          <ItemLabel>AdminID:</ItemLabel>
          <BaseInput
            fullWidth
            type="text"
            id="adminID"
            name="adminID"
            label="AdminID"
            onChange={handleInputChange}
            value={filterDocumentRejections?.adminID}
            InputProps={{
              endAdornment: filterDocumentRejections?.adminID ? (
                <DeleteForeverIcon
                  onClick={() =>
                    dispatch(
                      documentRejectionsActions.setFilterDocumentRejections({
                        ...filterDocumentRejections,
                        adminID: '',
                      }),
                    )
                  }
                />
              ) : undefined,
            }}
          />
        </ItemConatiner>
        <ItemConatiner>
          <ItemLabel>Status:</ItemLabel>
          <BaseDropdownInput
            label="Status"
            placeholder="Status"
            name="status"
            fullWidth
            options={DOCUMENT_REJECTIONS_STATUS_OPTIONS}
            onChange={handleInputChange}
            value={filterDocumentRejections?.status ?? ''}
            endAdornment={
              filterDocumentRejections?.status ? (
                <DeleteForeverIcon
                  sx={{
                    position: 'absolute',
                    right: '20px',
                  }}
                  onClick={() =>
                    dispatch(
                      documentRejectionsActions.setFilterDocumentRejections({
                        ...filterDocumentRejections,
                        status: '',
                      }),
                    )
                  }
                />
              ) : undefined
            }
          />
        </ItemConatiner>
      </TwoItemsContainer>
      <TwoItemsContainer>
        <ItemConatiner>
          <ItemLabel>Voucherer Name:</ItemLabel>
          <BaseInput
            fullWidth
            type="text"
            id="vouchererName"
            name="vouchererName"
            label="Voucherer Name"
            onChange={handleInputChange}
            value={filterDocumentRejections?.vouchererName}
            InputProps={{
              endAdornment: filterDocumentRejections?.vouchererName ? (
                <DeleteForeverIcon
                  onClick={() =>
                    dispatch(
                      documentRejectionsActions.setFilterDocumentRejections({
                        ...filterDocumentRejections,
                        vouchererName: '',
                      }),
                    )
                  }
                />
              ) : undefined,
            }}
          />
        </ItemConatiner>
        <ItemConatiner>
          <ItemLabel>User CoreID:</ItemLabel>
          <BaseInput
            fullWidth
            type="text"
            id="userCoreID"
            name="userCoreID"
            label="User CoreID"
            onChange={handleInputChange}
            value={filterDocumentRejections?.userCoreID}
            InputProps={{
              endAdornment: filterDocumentRejections?.userCoreID ? (
                <DeleteForeverIcon
                  onClick={() =>
                    dispatch(
                      documentRejectionsActions.setFilterDocumentRejections({
                        ...filterDocumentRejections,
                        userCoreID: '',
                      }),
                    )
                  }
                />
              ) : undefined,
            }}
          />
        </ItemConatiner>
      </TwoItemsContainer>
      <TwoItemsContainer>
        <ItemConatiner>
          <ItemLabel>CreatedAt From:</ItemLabel>
          <MaskInput
            fullWidth
            variant="outlined"
            maskedValue={createAtFrom}
            setMaskedValue={setCreateAtFrom}
            type="text"
            id="createdAtFrom"
            name="createdAtFrom"
            label="CreatedAt From"
            placeholder="YYYY-MM-DD"
            onChange={handleDateInputChange}
            InputProps={{
              endAdornment: createAtFrom ? (
                <DeleteForeverIcon
                  onClick={() => {
                    dispatch(
                      documentRejectionsActions.setFilterDocumentRejections({
                        ...filterDocumentRejections,
                        createdAtFrom: undefined,
                      }),
                    )
                    setCreateAtFrom('')
                  }}
                />
              ) : undefined,
            }}
          />
        </ItemConatiner>
        <ItemConatiner>
          <ItemLabel>CreatedAt To:</ItemLabel>
          <MaskInput
            fullWidth
            variant="outlined"
            maskedValue={createAtTo}
            setMaskedValue={setCreateAtTo}
            type="text"
            id="createdAtTo"
            name="createdAtTo"
            label="CreatedAt To"
            placeholder="YYYY-MM-DD"
            onChange={handleDateInputChange}
            InputProps={{
              endAdornment: createAtTo ? (
                <DeleteForeverIcon
                  onClick={() => {
                    dispatch(
                      documentRejectionsActions.setFilterDocumentRejections({
                        ...filterDocumentRejections,
                        createdAtTo: undefined,
                      }),
                    )
                    setCreateAtTo('')
                  }}
                />
              ) : undefined,
            }}
          />
        </ItemConatiner>
      </TwoItemsContainer>
      <TwoItemsContainer>
        <ItemConatiner>
          <ItemLabel>UpdateAt From:</ItemLabel>
          <MaskInput
            fullWidth
            variant="outlined"
            maskedValue={updateAtFrom}
            setMaskedValue={setUpdateAtFrom}
            type="text"
            id="updateAtFrom"
            name="updateAtFrom"
            label="UpdateAt From"
            placeholder="YYYY-MM-DD"
            onChange={handleDateInputChange}
            InputProps={{
              endAdornment: updateAtFrom ? (
                <DeleteForeverIcon
                  onClick={() => {
                    dispatch(
                      documentRejectionsActions.setFilterDocumentRejections({
                        ...filterDocumentRejections,
                        updateAtFrom: undefined,
                      }),
                    )
                    setUpdateAtFrom('')
                  }}
                />
              ) : undefined,
            }}
          />
        </ItemConatiner>
        <ItemConatiner>
          <ItemLabel>UpdateAt To:</ItemLabel>
          <MaskInput
            fullWidth
            variant="outlined"
            maskedValue={updateAtTo}
            setMaskedValue={setUpdateAtTo}
            type="text"
            id="updateAtTo"
            name="updateAtTo"
            label="UpdateAt To"
            placeholder="YYYY-MM-DD"
            onChange={handleDateInputChange}
            InputProps={{
              endAdornment: updateAtTo ? (
                <DeleteForeverIcon
                  onClick={() => {
                    dispatch(
                      documentRejectionsActions.setFilterDocumentRejections({
                        ...filterDocumentRejections,
                        updateAtTo: undefined,
                      }),
                    )
                    setUpdateAtTo('')
                  }}
                />
              ) : undefined,
            }}
          />
        </ItemConatiner>
      </TwoItemsContainer>
      <CTAContainer>
        <PrimaryButtons onClick={handleSearching}>Search</PrimaryButtons>
      </CTAContainer>
    </Wrapper>
  )
}

const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 32px;
  width: 100%;
  ${mediaQueries.lessThan('sm')`
  gap: 23px;`}
  ${mediaQueries.lessThan('xs')`
  gap: 16px;`}
`

const TwoItemsContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 32px;
  width: 100%;
  ${mediaQueries.lessThan('xs')`
  flex-direction: column;
  gap: 16px;
  `}
`

const ItemConatiner = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  width: 100%;
  min-width: 452px;
  ${mediaQueries.lessThan('lg')`
  min-width: 417px;
  `}
  ${mediaQueries.lessThan('md')`
  min-width: 239px;
  `}
  ${mediaQueries.lessThan('sm')`
  min-width: 241px;
  `}
`

const ItemLabel = styled(Box)`
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.palette.text.primary};
  word-wrap: normal;
  min-width: 120px;
  font-weight: 700;
`
const CTAContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 32px;
  margin-top: 28px;
  width: 100%;
  ${mediaQueries.lessThan('sm')`
  margin-top: 16px;`}
  ${mediaQueries.lessThan('xs')`
  margin-top: 8px;`}
`

const PrimaryButtons = styled(PrimaryButton)`
  width: 200px;
  ${mediaQueries.lessThan('sm')`
  width: 111px;
`}
  ${mediaQueries.lessThan('xs')`
  width: 100%;`}
`
