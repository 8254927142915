import { useDispatch, useSelector } from 'react-redux'
import { FilterEmailVerificationsContent } from './content'
import { emailVerificationsSelectors } from 'app/containers/EmailVerifications/selectors'
import { emailVerificationsActions } from 'app/containers/EmailVerifications/slice'
import { Modal } from 'app/components/modal'

export const FilterEmailVerificationsModal = () => {
  const dispatch = useDispatch()

  const isOpen = useSelector(
    emailVerificationsSelectors.displayFilterEmailVerificationsModal,
  )
  const handleModalClose = () => {
    dispatch(
      emailVerificationsActions.setDisplayFilterEmailVerificationsModal(false),
    )
  }

  return (
    <Modal open={isOpen} onClose={handleModalClose}>
      <FilterEmailVerificationsContent />
    </Modal>
  )
}
