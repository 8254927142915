/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from '@reduxjs/toolkit'

import { InjectedReducersType } from 'store/types/injector-typings'
import { globalReducer } from './slice'
import { resubmissionsReducer } from 'app/containers/Resubmissions/slice'
import { resubmissionItemReducer } from 'app/containers/ResubmissionItem/slice'
import { rolesReducer } from 'app/containers/Roles/slice'
import { adminsReducer } from 'app/containers/Admins/slice'
import { mainPageReducer } from 'app/containers/MainPage/slice'
import { ordersReducer } from 'app/containers/Orders/slice'
import { paymentsReducer } from 'app/containers/Payments/slice'
import { vouchersReducer } from 'app/containers/Vouchers/slice'
import { loginReducer } from 'app/containers/Login/slice'
import { emailVerificationsReducer } from 'app/containers/EmailVerifications/slice'
import { phoneVerificationsReducer } from 'app/containers/PhoneVerifications/slice'
import { phoneInvalidationsReducer } from 'app/containers/PhoneInvalidations/slice'
import { emailInvalidationsReducer } from 'app/containers/EmailInvalidations/slice'
import { documentInvalidationsReducer } from 'app/containers/DocumentInvalidations/slice'
import { searchUserReducer } from 'app/containers/SearchUser/slice'
import { submissionsReducer } from 'app/containers/Submissions/slice'
import { nftsReducer } from 'app/containers/Nfts/slice'
import { phoneRejectionsReducer } from 'app/containers/PhoneRejections/slice'
import { documentRejectionsReducer } from 'app/containers/DocumentRejections/slice'
import { emailRejectionsReducer } from 'app/containers/EmailRejections/slice'
import { transmissionsReducer } from 'app/containers/Transmissions/slice'
import { recordsReducer } from 'app/containers/Records/slice'
import { apiKeysReducer } from 'app/containers/ApiKeys/slice'
import { clientsReducer } from 'app/containers/Clients/slice'
import { clientSourceKeysReducer } from 'app/containers/ClientSourceKeys/slice'
import { oauthsReducer } from 'app/containers/Oauths/slice'
import { organizationsReducer } from 'app/containers/Organizations/slice'
import { domainsReducer } from 'app/containers/Domains/slice'
import { assetsManagementReducer } from 'app/containers/AssetsManagement/slice'
import { configurationsReducer } from 'app/containers/Configurations/slice'
import { botOrdersReducer } from 'app/containers/BotOrders/slice'

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */

export function createReducer(injectedReducers: InjectedReducersType = {}) {
  // Initially we don't have any injectedReducers, so returning identity function to avoid the error
  return combineReducers({
    ...(Object.keys(injectedReducers).length !== 0 && injectedReducers),
    global: globalReducer,
    login: loginReducer,
    mainPage: mainPageReducer,
    resubmissions: resubmissionsReducer,
    resubmissionItem: resubmissionItemReducer,
    roles: rolesReducer,
    admins: adminsReducer,
    orders: ordersReducer,
    payments: paymentsReducer,
    vouchers: vouchersReducer,
    emailVerifications: emailVerificationsReducer,
    phoneVerifications: phoneVerificationsReducer,
    phoneInvalidations: phoneInvalidationsReducer,
    emailInvalidations: emailInvalidationsReducer,
    documentInvalidations: documentInvalidationsReducer,
    searchUser: searchUserReducer,
    submissions: submissionsReducer,
    nfts: nftsReducer,
    phoneRejections: phoneRejectionsReducer,
    documentRejections: documentRejectionsReducer,
    emailRejections: emailRejectionsReducer,
    transmissions: transmissionsReducer,
    records: recordsReducer,
    apiKeys: apiKeysReducer,
    clients: clientsReducer,
    clientSourceKeys: clientSourceKeysReducer,
    oauths: oauthsReducer,
    organizations: organizationsReducer,
    domains: domainsReducer,
    assetsManagement: assetsManagementReducer,
    configurations: configurationsReducer,
    botOrders: botOrdersReducer,
  })
}
