import BaseInput from 'app/components/input/baseInput'
import { ResubmissionRowItemWidget } from './ResubmissionRowItemWidget'
import { Box, IconButton, styled } from '@mui/material'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import { capitalizeWithDot } from 'utils/string'
import { MaskInput } from 'app/components/input/inputWithMask'
import { useState } from 'react'
import { mediaQueries } from 'styles/mediaQueries'

interface IResubmissionRowProps {
  name: string
  userValue?: string
  providerValue?: string
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  value?: string
  hasError?: boolean
  errorText?: string
  onFormikValueChange?: (e: string) => void
}
export const ResubmissionRow = (props: IResubmissionRowProps) => {
  const [maskedValue, setMaskedValue] = useState(props.value || '')

  return (
    <Wrapper>
      <Title>{capitalizeWithDot(props.name)}</Title>

      <ResubmissionRowItemWidget
        onClick={() => {
          //   set user value as input value
          props.onFormikValueChange &&
            props.onFormikValueChange(props.providerValue ?? '')
          setMaskedValue(props.providerValue ?? '')
        }}
        title={props.providerValue ?? ''}
      />
      <ResubmissionRowItemWidget
        onClick={() => {
          //   set user value as input value
          props.onFormikValueChange &&
            props.onFormikValueChange(props.userValue ?? '')
          setMaskedValue(props.userValue ?? '')
        }}
        title={props.userValue ?? ''}
      />

      <InputContainer>
        {props.name === 'dob' ||
        props.name === 'issuedDate' ||
        props.name === 'validUntil' ? (
          <MaskInput
            maskedValue={maskedValue}
            setMaskedValue={setMaskedValue}
            variant="outlined"
            type="text"
            id={props.name}
            name={props.name}
            label={capitalizeWithDot(props.name)}
            onChange={props.onChange}
            value={props.value}
            error={props.hasError}
            fullWidth
            helperText={props.errorText}
            InputProps={{
              endAdornment: props.value ? (
                <IconButton
                  onClick={() => {
                    props.onFormikValueChange && props.onFormikValueChange('')
                    setMaskedValue('')
                  }}
                  sx={{
                    width: '16px',
                    height: '16px',
                    right: '-20px',
                    backgroundColor: 'transparent',
                  }}
                >
                  <DeleteForeverIcon color="action" />
                </IconButton>
              ) : undefined,
            }}
          />
        ) : (
          <BaseInput
            type="text"
            id={props.name}
            name={props.name}
            label={capitalizeWithDot(props.name)}
            onChange={props.onChange}
            value={props.value}
            error={props.hasError}
            fullWidth
            helperText={props.errorText}
            InputProps={{
              endAdornment: props.value ? (
                <IconButton
                  onClick={() => {
                    props.onFormikValueChange && props.onFormikValueChange('')
                  }}
                  sx={{
                    width: '16px',
                    height: '16px',
                    right: '-20px',
                    backgroundColor: 'transparent',
                  }}
                >
                  <DeleteForeverIcon color="action" />
                </IconButton>
              ) : undefined,
            }}
          />
        )}
      </InputContainer>
    </Wrapper>
  )
}

const Wrapper = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 2fr 2fr 2fr;
  border-bottom: 1px solid #e0e0e0;
  height: 100px;
`

const Title = styled('h4')`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  word-break: break-all;
  padding: 0 5px;
  ${mediaQueries.lessThan('md')`
  font-size: 14px;
   `}
  ${mediaQueries.lessThan('sm')`
   font-size: 12px;
   `}
`

const InputContainer = styled(Box)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 0 20px;
  & .MuiFormHelperText-root {
    position: absolute;
    bottom: -20px;
    left: 0;
    height: 20px;
    font-size: 12px;
  }
  ${mediaQueries.lessThan('sm')`
  padding: 0 10px;
  `}
  ${mediaQueries.lessThan('xs')`
  padding: 0 5px;
  `}
`
