import { baseURL } from 'app/constants'
import axios from 'axios'

export function getApiKeyFromStorage() {
  const token = localStorage.getItem('AUTH_ACCESS_TOKEN')
  return [token]
}

const axiosInstance = axios.create({
  baseURL,
})

axiosInstance.interceptors.request.use(
  config => {
    const token = getApiKeyFromStorage()
    // Initialize config.headers as an object if it is undefined
    config.headers = config.headers || {}

    if (token) {
      config.headers['Authorization'] = `${token}`
    }
    return config
  },
  error => {
    return Promise.reject({ error })
  },
)

export const axiosInstanceNotLoggedIn = axios.create({
  baseURL,
})
export default axiosInstance
