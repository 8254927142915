import { FC } from 'react'
import { IconButton, IconButtonProps } from '@mui/material'
import { DeleteForever } from '@mui/icons-material'

interface IProps extends IconButtonProps {
  onClick: () => void
}

export const DeleteForeverIcon: FC<IProps> = ({ onClick, ...props }) => {
  return (
    <IconButton
      onClick={onClick}
      sx={{
        width: '16px',
        height: '16px',
        right: '-20px',
        backgroundColor: 'transparent',
      }}
      {...props}
    >
      <DeleteForever color="action" />
    </IconButton>
  )
}
