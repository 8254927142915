import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import { nftsActions } from './slice'
import { getAllNftResponseParser, getRpcProvider } from 'utils/cryptoOperations'
import { Provider } from 'corebc'
import { globalDomains } from '../Initialize/selector'
import { KYCVoucherer__factory } from 'contracts/kyc-voucherer'
import { errorMessage, successMessage } from 'utils/parser'
import { AxiosResponse } from 'axios'
import { customToast } from 'app/components/toast/CustomToast'
import { rejectNftReq } from './providers'
import { NftContractResponse } from './types'

export function* fetchNfts(action: ReturnType<typeof nftsActions.fetchNfts>) {
  yield put(nftsActions.setIsLoading(true))

  const provider: Provider = getRpcProvider()
  const {
    EmailVerifier,
    IDCardVerifier,
    PassportVerifier,
    PhoneVerifier,
    ResidencePermitVerifier,
    DriverLicenseVerifier,
  }: {
    EmailVerifier: string
    IDCardVerifier: string
    PassportVerifier: string
    PhoneVerifier: string
    ResidencePermitVerifier: string
    DriverLicenseVerifier: string
  } = yield select(globalDomains.contractAddresses)
  const nftsAddresses = {
    EmailVerifier,
    IDCardVerifier,
    PassportVerifier,
    PhoneVerifier,
    ResidencePermitVerifier,
    DriverLicenseVerifier,
  }

  const toDispatch: Promise<NftContractResponse>[] = []
  const nftsAddressesKeys = Object.keys(nftsAddresses) as Array<
    keyof typeof nftsAddresses
  >
  nftsAddressesKeys.map(key => {
    const nftContract = KYCVoucherer__factory.connect(
      nftsAddresses[key],
      provider,
    )
    toDispatch.push(nftContract.getAllNFTsWithStates(action.payload))
  })
  const nfts: NftContractResponse[] = yield all(toDispatch)
  const formatedNfts = getAllNftResponseParser(nfts, nftsAddressesKeys)

  yield put(nftsActions.setNfts(formatedNfts))
  yield put(nftsActions.setIsLoading(false))
}

export function* rejectNft(action: ReturnType<typeof nftsActions.rejectNft>) {
  try {
    yield put(nftsActions.setIsRejectingNft(true))
    const response: AxiosResponse = yield call(rejectNftReq, action)
    yield put(nftsActions.setConfirmRejectModalItem(undefined))
    customToast.success(successMessage(response))
  } catch (error) {
    customToast.error(errorMessage(error))
  } finally {
    yield put(nftsActions.setIsRejectingNft(false))
  }
}

export function* nftsSaga() {
  yield takeLatest(nftsActions.fetchNfts.type, fetchNfts)
  yield takeLatest(nftsActions.rejectNft.type, rejectNft)
}
