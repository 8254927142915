import { useDispatch } from 'react-redux'
import { vouchersActions } from '../../slice'
import { styled } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import { IssueVoucherModal } from './addVoucherModal'
import { mediaQueries } from 'styles/mediaQueries'
import PrimaryButton from 'app/components/button/primaryButton'

export const IssueVoucherButton = () => {
  const dispatch = useDispatch()
  return (
    <>
      <CTAContainer
        endIcon={<StyledAddIcon />}
        onClick={() => dispatch(vouchersActions.setDisplayVoucherModal(true))}
      >
        <h4> Issue Voucher</h4>
      </CTAContainer>
      <IssueVoucherModal />
    </>
  )
}
const CTAContainer = styled(PrimaryButton)`
  h4 {
    display: inline;
    margin: 0;
    ${mediaQueries.lessThan('xs')`
      display: none;   
      `}
  }
  ${mediaQueries.lessThan('xs')`
      width: 40px;
      & .MuiButton-endIcon {
        margin:0;
      }
      `}
`
const StyledAddIcon = styled(AddIcon)`
  color: var(--white);
`
