export const ellipsisBetween = (
  str: string,
  numberOfShownCharacter: number = 5,
) => {
  const length = str.length
  const shortenStringFirstSide = str
    .substring(0, numberOfShownCharacter)
    .concat('...')
  const shortenStringLastSide = str.substring(
    length - numberOfShownCharacter,
    length,
  )
  const shortenString = shortenStringFirstSide.concat(shortenStringLastSide)
  return shortenString
}

const fallbackCopyTextToClipboard = (text: string) => {
  let response = {}
  const textArea = document.createElement('textarea')
  textArea.value = text

  textArea.style.top = '0'
  textArea.style.left = '0'
  textArea.style.position = 'fixed'

  document.body.appendChild(textArea)
  textArea.focus()
  textArea.select()

  try {
    response = document.execCommand('copy')
  } catch (err: any) {
    throw new Error(err)
  }

  document.body.removeChild(textArea)
  return response
}

export const copyTextToClipboard = (text?: string) => {
  if (text) {
    if (!navigator.clipboard) {
      fallbackCopyTextToClipboard(text)
      return
    }
    return navigator.clipboard.writeText(text)
  }
  return
}

export const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export function capitalizeWithDot(string: string) {
  let result = ''
  let capitalizeNext = true

  for (let i = 0; i < string.length; i++) {
    if (string[i] === '.') {
      capitalizeNext = true
      result += '.'
    } else if (capitalizeNext && string[i] !== ' ') {
      result += string[i].toUpperCase()
      capitalizeNext = false
    } else {
      result += string[i]
    }
  }

  return result
}

export function parseToNumber(value: string | null) {
  return value ? +value : undefined
}

export function capitalize(str: string) {
  if (str.length === 0) {
    return undefined
  }
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export function capitalizeAllCharacters(str: string) {
  return str.toUpperCase()
}

// Function to generate a consistent random color based on the param
export const generateUniqueColor = (name: string) => {
  let hash = 0
  for (let i = 0; i < name.length; i++) {
    hash = name.charCodeAt(i) + ((hash << 5) - hash)
  }
  const c = (hash & 0x00ffffff).toString(16).toUpperCase()
  return '#' + '00000'.substring(0, 6 - c.length) + c
}

export const removePrefix = function (input: String) {
  if (!input.startsWith('0x')) return input.toString()
  return input.replace('0x', '')
}
