import { styled } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import { useDispatch, useSelector } from 'react-redux'

import { ordersSelectors } from '../selectors'
import { ordersActions } from '../slice'
import {
  FilterChipsInHeader,
  IShowablefilterData,
} from 'app/components/common/FilterChipsInHeader'
import { PageHeader } from 'app/components/common/globals/pageHeader'
import PrimaryButton from 'app/components/button/primaryButton'

export const OrdersHeader = () => {
  const dispatch = useDispatch()

  const filterOrders = useSelector(ordersSelectors.filterOrders)
  const displayFilterOrdersModal = useSelector(
    ordersSelectors.displayFilterOrdersModal,
  )

  const handleOpenFilterModal = () => {
    dispatch(ordersActions.setDisplayFilterOrdersModal(true))
  }

  const showablefilterOrders: IShowablefilterData[] = [
    { label: 'CoreID', value: filterOrders?.coreID },
    { label: 'Status', value: filterOrders?.status },
    { label: 'Payment Type', value: filterOrders?.paymentType },
    {
      label: 'CreatedAt From',
      value: filterOrders?.createdAtFrom,
      isDate: true,
    },
    { label: 'CreatedAt To', value: filterOrders?.createdAtTo, isDate: true },
    { label: 'UpdateAt From', value: filterOrders?.updateAtFrom, isDate: true },
    { label: 'UpdateAt To', value: filterOrders?.updateAtTo, isDate: true },
  ]

  const handleDelete = (label: string) => {
    dispatch(
      ordersActions.setFilterOrders({
        ...filterOrders,
        [label]: undefined,
      }),
    )
    dispatch(ordersActions.getOrders())
  }

  return (
    <>
      <PageHeader title="Orders">
        <PrimaryButton
          onClick={handleOpenFilterModal}
          endIcon={<StyledSearchIcon />}
        >
          Search
        </PrimaryButton>
      </PageHeader>
      <FilterSection>
        {!displayFilterOrdersModal && (
          <FilterChipsInHeader
            showableFilter={showablefilterOrders}
            deleteFilter={handleDelete}
          />
        )}
      </FilterSection>
    </>
  )
}

const FilterSection = styled('div')`
  display: flex;
  gap: 8px;
  width: 100%;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
`
const StyledSearchIcon = styled(SearchIcon)`
  color: var(--white);
`
