import { ColumnOfTable } from 'app/components/table/sortableTable'
import { getTableSettingsFromStorage } from 'utils/storage'

export const ASSETS_MANAGEMENT_TABLE_KEY = 'assetsManagementTable'

export const columnsForShowInAssetsManagementTable: ColumnOfTable[] = [
  {
    id: 'amount',
    label: 'Amount',
  },
  {
    id: 'asset',
    label: 'Asset',
  },
  {
    id: 'fee',
    label: 'Fee(%)',
  },
  {
    id: 'maintenanceAmount',
    label: 'Maintenance Amount',
  },
  {
    id: 'maintenanceFee',
    label: 'Maintenance Fee (%)',
  },
  {
    id: 'period',
    label: 'Period',
  },
  {
    id: 'price',
    label: 'Price(CHF)',
  },
  {
    id: 'priceWithoutFee',
    label: 'Price Without Fee(CHF)',
  },
  {
    id: 'priceIncludingFee',
    label: 'Price Including Fee(CHF)',
  },
  {
    id: 'priceIncludingMaintenanceFee',
    label: 'Price Including Maintenance Fee(CHF)',
  },
  {
    id: 'profit',
    label: 'Profit(CHF)',
  },
  {
    id: 'totalSales',
    label: 'Total Sales(CHF)',
  },
  {
    id: 'profitPercentage',
    label: 'Profit Percentage(%)',
  },
  {
    id: 'status',
    label: 'Status',
  },
  {
    id: 'startedAt',
    label: 'Started At',
    isDate: true,
  },
  {
    id: 'createdAt',
    label: 'Created At',
    isDate: true,
  },
  {
    id: 'endedAt',
    label: 'Ended At',
    isDate: true,
  },
]

export const {
  pageSize: localPageSize,
  sortType: localSortType,
  sortField: localSortField,
} = getTableSettingsFromStorage({ tableKey: ASSETS_MANAGEMENT_TABLE_KEY })

export const STATUS_OPTIONS = ['CREATED', 'AGGREGATED']
