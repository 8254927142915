import { call, put, select, takeLatest } from 'redux-saga/effects'
import { oauthsActions } from './slice'
import { getOauthsReq } from './providers'
import { AxiosResponse } from 'axios'
import { customToast } from 'app/components/toast/CustomToast'
import { errorMessage } from 'utils/parser'
import { oauthsSelectors } from './selectors'
import { IFilterOauths, IOauths } from './types'

function* getOauths(): any {
  yield put(oauthsActions.setIsLoadingOauths(true))
  try {
    const filterData: IFilterOauths = yield select(oauthsSelectors.filterOauths)
    const response: AxiosResponse = yield call(getOauthsReq, filterData)

    const listOfNewData = (response?.data?.data?.oauths || []) as IOauths[]

    yield put(oauthsActions.setOauths(listOfNewData))

    listOfNewData.length === 0 || listOfNewData.length < filterData.pageSize
      ? yield put(oauthsActions.setIsAllOauthsFetched(true))
      : yield put(oauthsActions.setIsAllOauthsFetched(false))
  } catch (error) {
    customToast.error(errorMessage(error))
  } finally {
    yield put(oauthsActions.setIsLoadingOauths(false))
  }
}

export function* oauthsSaga() {
  yield takeLatest(oauthsActions.getOauthsList.type, getOauths)
}
