import { ColumnOfTable } from 'app/components/table/sortableTable'
import { getTableSettingsFromStorage } from 'utils/storage'

export const CLIENTS_TABLE_KEY = 'clientsTable'

export const columnsForShowInClientsTable: ColumnOfTable[] = [
  { id: 'corepassID', label: 'CorepassID' },
  { id: 'country', label: 'Country' },
  { id: 'email', label: 'Email' },
  { id: 'name', label: 'Name' },
  { id: 'phone', label: 'Phone' },
  { id: 'createdAt', label: 'Created At', isDate: true },
  { id: 'updatedAt', label: 'Updated At', isDate: true },
]

export const {
  pageSize: localPageSize,
  sortType: localSortType,
  sortField: localSortField,
} = getTableSettingsFromStorage({ tableKey: CLIENTS_TABLE_KEY })
