import { styled } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import { useDispatch, useSelector } from 'react-redux'
import { emailRejectionsSelectors } from '../selectors'
import { emailRejectionsActions } from '../slice'
import {
  FilterChipsInHeader,
  IShowablefilterData,
} from 'app/components/common/FilterChipsInHeader'
import { PageHeader } from 'app/components/common/globals/pageHeader'
import PrimaryButton from 'app/components/button/primaryButton'

export const EmailRejectionsHeader = () => {
  const dispatch = useDispatch()

  const filterEmailRejections = useSelector(
    emailRejectionsSelectors.filterDataEmailRejections,
  )
  const displayFilterEmailRejectionsModal = useSelector(
    emailRejectionsSelectors.displayFilterEmailRejectionsModal,
  )

  const handleOpenFilterModal = () => {
    dispatch(emailRejectionsActions.setDisplayFilterEmailRejectionsModal(true))
  }

  const showablefilterEmailRejections: IShowablefilterData[] = [
    { label: 'AdminID', value: filterEmailRejections?.adminID },
    { label: 'UserCoreID', value: filterEmailRejections?.userCoreID },
    { label: 'VouchererName', value: filterEmailRejections?.vouchererName },
    { label: 'Status', value: filterEmailRejections?.status },
    {
      label: 'CreatedAt From',
      value: filterEmailRejections?.createdAtFrom,
      isDate: true,
    },
    {
      label: 'CreatedAt To',
      value: filterEmailRejections?.createdAtTo,
      isDate: true,
    },
    {
      label: 'UpdateAt From',
      value: filterEmailRejections?.updateAtFrom,
      isDate: true,
    },
    {
      label: 'UpdateAt To',
      value: filterEmailRejections?.updateAtTo,
      isDate: true,
    },
  ]

  const handleDelete = (label: string) => {
    dispatch(
      emailRejectionsActions.setFilterEmailRejections({
        ...filterEmailRejections,
        [label]: undefined,
      }),
    )
    dispatch(emailRejectionsActions.getEmailRejections())
  }

  return (
    <>
      <PageHeader title="Email Rejections">
        <PrimaryButton
          onClick={handleOpenFilterModal}
          endIcon={<StyledSearchIcon />}
        >
          Search
        </PrimaryButton>
      </PageHeader>
      <FilterSection>
        {!displayFilterEmailRejectionsModal && (
          <FilterChipsInHeader
            showableFilter={showablefilterEmailRejections}
            deleteFilter={handleDelete}
          />
        )}
      </FilterSection>
    </>
  )
}

const FilterSection = styled('div')`
  display: flex;
  gap: 8px;
  width: 100%;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
`
const StyledSearchIcon = styled(SearchIcon)`
  color: var(--white);
`
