import { createSelector } from '@reduxjs/toolkit'

import { RootState } from 'store/types'
import { initialState } from './slice'

export const configurationsDomains = {
  root: (state: RootState) => state.configurations || initialState,
  configurationsList: (state: RootState) =>
    state.configurations.configurationsList,
  isLoadingConfigurations: (state: RootState) =>
    state.configurations.isLoadingConfigurations,
  filterConfigurations: (state: RootState) =>
    state.configurations.filterConfigurations,
  lastPageOfConfigurationsTable: (state: RootState) =>
    state.configurations.lastPageOfConfigurationsTable,
  isAllConfigurationsFetched: (state: RootState) =>
    state.configurations.isAllConfigurationsFetched,
  displayFilterConfigurationsModal: (state: RootState) =>
    state.configurations.displayFilterConfigurationsModal,
  displaySetConfigurationModal: (state: RootState) =>
    state.configurations.displaySetConfigurationModal,
  newConfiguration: (state: RootState) => state.configurations.newConfiguration,
}

export const configurationsSelectors = {
  root: createSelector(configurationsDomains.root, root => root),
  configurationsList: createSelector(
    configurationsDomains.configurationsList,
    configurations => configurations,
  ),
  isLoadingConfigurations: createSelector(
    configurationsDomains.isLoadingConfigurations,
    isLoading => isLoading,
  ),
  filterConfigurations: createSelector(
    configurationsDomains.filterConfigurations,
    filterConfigurations => filterConfigurations,
  ),
  lastPageOfConfigurationsTable: createSelector(
    configurationsDomains.lastPageOfConfigurationsTable,
    lastPageOfTable => lastPageOfTable,
  ),
  isAllConfigurationsFetched: createSelector(
    configurationsDomains.isAllConfigurationsFetched,
    isAllFetched => isAllFetched,
  ),
  displayFilterConfigurationsModal: createSelector(
    configurationsDomains.displayFilterConfigurationsModal,
    displayFilterModal => displayFilterModal,
  ),
  displaySetConfigurationModal: createSelector(
    configurationsDomains.displaySetConfigurationModal,
    displaySetModal => displaySetModal,
  ),
  newConfiguration: createSelector(
    configurationsDomains.newConfiguration,
    newConfiguration => newConfiguration,
  ),
}
