import { ColumnOfTable } from 'app/components/table/sortableTable'

export const TABLE_KEY = 'submissonsTable'

export const columnsForShowInTable: ColumnOfTable[] = [
  {
    id: 'id',
    label: 'Id',
  },
  {
    id: 'state',
    label: 'State',
  },
  {
    id: 'type',
    label: 'Type',
  },
  {
    id: 'action',
    label: 'Reject',
    actionKey: 'id',
  },
]

export const KYCNFT_STATE = {
  0: 'NotIssued',
  1: 'Issued',
  2: 'Locked',
  3: 'Verified',
  4: 'Rejected',
}
