import { useDispatch, useSelector } from 'react-redux'
import { FilterDocumentInvalidationsContent } from './content'
import { documentInvalidationsSelectors } from 'app/containers/DocumentInvalidations/selectors'
import { documentInvalidationsActions } from 'app/containers/DocumentInvalidations/slice'
import { Modal } from 'app/components/modal'

export const FilterDocumentInvalidationsModal = () => {
  const dispatch = useDispatch()

  const isOpen = useSelector(
    documentInvalidationsSelectors.displayFilterDocumentInvalidationsModal,
  )
  const handleModalClose = () => {
    dispatch(
      documentInvalidationsActions.setDisplayFilterDocumentInvalidationsModal(
        false,
      ),
    )
  }

  return (
    <Modal open={isOpen} onClose={handleModalClose}>
      <FilterDocumentInvalidationsContent />
    </Modal>
  )
}
