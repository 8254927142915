import { useDispatch, useSelector } from 'react-redux'
import { FilterOrganizationsContent } from './content'
import { organizationsSelectors } from 'app/containers/Organizations/selectors'
import { organizationsActions } from 'app/containers/Organizations/slice'
import { Modal } from 'app/components/modal'

export const FilterOrganizationsModal = () => {
  const dispatch = useDispatch()

  const isOpen = useSelector(
    organizationsSelectors.displayFilterOrganizationsModal,
  )
  const handleModalClose = () => {
    dispatch(organizationsActions.setDisplayFilterOrganizationsModal(false))
  }

  return (
    <Modal open={isOpen} onClose={handleModalClose}>
      <FilterOrganizationsContent />
    </Modal>
  )
}
