import { Header } from './components/Header'
import { FilterClientSourceKeysModal } from './components/modals/filter'
import { TableOfData } from './components/table'

export function ClientSourceKeys() {
  return (
    <>
      <Header />
      <TableOfData />
      <FilterClientSourceKeysModal />
    </>
  )
}
