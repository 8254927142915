import { styled } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import { useDispatch, useSelector } from 'react-redux'

import { phoneRejectionsSelectors } from '../selectors'
import { phoneRejectionsActions } from '../slice'
import {
  FilterChipsInHeader,
  IShowablefilterData,
} from 'app/components/common/FilterChipsInHeader'
import { PageHeader } from 'app/components/common/globals/pageHeader'
import PrimaryButton from 'app/components/button/primaryButton'

export const PhoneRejectionsHeader = () => {
  const dispatch = useDispatch()

  const filterPhoneRejections = useSelector(
    phoneRejectionsSelectors.filterPhoneRejections,
  )
  const displayFilterPhoneRejectionsModal = useSelector(
    phoneRejectionsSelectors.displayFilterPhoneRejectionsModal,
  )

  const handleOpenFilterModal = () => {
    dispatch(phoneRejectionsActions.setDisplayFilterPhoneRejectionsModal(true))
  }

  const showablefilterPhoneRejections: IShowablefilterData[] = [
    { label: 'AdminID', value: filterPhoneRejections?.adminID },
    { label: 'UserCoreID', value: filterPhoneRejections?.userCoreID },
    { label: 'VouchererName', value: filterPhoneRejections?.vouchererName },
    { label: 'Status', value: filterPhoneRejections?.status },
    {
      label: 'CreatedAt From',
      value: filterPhoneRejections?.createdAtFrom,
      isDate: true,
    },
    {
      label: 'CreatedAt To',
      value: filterPhoneRejections?.createdAtTo,
      isDate: true,
    },
    {
      label: 'UpdateAt From',
      value: filterPhoneRejections?.updateAtFrom,
      isDate: true,
    },
    {
      label: 'UpdateAt To',
      value: filterPhoneRejections?.updateAtTo,
      isDate: true,
    },
  ]

  const handleDelete = (label: string) => {
    dispatch(
      phoneRejectionsActions.setFilterPhoneRejections({
        ...filterPhoneRejections,
        [label]: undefined,
      }),
    )
    dispatch(phoneRejectionsActions.getPhoneRejections())
  }

  return (
    <>
      <PageHeader title="Phone Rejections">
        <PrimaryButton
          onClick={handleOpenFilterModal}
          endIcon={<StyledSearchIcon />}
        >
          Search
        </PrimaryButton>
      </PageHeader>
      <FilterSection>
        {!displayFilterPhoneRejectionsModal && (
          <FilterChipsInHeader
            showableFilter={showablefilterPhoneRejections}
            deleteFilter={handleDelete}
          />
        )}
      </FilterSection>
    </>
  )
}

const FilterSection = styled('div')`
  display: flex;
  gap: 8px;
  width: 100%;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
`
const StyledSearchIcon = styled(SearchIcon)`
  color: var(--white);
`
