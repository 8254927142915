import { createGlobalStyle, css } from 'styled-components'

const GlobalStyle = createGlobalStyle`
:root {
  --mirage:#131722;
  --white:#ffffff;
  --lynch:#5D738F;
  --link-water:#DBE5F4;
  --denim:#1066DF;
  --infinity:#222531;
  --causeway:#6D7A8C;
  --pacific-line: #2e3344;
  --black-knight: #020c16;
  --royal-blue:#4085E5;
  --greek-sea: #70a3ec;
  --black:#000000;
  --spring-dew: #d7efe1;
  --red-panda: #cc4a1b;
  --lake-green: #2e8e58;
  --pink-sangria: #f5dbd1;
  --wash-me: #fafbfc;
  --error:#d32f2f;
  --success:#4caf50;
  --alice-blue:#e3f2fd;
  --chip-color:#1c5699;
// add coreport colors
  --greyscale-25: #f8fafd;
  --greyscale-50: #ebf0f7;
  --greyscale-100: #dfe6f0;
  --greyscale-200: #ccd5e3;
  --greyscale-300: #a9b5c7;
  --greyscale-400: #78879d;
  --greyscale-500: #495870;
  --greyscale-600: #2a384e;
  --greyscale-700: #131d2f;
  --greyscale-800: #0d1522;
  --corepass: #1066df;
  --black: #070c15;
  --purple: #3551f2;
  --success-50: #ccfde0;
  --success-100: #92f5ba;
  --success-200: #66ea9b;
  --success-300: #51e08a;
  --success-400: #46d07d;
  --success-500: #40be72;
  --success-600: #39af68;
  --success-700: #27a358;
  --success-800: #198f48;
  --warning-50: #fef9f1;
  --warning-100: #ffeccc;
  --warning-200: #ffd285;
  --warning-300: #ffd285;
  --warning-400: #ffc35c;
  --warning-500: #ffb940;
  --warning-600: #eea72d;
  --warning-700: #db9317;
  --warning-800: #c98106;
  --error-50: #fff4f4;
  --error-100: #fac8c8;
  --error-200: #f17e7d;
  --error-300: #f16b6a;
  --error-400: #f15857;
  --error-500: #f13e3d;
  --error-600: #de2d2c;
  --error-700: #c62221;
  --error-800: #b0100f;
  --light-grey: #fafbfc;
  --menu-shadow: 0px 4px 8px rgba(116, 133, 157, 0.07);
  --box-shadow: 0px 0px 16px rgba(204, 213, 227, 0.12);
  --white-40: rgba(255, 255, 255, 0.4);
}
.darkTheme {

}

body,
html {
  margin: 0;
  width: 100vw;
  min-height: 100vh;
  font-family:"Inter",Arial, Helvetica, sans-serif ;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  
  input[type=number] {
  -moz-appearance: textfield; /* Firefox */
  }

  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

}
*{
  box-sizing:border-box;
}
`
//COLUMN

export const ROW = css`
  display: flex;
  flex-direction: row;
`
export const COLUMN = css`
  display: flex;
  flex-direction: column;
`
const CENTER = css`
  align-items: center;
  justify-content: center;
`
export const COLUMN_CENTER = `
  ${CENTER}
  ${COLUMN}
`
export const ROW_CENTER = `
  ${CENTER}
  ${ROW}
`
export const COLUMN_JUSTIFY_END__ALIGN_CENTER = `
  ${COLUMN}
  justify-content: flex-end;
  align-items: center;
`
export const COLUMN_JUSTIFY_START__ALIGN_CENTER = `
  ${COLUMN}
  justify-content: flex-start;
  align-items: center;
`
export const COLUMN_ALIGN_END__JUSTIFY_CENTER = `
  ${COLUMN}
  justify-content: center;
  align-items: flex-end;
`
export const COLUMN_ALIGN_START__JUSTIFY_CENTER = `
  ${COLUMN}
  justify-content: center;
  align-items: flex-start;
`
export const COLUMN_ALIGN_START__JUSTIFY_START = `
  ${COLUMN}
  justify-content: flex-start;
  align-items: flex-start;
`
export const COLUMN_ALIGN_END__JUSTIFY_END = css`
  ${COLUMN}
  justify-content: flex-end;
  align-items: flex-end;
`
export const COLUMN_ALIGN_START__JUSTIFY_END = css`
  ${COLUMN}
  justify-content: flex-end;
  align-items: flex-start;
`
export const COLUMN_ALIGN_END__JUSTIFY_START = css`
  ${COLUMN}
  justify-content: flex-start;
  align-items: flex-end;
`
export const COLUMN_ALIGN_END__SPACE_B = css`
  ${COLUMN}
  justify-content:space-between;
  align-items: flex-end;
`
export const COLUMN_ALIGN_START__SPACE_B = `
  ${COLUMN}
  justify-content:space-between;
  align-items: flex-start;
`
export const COLUMN_ALIGN_CENTER__SPACE_B = css`
  ${COLUMN}
  justify-content:space-between;
  align-items: center;
`

// ROWS

export const ROW_JUSTIFY_END__ALIGN_CENTER = css`
  ${ROW}
  justify-content: flex-end;
  align-items: center;
`
export const ROW_JUSTIFY_START__ALIGN_CENTER = `
  ${ROW}
  justify-content: flex-start;
  align-items: center;
`
export const ROW_ALIGN_END__JUSTIFY_CENTER = css`
  ${ROW}
  justify-content: center;
  align-items: flex-end;
`
export const ROW_ALIGN_START__JUSTIFY_CENTER = css`
  ${ROW}
  justify-content: center;
  align-items: flex-start;
`
export const ROW_ALIGN_START__JUSTIFY_START = `
  ${ROW}
  justify-content: flex-start;
  align-items: flex-start;
`
export const ROW_ALIGN_END__JUSTIFY_END = css`
  ${ROW}
  justify-content: flex-end;
  align-items: flex-end;
`
export const ROW_ALIGN_START__JUSTIFY_END = css`
  ${ROW}
  justify-content: flex-end;
  align-items: flex-end;
`
export const ROW_ALIGN_END__JUSTIFY_START = css`
  ${ROW}
  justify-content: flex-end;
  align-items: flex-end;
`
export const ROW_ALIGN_END__SPACE_B = css`
  ${ROW}
  justify-content:space-between;
  align-items: flex-end;
`
export const ROW_ALIGN_START__SPACE_B = `
  ${ROW}
  justify-content:space-between;
  align-items: flex-start;
`
export const ROW_ALIGN_CENTER__SPACE_B = `
  ${ROW}
  justify-content:space-between;
  align-items: center;
`
export const FULL_WIDTH = css`
  width: 100%;
`
export const FULL_HEIGHT = css`
  height: 100%;
`
export const UNSELECTABLE = css`
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
`
export const BUTTON = css`
  ${UNSELECTABLE}
  border: none;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
`

export const BORDER_BLACK_WHITE_12 = css`
  position: relative;
  z-index: 3;
  &::before {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: 12px;
    padding: 1.3px;
    background: linear-gradient(
      135deg,
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0.15),
      rgba(255, 255, 255, 0.02),
      rgba(255, 255, 255, 0.2),
      rgba(255, 255, 255, 0.25)
    );
    -webkit-mask:
      linear-gradient(var(--White) 0 0) content-box,
      linear-gradient(var(--White) 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    z-index: -1;
  }
`

export const HIDE_SCROLLBAR_BUT_ALLOW_SCROLLING = css`
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }
`
export default GlobalStyle
