import React, { useLayoutEffect, useState } from 'react'
import ReactDOM from 'react-dom/client'
import App from './app/App'
import FontFaceObserver from 'fontfaceobserver'
import { Provider as ReduxProvider } from 'react-redux'
import { HelmetProvider } from 'react-helmet-async'
import { configureAppStore } from 'store/configureStore'
import GlobalStyle from 'styles/globalStyles'
import 'ag-grid-community/styles/ag-grid.css'
// Core grid CSS, always needed
import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer } from 'react-toastify'
import { globalActions } from 'store/slice'
import axiosInstance from 'api/axios'
import { PageLoading } from 'app/components/common/loading/pageLoading'

interface Props {
  Component: typeof App
}
const store = configureAppStore({})
const ConnectedApp = ({ Component }: Props) => {
  const [isFontLoaded, setIsFontLoaded] = useState(false)
  const openSansObserver = new FontFaceObserver('Inter', {})
  openSansObserver.load().then(() => {
    setIsFontLoaded(true)
    document.body.classList.add('fontLoaded')
  })

  useLayoutEffect(() => {
    if (document.body.classList.contains('fontLoaded')) {
      setIsFontLoaded(true)
    } else {
      setIsFontLoaded(false)
    }
  }, [isFontLoaded])
  return (
    <ReduxProvider store={store}>
      <GlobalStyle />
      <ToastContainer />
      <HelmetProvider>
        {isFontLoaded ? <Component /> : <PageLoading />}
      </HelmetProvider>
    </ReduxProvider>
  )
}

const { dispatch } = store
const ErrorHandling = (error: any) => {
  console.log({ error })
  if (
    error?.response?.status === 401 ||
    error?.response?.data?.error?.code === 401
  ) {
    dispatch(globalActions.setUnauthorized(true))
  }
  return Promise.reject({ error })
}
// display toast message if the user is unauthorized
axiosInstance.interceptors.response.use(response => {
  return response
}, ErrorHandling)

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(<ConnectedApp Component={App} />)
