import { Header } from './components/Header'
import { ConfirmDeleteModal } from './components/modals/confirmDeleteModal'
import { TableOfData } from './components/table'

export function Submissions() {
  return (
    <>
      <Header />
      <TableOfData />
      <ConfirmDeleteModal />
    </>
  )
}
