import { Box, styled } from '@mui/material'
import { mediaQueries } from 'styles/mediaQueries'

interface IWidgetProps {
  title: string
  type: 'user' | 'provider' | 'value'
}
export const Widget = (props: IWidgetProps) => {
  const { title, type } = props

  return (
    <Wrapper type={type}>
      <Title hasTitle={title ? 'true' : 'false'}>{title ? title : 'N/A'}</Title>
    </Wrapper>
  )
}

const Wrapper = styled(Box)<{ type: 'user' | 'provider' | 'value' }>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #e0e0e0;
  background-color: ${({ type }) =>
    type === 'provider'
      ? '#f5f5f5'
      : type === 'value'
        ? 'rgba(75,181,67,0.3)'
        : 'inherit'};
  &:first-of-type {
    border-left: 1px solid #e0e0e0;
  }
`

const Title = styled('p')<{ hasTitle: 'true' | 'false' }>`
  margin: 0;
  padding: 0 8px;
  color: ${({ hasTitle, theme }) =>
    hasTitle === 'true' ? theme.palette.common.black : theme.palette.grey[400]};
  word-break: break-word;
  ${mediaQueries.lessThan('sm')`
    font-size: 1spx;
    `}
  ${mediaQueries.lessThan('xs')`
    font-size: 12px;
    `}
`
