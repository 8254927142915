import axiosInstance from 'api/axios'
import { RolesEndpoints } from 'api/endpoints/roles'
import { AxiosResponse } from 'axios'
import { RolesState } from '../types'
import { rolesActions } from '../slice'

export function* fetchRolesReq() {
  const response: AxiosResponse = yield axiosInstance.get(
    RolesEndpoints.rolesList,
  )
  return response
}

export function* deleteRouteFromRoleReq(
  removeRouteFromRole: RolesState['deleteConfirmationModal'],
) {
  const { role, route } = removeRouteFromRole

  const response: AxiosResponse = yield axiosInstance.delete(
    RolesEndpoints.deleteRouteFromRole,
    // add some body with delete request
    {
      data: {
        paths: [route?.path],
        roleCode: role?.code,
      },
    },
  )
  return response
}

export function* addRouteToRoleReq(
  action: ReturnType<typeof rolesActions.addRouteToRole>,
) {
  const { role, route } = action.payload
  const response: AxiosResponse = yield axiosInstance.post(
    RolesEndpoints.addRouteToRole,
    {
      paths: [route?.path],
      roleCode: role?.code,
    },
  )
  return response
}

export function* fetchRoutesReq() {
  const response: AxiosResponse = yield axiosInstance.get(
    RolesEndpoints.routesList,
  )
  return response
}

export function* addRoleReq(action: ReturnType<typeof rolesActions.addRole>) {
  const response: AxiosResponse = yield axiosInstance.post(
    RolesEndpoints.addRole,
    action.payload,
  )
  return response
}
