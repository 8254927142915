import { createSelector } from '@reduxjs/toolkit'

import { RootState } from 'store/types'
import { initialState } from './slice'

export const resubmissionDomains = {
  root: (state: RootState) => state.resubmissions || initialState,
  lastPageOfResubmissionsTable: (state: RootState) =>
    state.resubmissions.lastPageOfResubmissionsTable,
  filterResubmissions: (state: RootState) =>
    state.resubmissions.filterResubmissions,
  displayFilterResubmissionsModal: (state: RootState) =>
    state.resubmissions.displayFilterResubmissionsModal,
}

export const allResubmissionsSelectors = {
  root: createSelector(resubmissionDomains.root, root => root),
  resubmissionsList: createSelector(
    resubmissionDomains.root,
    root => root.resubmissionsList,
  ),
  isLoadingResubmissions: createSelector(
    resubmissionDomains.root,
    root => root.isLoadingResubmissions,
  ),
  isAllResubmissionsFetched: createSelector(
    resubmissionDomains.root,
    root => root.isAllResubmissionsFetched,
  ),
  lastPageOfResubmissionsTable: createSelector(
    resubmissionDomains.lastPageOfResubmissionsTable,
    lastPageOfTable => lastPageOfTable,
  ),
  filterResubmissions: createSelector(
    resubmissionDomains.filterResubmissions,
    filterData => filterData,
  ),
  displayFilterResubmissionsModal: createSelector(
    resubmissionDomains.displayFilterResubmissionsModal,
    displayFilterModal => displayFilterModal,
  ),
}
