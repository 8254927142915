import { Box, styled } from '@mui/material'
import { ROW_ALIGN_CENTER__SPACE_B } from 'styles/globalStyles'
import { mediaQueries } from 'styles/mediaQueries'

export const Title = styled('div')`
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: -0.02em;
  color: var(--black-knight);
  ${mediaQueries.lessThan('md')`
    font-size: 28px;
    line-height: 32px;
    `}
  ${mediaQueries.lessThan('sm')`
    font-size: 24px;
    line-height: 28px;
    `}
    ${mediaQueries.lessThan('xs')`
    font-size: 20px;
    `}
`
export const TitleAndExportWrapper = styled('div')`
  ${ROW_ALIGN_CENTER__SPACE_B}
  width:100%;
  margin-bottom: 16px;
  ${mediaQueries.lessThan('sm')`
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  `}
`

export const HeaderContainer = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 2fr 2fr 2fr;
  padding: 10px 0;
  border-bottom: 1px solid #e0e0e0;
  ${mediaQueries.lessThan('md')`
  padding: 8px 0;
  `}
`

export const HeaderTitle = styled('h4')`
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: var(--black-knight);
  display: flex;
  align-items: center;
  justify-content: center;
  &:first-of-type {
    justify-self: flex-start !important;
  }
  ${mediaQueries.lessThan('md')`
    font-size: 14px;
    line-height: 20px;
    margin: 15px 0;   
  `}
`
