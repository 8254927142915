import { createSelector } from '@reduxjs/toolkit'

import { RootState } from 'store/types'
import { initialState } from './slice'

export const clientSourceKeysDomains = {
  root: (state: RootState) => state.clientSourceKeys || initialState,
  clientSourceKeysList: (state: RootState) =>
    state.clientSourceKeys.clientSourceKeysList,
  isLoadingClientSourceKeys: (state: RootState) =>
    state.clientSourceKeys.isLoadingClientSourceKeys,
  filterClientSourceKeys: (state: RootState) =>
    state.clientSourceKeys.filterClientSourceKeys,
  lastPageOfClientSourceKeysTable: (state: RootState) =>
    state.clientSourceKeys.lastPageOfClientSourceKeysTable,
  isAllClientSourceKeysFetched: (state: RootState) =>
    state.clientSourceKeys.isAllClientSourceKeysFetched,
  displayFilterClientSourceKeysModal: (state: RootState) =>
    state.clientSourceKeys.displayFilterClientSourceKeysModal,
}

export const clientSourceKeysSelectors = {
  root: createSelector(clientSourceKeysDomains.root, root => root),
  clientSourceKeysList: createSelector(
    clientSourceKeysDomains.clientSourceKeysList,
    clientSourceKeys => clientSourceKeys,
  ),
  isLoadingClientSourceKeys: createSelector(
    clientSourceKeysDomains.isLoadingClientSourceKeys,
    isLoading => isLoading,
  ),
  filterClientSourceKeys: createSelector(
    clientSourceKeysDomains.filterClientSourceKeys,
    filterData => filterData,
  ),
  lastPageOfClientSourceKeysTable: createSelector(
    clientSourceKeysDomains.lastPageOfClientSourceKeysTable,
    lastPageOfTable => lastPageOfTable,
  ),
  isAllClientSourceKeysFetched: createSelector(
    clientSourceKeysDomains.isAllClientSourceKeysFetched,
    isAllFetched => isAllFetched,
  ),
  displayFilterClientSourceKeysModal: createSelector(
    clientSourceKeysDomains.displayFilterClientSourceKeysModal,
    displayFilterModal => displayFilterModal,
  ),
}
