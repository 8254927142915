import { TableOfData } from './components/table'
import { Header } from './components/Header'
import { FilterVouchersModal } from './components/modals/filter'

export function Vouchers() {
  return (
    <>
      <Header />
      <TableOfData />
      <FilterVouchersModal />
    </>
  )
}
