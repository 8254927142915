import { OrdersHeader } from './components/Header'
import { FilterOrdersModal } from './components/modals/filter'
import { OrdersTable } from './components/table'

export function Orders() {
  return (
    <>
      <OrdersHeader />
      <OrdersTable />
      <FilterOrdersModal />
    </>
  )
}
