import { ColumnOfTable } from 'app/components/table/sortableTable'
import { getTableSettingsFromStorage } from 'utils/storage'

export const BOT_ORDERS_TABLE_KEY = 'botOrdersTable'

export const columnsForShowInBotOrdersTable: ColumnOfTable[] = [
  {
    id: 'amount',
    label: 'Amount',
  },
  {
    id: 'assetManagementID',
    label: 'Asset Management ID',
  },
  {
    id: 'failureReason',
    label: 'Failure Reason',
  },
  {
    id: 'metaData',
    label: 'Meta Data',
  },
  {
    id: 'metaID',
    label: 'Meta ID',
  },
  {
    id: 'price',
    label: 'Price',
  },
  {
    id: 'status',
    label: 'Status',
  },
  {
    id: 'symbol',
    label: 'Symbol',
  },
  {
    id: 'type',
    label: 'Type',
  },
  {
    id: 'createdAt',
    label: 'Created At',
    isDate: true,
  },
  {
    id: 'updatedAt',
    label: 'Updated At',
    isDate: true,
  },
]

export const {
  pageSize: localPageSize,
  sortType: localSortType,
  sortField: localSortField,
} = getTableSettingsFromStorage({ tableKey: BOT_ORDERS_TABLE_KEY })

export const STATUS_OPTIONS = ['IN_PROGRESS', 'CANCELED', 'FAILED', 'COMPLETED']
