import { styled } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import { useDispatch, useSelector } from 'react-redux'
import { clientSourceKeysSelectors } from '../selectors'
import { clientSourceKeysActions } from '../slice'
import {
  FilterChipsInHeader,
  IShowablefilterData,
} from 'app/components/common/FilterChipsInHeader'
import { PageHeader } from 'app/components/common/globals/pageHeader'
import PrimaryButton from 'app/components/button/primaryButton'

export const Header = () => {
  const dispatch = useDispatch()

  const filterClientSourceKeys = useSelector(
    clientSourceKeysSelectors.filterClientSourceKeys,
  )
  const displayFilterClientSourceKeysModal = useSelector(
    clientSourceKeysSelectors.displayFilterClientSourceKeysModal,
  )

  const handleOpenFilterModal = () => {
    dispatch(
      clientSourceKeysActions.setDisplayFilterClientSourceKeysModal(true),
    )
  }

  const showablefilterClientSourceKeys: IShowablefilterData[] = [
    {
      label: 'Client DomainUUID',
      value: filterClientSourceKeys.clientDomainUUID,
    },
    {
      label: 'Client DomainID',
      value: filterClientSourceKeys.clientDomainID,
    },
    {
      label: 'Plan',
      value: filterClientSourceKeys.plan,
    },
    {
      label: 'CreatedAt From',
      value: filterClientSourceKeys.createdAtFrom,
      isDate: true,
    },
    {
      label: 'CreatedAt To',
      value: filterClientSourceKeys.createdAtTo,
      isDate: true,
    },
    {
      label: 'UpdateAt From',
      value: filterClientSourceKeys.updateAtFrom,
      isDate: true,
    },
    {
      label: 'UpdateAt To',
      value: filterClientSourceKeys.updateAtTo,
      isDate: true,
    },
  ]

  const handleDelete = (label: string) => {
    dispatch(
      clientSourceKeysActions.setFilterClientSourceKeys({
        ...filterClientSourceKeys,
        [label]: undefined,
      }),
    )
    dispatch(clientSourceKeysActions.getClientSourceKeysList())
  }

  return (
    <>
      <PageHeader title="Client SourceKeys">
        <PrimaryButton
          onClick={handleOpenFilterModal}
          endIcon={<StyledSearchIcon />}
        >
          Search
        </PrimaryButton>
      </PageHeader>
      <FilterSection>
        {!displayFilterClientSourceKeysModal && (
          <FilterChipsInHeader
            showableFilter={showablefilterClientSourceKeys}
            deleteFilter={handleDelete}
          />
        )}
      </FilterSection>
    </>
  )
}

const FilterSection = styled('div')`
  display: flex;
  gap: 8px;
  width: 100%;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
`
const StyledSearchIcon = styled(SearchIcon)`
  color: var(--white);
`
