import { ConfigurationsHeader } from './components/Header'
import { FilterConfigurationsModal } from './components/modals/filter'
import { SetConfigurationModal } from './components/modals/setConfiguration'
import { ConfigurationsTable } from './components/table'

export function Configurations() {
  return (
    <>
      <ConfigurationsHeader />
      <ConfigurationsTable />
      <FilterConfigurationsModal />
      <SetConfigurationModal />
    </>
  )
}
