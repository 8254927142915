import { createSelector } from '@reduxjs/toolkit'

import { RootState } from 'store/types'
import { initialState } from './slice'

export const oauthsDomains = {
  root: (state: RootState) => state.oauths || initialState,
  oauthsList: (state: RootState) => state.oauths.oauthsList,
  isLoadingOauths: (state: RootState) => state.oauths.isLoadingOauths,
  filterOauths: (state: RootState) => state.oauths.filterOauths,
  lastPageOfOauthsTable: (state: RootState) =>
    state.oauths.lastPageOfOauthsTable,
  isAllOauthsFetched: (state: RootState) => state.oauths.isAllOauthsFetched,
  displayFilterOauthsModal: (state: RootState) =>
    state.oauths.displayFilterOauthsModal,
}

export const oauthsSelectors = {
  root: createSelector(oauthsDomains.root, root => root),
  oauthsList: createSelector(oauthsDomains.oauthsList, oauths => oauths),
  isLoadingOauths: createSelector(
    oauthsDomains.isLoadingOauths,
    isLoading => isLoading,
  ),
  filterOauths: createSelector(
    oauthsDomains.filterOauths,
    filterData => filterData,
  ),
  lastPageOfOauthsTable: createSelector(
    oauthsDomains.lastPageOfOauthsTable,
    lastPageOfTable => lastPageOfTable,
  ),
  isAllOauthsFetched: createSelector(
    oauthsDomains.isAllOauthsFetched,
    isAllFetched => isAllFetched,
  ),
  displayFilterOauthsModal: createSelector(
    oauthsDomains.displayFilterOauthsModal,
    displayFilterModal => displayFilterModal,
  ),
}
