import { Box, styled } from '@mui/material'

import { useDispatch, useSelector } from 'react-redux'

import { getDateFromTimestamp } from 'utils/date'

import { mediaQueries } from 'styles/mediaQueries'
import { assetsManagementSelectors } from 'app/containers/AssetsManagement/selectors'
import { assetsManagementActions } from 'app/containers/AssetsManagement/slice'
import PrimaryButton from 'app/components/button/primaryButton'

export const DetailsAssetsManagementContent = () => {
  const dispatch = useDispatch()

  const data = useSelector(
    assetsManagementSelectors.displayDetailAssetsManagementModal,
  )

  const handleClose = () => {
    dispatch(
      assetsManagementActions.setDisplayDetailAssetsManagementModal(undefined),
    )
  }

  const renderDetailItem = (label: string, value: any) => (
    <ItemConatiner>
      <ItemLabel>{label}:</ItemLabel>
      <ItemValue>{value}</ItemValue>
    </ItemConatiner>
  )

  const renderDetailItems = () => {
    const detailItems = [
      { label: 'Asset', value: data?.asset },
      { label: 'Status', value: data?.status },
      { label: 'Amount', value: data?.amount },
      { label: 'Fee', value: data?.fee },
      { label: 'Maintenance Amount', value: data?.maintenanceAmount },
      { label: 'Maintenance Fee', value: data?.maintenanceFee },
      { label: 'Period', value: data?.period },
      { label: 'Price', value: data?.price },
      { label: 'Price Including Fee', value: data?.priceIncludingFee },
      {
        label: 'Price Including Maintenance Fee',
        value: data?.priceIncludingMaintenanceFee,
      },
      { label: 'Profit', value: data?.profit },
      { label: 'Profit Percentage', value: data?.profitPercentage },
      { label: 'Total Sales', value: data?.totalSales },
      {
        label: 'Started At',
        value: getDateFromTimestamp(data?.startedAt as number),
      },
      {
        label: 'Created At',
        value: getDateFromTimestamp(data?.createdAt as number),
      },
      {
        label: 'Ended At',
        value: getDateFromTimestamp(data?.endedAt as number),
      },
    ]
    const result: JSX.Element[] = []
    for (let i = 0; i < detailItems.length; i += 2) {
      console.log(i % 2 !== 0)

      result.push(
        <TwoItemsContainer key={i}>
          {renderDetailItem(detailItems[i].label, detailItems[i].value)}
          {renderDetailItem(detailItems[i + 1].label, detailItems[i + 1].value)}
        </TwoItemsContainer>,
      )
    }
    return result
  }

  return (
    <Wrapper>
      {renderDetailItems()}
      <CTAContainer>
        <PrimaryButton onClick={handleClose}>Close</PrimaryButton>
      </CTAContainer>
    </Wrapper>
  )
}

const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 32px;
  width: 100%;
  ${mediaQueries.lessThan('sm')`
  gap: 23px;`}
  ${mediaQueries.lessThan('xs')`
  gap: 16px;`}
`

const TwoItemsContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 32px;
  width: 100%;
  ${mediaQueries.lessThan('xs')`
  flex-direction: column;
  gap: 16px;
  `}
`

const ItemConatiner = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  height: 32px;
  width: 100%;
  padding: 6px;
  background-color: var(--greyscale-50);
`

const ItemLabel = styled(Box)`
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.palette.text.primary};
  word-wrap: normal;
  min-width: 120px;
  font-weight: 700;
  width: 100%;
  margin: 4px;
`

const ItemValue = styled(ItemLabel)`
  font-size: 18px;
  width: 80%;
  text-align: center;
`
const CTAContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 32px;
  width: 100%;
  ${mediaQueries.lessThan('sm')`
  margin-top: 16px;`}
  ${mediaQueries.lessThan('xs')`
  margin-top: 8px;`}
`
