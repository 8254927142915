import axiosInstance from 'api/axios'
import { AxiosResponse } from 'axios'
import { IFilterEmailVerifications } from '../types'
import { EmailVerificationsEndpoints } from 'api/endpoints/verifications'

export function* getEmailVerificationsDataReq(
  filterData: IFilterEmailVerifications,
) {
  const response: AxiosResponse = yield axiosInstance.get(
    EmailVerificationsEndpoints.emailVerificationsList(filterData),
  )
  return response
}
