import axiosInstance from 'api/axios'
import { baseURLPrefix } from 'app/constants'
import { AxiosResponse } from 'axios'
import { IResubmissionSubmitRequest } from '../types'

export function* getApplicantDetail(refrenceId: string) {
  const response: AxiosResponse = yield axiosInstance.get(
    `${baseURLPrefix}document/provided/data?referenceId=${refrenceId}`,
  )
  return response.data
}
export function* postResubmissionConfirmation(
  payload: IResubmissionSubmitRequest,
) {
  const response: AxiosResponse = yield axiosInstance.post(
    `${baseURLPrefix}document/review/confirm`,
    payload,
  )
  return response.data
}
