import { createSelector } from '@reduxjs/toolkit'

import { RootState } from 'store/types'
import { initialState } from './slice'

export const rolesDomains = {
  root: (state: RootState) => state.roles || initialState,
  rolesList: (state: RootState) => state.roles.rolesList,
  isLoadingRoles: (state: RootState) => state.roles.isLoadingRoles,
  deleteConfirmationModal: (state: RootState) =>
    state.roles.deleteConfirmationModal,
  routesList: (state: RootState) => state.roles.routesList,
  isAddingRoute: (state: RootState) => state.roles.isRouteAdding,
  addRoleModalDisplay: (state: RootState) => state.roles.addRoleModalDisplay,
}

export const rolesSelectors = {
  root: createSelector(rolesDomains.root, root => root),
  rolesList: createSelector(rolesDomains.rolesList, rolesList => rolesList),
  roleById: (roleId: number | string) =>
    createSelector(rolesDomains.rolesList, rolesList =>
      rolesList.find(role => role.id === roleId),
    ),
  isLoadingRoles: createSelector(
    rolesDomains.isLoadingRoles,
    isLoadingRoles => isLoadingRoles,
  ),
  deleteConfirmationModal: createSelector(
    rolesDomains.deleteConfirmationModal,
    displayDeleteConfirmationModal => displayDeleteConfirmationModal,
  ),
  displayDeleteConfirmationModal: createSelector(
    rolesDomains.deleteConfirmationModal,
    displayDeleteConfirmationModal => displayDeleteConfirmationModal.display,
  ),
  routesList: createSelector(rolesDomains.routesList, routesList => routesList),
  isAddingRoute: createSelector(
    rolesDomains.isAddingRoute,
    isAddingRoute => isAddingRoute,
  ),
  addRoleModalDisplay: createSelector(
    rolesDomains.addRoleModalDisplay,
    addRoleModalDisplay => addRoleModalDisplay,
  ),
}
