import { Chip, styled } from '@mui/material'
import { useSelector } from 'react-redux'
import { searchUserSelectors } from 'app/containers/SearchUser/selector'
import { mediaQueries } from 'styles/mediaQueries'
import { PageHeader } from 'app/components/common/globals/pageHeader'

export const Header = () => {
  const searchUserCoreId = useSelector(searchUserSelectors.coreId)

  return (
    <Wrapper>
      <PageHeader title="Nfts" />
      <FilterSection>
        {searchUserCoreId ? (
          <StyledChip
            key={searchUserCoreId}
            label={`coreId: ${searchUserCoreId}`}
            variant="outlined"
          />
        ) : (
          <></>
        )}
      </FilterSection>
    </Wrapper>
  )
}

const Wrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 16px;
  ${mediaQueries.lessThan('sm')`
    flex-direction: column;
    align-items: flex-start;
    `}
`

const FilterSection = styled('div')`
  display: flex;
  gap: 8px;
  width: 100%;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 24px;
  ${mediaQueries.lessThan('sm')`
   margin-bottom:0;
   `}
`

const StyledChip = styled(Chip)`
  background: var(--alice-blue);
  border-color: var(--chip-color);
  color: var(--chip-color);
  font-weight: 700;
`
