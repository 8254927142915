import axiosInstance from 'api/axios'
import { AxiosResponse } from 'axios'
import { IFilterPhoneRejections } from '../types'
import { PhoneRejectionsEndpoints } from 'api/endpoints/verifications'

export function* getPhoneRejectionsReq(filterData: IFilterPhoneRejections) {
  const response: AxiosResponse = yield axiosInstance.get(
    PhoneRejectionsEndpoints.phoneRejectionsList(filterData),
  )
  return response
}
