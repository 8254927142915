import { useDispatch, useSelector } from 'react-redux'

import { FilterEmailInvalidationsContent } from './content'
import { emailInvalidationsSelectors } from 'app/containers/EmailInvalidations/selectors'
import { emailInvalidationsActions } from 'app/containers/EmailInvalidations/slice'
import { Modal } from 'app/components/modal'

export const FilterEmailInvalidationsModal = () => {
  const dispatch = useDispatch()

  const isOpen = useSelector(
    emailInvalidationsSelectors.displayFilterEmailInvalidationsModal,
  )
  const handleModalClose = () => {
    dispatch(
      emailInvalidationsActions.setDisplayFilterEmailInvalidationsModal(false),
    )
  }

  return (
    <Modal open={isOpen} onClose={handleModalClose}>
      <FilterEmailInvalidationsContent />
    </Modal>
  )
}
