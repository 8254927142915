import { PayloadAction } from '@reduxjs/toolkit'
import { OrganizationsState } from './types'
import { createSlice } from 'store/toolkit'
import { useInjectReducer, useInjectSaga } from 'store/redux-injectors'

import { organizationsSaga } from './saga'
import { localPageSize, localSortField, localSortType } from './constants'

export const initialState: OrganizationsState = {
  organizationsList: [],
  isLoadingOrganizations: false,
  filterOrganizations: {
    page: 0,
    pageSize: localPageSize ? localPageSize : 100,
    sortField: localSortField,
    sortType: localSortType,
  },
  lastPageOfOrganizationsTable: 0,
  isAllOrganizationsFetched: false,
  displayFilterOrganizationsModal: false,
}

const organizationsSlice = createSlice({
  name: 'organizations',
  initialState,
  reducers: {
    getOrganizationsList(state) {},
    setOrganizations(
      state,
      action: PayloadAction<OrganizationsState['organizationsList']>,
    ) {
      state.organizationsList = action.payload
    },
    setIsLoadingOrganizations(
      state,
      action: PayloadAction<OrganizationsState['isLoadingOrganizations']>,
    ) {
      state.isLoadingOrganizations = action.payload
    },
    setFilterOrganizations(
      state,
      action: PayloadAction<OrganizationsState['filterOrganizations']>,
    ) {
      state.filterOrganizations = action.payload
    },
    setLastPageOfOrganizationsTable(state, action: PayloadAction<number>) {
      state.lastPageOfOrganizationsTable = action.payload
    },
    setIsAllOrganizationsFetched(state, action: PayloadAction<boolean>) {
      state.isAllOrganizationsFetched = action.payload
    },
    setDisplayFilterOrganizationsModal(state, action: PayloadAction<boolean>) {
      state.displayFilterOrganizationsModal = action.payload
    },
  },
})

export const {
  actions: organizationsActions,
  reducer: organizationsReducer,
  name: sliceKey,
} = organizationsSlice

export const useOrganizationsSlice = () => {
  useInjectReducer({ key: sliceKey, reducer: organizationsReducer })
  useInjectSaga({ key: sliceKey, saga: organizationsSaga })
  return { organizationsActions: organizationsActions }
}
