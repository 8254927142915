import { Header } from './components/Header'
import { FilterRecordsModal } from './components/modals/filter'
import { TableOfData } from './components/table'

export function Records() {
  return (
    <>
      <Header />
      <TableOfData />
      <FilterRecordsModal />
    </>
  )
}
