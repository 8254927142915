import axiosInstance from 'api/axios'
import { AxiosResponse } from 'axios'
import { IFilterEmailInvalidations } from '../types'
import { EmailInvalidationsEndpoints } from 'api/endpoints/verifications'

export function* getDocumentInvalidationsReq(
  filterData: IFilterEmailInvalidations,
) {
  const response: AxiosResponse = yield axiosInstance.get(
    EmailInvalidationsEndpoints.emailInvalidationsList(filterData),
  )
  return response
}
