import { PhoneRejectionsHeader } from './components/Header'
import { FilterPhoneRejectionsModal } from './components/modals/filter'
import { PhoneRejectionsTable } from './components/table'

export function PhoneRejections() {
  return (
    <>
      <PhoneRejectionsHeader />
      <PhoneRejectionsTable />
      <FilterPhoneRejectionsModal />
    </>
  )
}
