import { PayloadAction } from '@reduxjs/toolkit'
import {
  IKYCProviderData,
  IResubmissionItemInfo,
  IResubmissionItemState,
  IResubmissionSubmitRequest,
} from './types'
import { createSlice } from 'store/toolkit'

export const initialState: IResubmissionItemState = {
  resubmissionItem: {
    kycProviderData: {},
    userSuggestion: {},
    rawUserSuggestions: {},
    applicantId: '',
  },
  isLoading: false,
  isSubmitLoading: false,
  displayConfirmationModal: false,
  kycData: {},
}

const resubmissionItemSlice = createSlice({
  name: 'resubmissionItem',
  initialState,
  reducers: {
    fetchResubmissionItem(state, action: PayloadAction<string>) {},
    setIsLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload
    },
    setResubmissionItem(state, action: PayloadAction<IResubmissionItemInfo>) {
      state.resubmissionItem = action.payload
    },
    sendResubmissionConfirmation(
      state,
      action: PayloadAction<IResubmissionSubmitRequest>,
    ) {},
    setSubmitLoading(state, action: PayloadAction<boolean>) {
      state.isSubmitLoading = action.payload
    },
    setDisplayConfirmationModal(state, action: PayloadAction<boolean>) {
      state.displayConfirmationModal = action.payload
    },
    setKycData(state, action: PayloadAction<IKYCProviderData>) {
      state.kycData = action.payload
    },
  },
})

export const {
  actions: resubmissionItemActions,
  reducer: resubmissionItemReducer,
  name: sliceKey,
} = resubmissionItemSlice
