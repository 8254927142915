import { styled } from '@mui/material'
import CustomTab from 'app/components/tab'
import { DocumentInvalidations } from 'app/containers/DocumentInvalidations'
import { DocumentRejections } from 'app/containers/DocumentRejections'
import { EmailInvalidations } from 'app/containers/EmailInvalidations'
import { EmailRejections } from 'app/containers/EmailRejections'
import { EmailVerifications } from 'app/containers/EmailVerifications'
import { Nfts } from 'app/containers/Nfts'
import { Orders } from 'app/containers/Orders'
import { Payments } from 'app/containers/Payments'
import { PhoneInvalidations } from 'app/containers/PhoneInvalidations'
import { PhoneRejections } from 'app/containers/PhoneRejections'
import { PhoneVerifications } from 'app/containers/PhoneVerifications'
import { Resubmissions } from 'app/containers/Resubmissions'
import { Submissions } from 'app/containers/Submissions'
import { Vouchers } from 'app/containers/Vouchers'
import { mediaQueries } from 'styles/mediaQueries'

export const TabContainer = () => {
  return (
    <TabWrapper>
      <CustomTab
        scrollable
        bgColor="var(--white)"
        hasDivider
        tabs={[
          {
            page: <Vouchers />,
            title: 'Voucher',
          },
          {
            page: <Payments />,
            title: 'Payments',
          },
          {
            page: <Orders />,
            title: 'Orders',
          },
          {
            page: <Resubmissions />,
            title: 'Resubmissions',
          },
          {
            page: <Submissions />,
            title: 'Submissions',
          },
          {
            page: <PhoneVerifications />,
            title: 'Phone Verifications',
          },
          {
            page: <EmailVerifications />,
            title: 'Email Verifications',
          },
          {
            page: <PhoneInvalidations />,
            title: 'Phone Invalidations',
          },
          {
            page: <EmailInvalidations />,
            title: 'Email Invalidations',
          },
          {
            page: <DocumentInvalidations />,
            title: 'Document Invalidations',
          },
          {
            page: <PhoneRejections />,
            title: 'Phone Rejections',
          },
          {
            page: <EmailRejections />,
            title: 'Email Rejections',
          },
          {
            page: <DocumentRejections />,
            title: 'Document Rejections',
          },
          {
            page: <Nfts />,
            title: 'Nfts',
          },
        ]}
      />
    </TabWrapper>
  )
}

export const TabWrapper = styled('div')`
  margin-top: 41px;
  width: 100%;
  flex: 1 1 100%;
  background-color: var(--light-grey);
  border-radius: 16px;
  overflow-y: scroll;
  ${mediaQueries.lessThan('md')`
   .MuiTabPanel-root {
      padding:  10px;
    }
  `}
  ${mediaQueries.lessThan('sm')`
    margin-top: 24px;
     .MuiTabPanel-root {
    }
    .css-1h9z7r5-MuiButtonBase-root-MuiTab-root{
      padding: 6px 8px;
    }
    `}
  ${mediaQueries.lessThan('xs')`
    margin-top: 16px;
    .css-1h9z7r5-MuiButtonBase-root-MuiTab-root{
      padding: 3px 4px;
    }`}
`
